import React, {Component} from 'react';
import {
  BrowserRouter as Router,
  Route,
  NavLink
} from "react-router-dom";
import withRouter from '../../components/withRouter';
import StaffMobileReserve from "./reserve";
import StaffMobileTransfer from "./transfer";
import '../css/style.css';
import '../css/staff.css';
var logging = require('../../admin/service/loggingComponent');
const loggingHeader = '[frontend][mobile][staff][home]';


class StaffMobile extends Component {

  constructor(props){
    super(props);
    this.state = {
      staff:this.props.params.id,
      name:'',
      // staff:'',
      default_content:''

    }
  }

  componentWillMount(){
    // console.log('componentDidMount');
    this.default();
  }

  handlelogout = _ => {
    logging.logToGoogle(loggingHeader + ' clicked logout');
    sessionStorage.setItem('int', 'logout');
  }


  default = _ => {
    var {staff} = this.state;
    var url = window.location.href;

    // console.log(staff)

    // Redirect to current reserve page
    if (url.endsWith('/staffmobile/home')){
      this.setState({default_content: (<StaffMobileReserve id={staff}></StaffMobileReserve>) })
    }
    else{
      this.setState({default_content: (<StaffMobileTransfer id={staff}></StaffMobileTransfer>) })
    }

  }


  render() {

      var staff = sessionStorage.getItem('id_s');
      var email = sessionStorage.getItem('email');

      return (

        <div className="StaffMobile">
        <Router>
          <div>

          <nav className="navbar navbar-default">
            <div className="container-fluid">
              <div className="navbar-header">
                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <a className="navbar-brand" href="/"><img id="logo" src="/img/logo.png" alt="Navlogo"/></a>
              </div>

              <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                <ul className="nav navbar-nav">
                  <li className=""  data-toggle="collapse" data-target="#bs-example-navbar-collapse-1"><NavLink active="active" to="/{staff}/staffmobile/home" >RESERVE <span className="sr-only">(current)</span></NavLink></li>
                  <li className="" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1"><NavLink active="active" to="/{staff}/staffmobile/transfer">APPLY TRANSFER </NavLink></li>
                </ul>
                <ul className="nav navbar-nav navbar-right">
                  <li>
                    <a href="/logout" onClick={() => this.handlelogout()}> Log out from {email}@navigators.org.sg </a>
                    <a href="/staff/">Switch to Desktop Version</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>


          <div className="content container">
            
            <Route path="/:staff/staffmobile/home" children={<StaffMobileReserve id={staff} /> } />
            <Route path="/:staff/staffmobile/transfer" children={<StaffMobileTransfer id={staff} /> } />
            {/* <Route path="/:staff/staffmobile/home" render={() => <StaffMobileReserve id={staff} /> } />
            <Route path="/:staff/staffmobile/transfer" render={() => <StaffMobileTransfer id={staff} /> } /> */}

          </div>


          </div>

        </Router>

        </div>

      );
    }

}


export default withRouter(StaffMobile);
