import React, { Component } from "react";
var config = require('./config.js');

const website = config.website;


class Warning extends Component {

  componentDidMount() {
    sessionStorage.removeItem('staff');
    sessionStorage.removeItem('super');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('id_s');
    sessionStorage.removeItem('admin');
    sessionStorage.removeItem('id_e');
    sessionStorage.removeItem('version');
    sessionStorage.removeItem('int');
    sessionStorage.removeItem('email');
  }


  render(){
    return (
      <div className = 'warning-msg'>
        <br /><br />
        <h1>
            Oops... You cannot access this page
        </h1>
        <div>

            <h2>Please make sure that <br /> <br /></h2>
            <h3>
              1. You have authority to access this page. <br /><br />
            </h3>
            <h3>
              2. Log in with your navigators account.<br /><br />
            </h3>

            <h3>
              <a href={website}>Return to the Reserves Service.</a>
            </h3>


        </div>


      </div>


    )
  }
}


export default Warning;
