import React, {Component} from "react";
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
var logging = require('../admin/service/loggingComponent');
const loggingHeader = '[frontend][exportPDF]';


function empty_row(){
  var empty_row = []
  for (var j=0; j<16; j++){
    empty_row.push('');
  }
  return empty_row;
}


class ExportPDF extends Component {

  // _isMounted = false;

  constructor(props){
    super(props);
    this.state = {
      dataSet: [],
      type:'',

      documentDefinition :{
        pageSize: 'A2',
        pageOrientation: 'landscape',
        // customize: function (doc) { doc.defaultStyle.fontSize = 2; //2,3,4,etc
        //   doc.styles.tableHeader.fontSize = 1; //2, 3, 4, etc
        // },
        content: [],
        dontBreakRows: true,
        pageMargins: [30,25,15,0],
        scale: 59,
        startPosition:{
          horizontalRatio: 0.2
        }
      }

    }

    // this.state.dataSet = this.ref;
  }
  // title,
  // '\n',
  // admin_title,
  // '\n',
  //
  // {
  //   table: {
  //     headerRows: 1,
  //     dontBreakRows: true,
  //     body: []
  //   }
  // },
  //
  // '\n',
  // ministry_title,
  // '\n',
  //
  // {
  //   table: {
  //     headerRows: 1,
  //     dontBreakRows: true,
  //     body: []
  //   }
  // }

  componentWillReceiveProps(nextProps) {

      this.setState({ dataSet: nextProps.data });
      this.setState({ type: nextProps.type });
      this._exportPdfTable();

  }

  componentWillMount(){
      this.setState({ dataSet: this.props.data });
      this.setState({ type: this.props.type });
      this._exportPdfTable();
  }

  componentDidMount() {
    this._exportPdfTable();
  }

  componentWillUnmount(){
    this._exportPdfTable();
  }

  render_row(row_data, type){

    var row = [];

    if (type === 'MonthlyPayroll'){
      for (var i=0; i <2; i++){
        // console.log(typeof row_data[i], row_data[i])
        if (typeof row_data[i] === "string"){
          // console.log('find string');
          if (row_data[i] === undefined){
            row.push({text: "", alignment: "left"});
            // row.push("");
          }
          else{
            row.push({text: row_data[i], alignment: "left"});
            // row.push(row_data[i]);
          }
        }
        else{
          if (row_data[i].value < 0){
            row.push({text:row_data[i].value, alignment: "left", color: '#FF0000' });
          }
          else{
            row.push({text:row_data[i].value, alignment: "left"});
          }
          // row.push(row_data[i].value);
        }
        // console.log('data',row_data[i].value);
      }

      for (var j=2; j < row_data.length; j++){
        // console.log(typeof row_data[i], row_data[i])
        if (typeof row_data[j] === "string"){
          if (row_data[j] === undefined){
            row.push({text: "", alignment: "right"});
            // row.push("");
          }
          else{
            row.push({text: row_data[j], alignment: "right"});
            // row.push(row_data[i]);
          }
        }
        else{
          // console.log('value', row_data[j]);
          // console.log(row_data[j].value, '< 0?', (row_data[j].value.startsWith('-')))

          // If valus is null, return 0
          if (row_data[j].value === null){
            row.push({text:'',alignment: "right" })
          }

          else{
            if (row_data[j].value.startsWith('-')){
              row.push({text:row_data[j].value, alignment: "right", color: '#FF0000' });
            }
            else{
              row.push({text:row_data[j].value, alignment: "right"});
            }
          }

        }
      }
      return row;

    }
    else if (type === 'Employee'){
      // First cell colSpan = 2
      if (row_data[0].value){
        row.push({text:row_data[0].value, alignment: "left", colSpan: 2});
      }
      else if (row_data[0]){
        row.push({text:row_data[0], alignment: "left", colSpan: 2});
      }
      else{
        row.push({text:'', alignment: "left", colSpan: 2});
      }
      // Other cells in the row align to right
      for (var k=1; k < row_data.length; k++){
      // for (var i=1; i < 3; i++){
        if (row_data[k].value){

          if (row_data[k].value.startsWith('-')){
            row.push({text:row_data[k].value, alignment: "right", color: '#FF0000'});
          }
          else{
            row.push({text:row_data[k].value, alignment: "right"});
          }

        }
        else if (row_data[k]){
          row.push({text:row_data[k], alignment: "right"});
        }
        else{
          row.push({text:'', alignment: "right"});
        }
      }

      return row;
    }
    else if (type === 'Yearly'){
      // First cell colSpan = 2
      if (row_data[0].value){
        row.push({text:row_data[0].value, alignment: "left", colSpan: 2});
      }
      else if (row_data[0]){
        row.push({text:row_data[0], alignment: "left", colSpan: 2});
      }
      else{
        row.push({text:'', alignment: "left", colSpan: 2});
      }
      // Other cells in the row align to right
      // console.log(row_data.length);
      for (var x=1; x < row_data.length; x++){
      // for (var i=1; i < 3; i++){
        if (row_data[x].value){
          // console.log('value', row_data[i].value);
          // row.push({text:row_data[x].value, alignment: "right"});

          if (row_data[x].value.startsWith('-')){
            row.push({text:row_data[x].value, alignment: "right", color: '#FF0000'});
          }
          else{
            row.push({text:row_data[x].value, alignment: "right"});
          }

        }
        else if (row_data[x]){
          row.push({text:row_data[x], alignment: "right"});
        }
        else{
          row.push({text:'', alignment: "right"});
        }
      }
      if (row_data.length !== 16){
        row.push({text:'', alignment: "right"});
      }
      // console.log(row);
      return row;
    }


    // console.log('row',row);
  }

  render_columns(columns, type){
    var col = [];
    // console.log('columns', columns, type);
    for (var j=0; j < columns.length; j++){

      // First cell of Employee month roll col span is 2
      if (type === 'Employee_month'){
        if (j===0){
          col.push({text: columns[j].value, bold:true, alignment: "left", colSpan:2});
        }
        else{
          col.push({text: columns[j].value, bold:true, alignment: "center"});
        }
      }

      else if (type === 'Employee_name'){
        col.push({text: columns[0], alignment: "left", colSpan: 2},{},{text: columns[1], alignment: "left"});
        break;
      }

      else if (columns[j].value){
        col.push({text: columns[j].value, bold:true, fillColor: '#dddddd', alignment: "right"});

      }

      else if (columns[j]){
        col.push({text: columns[j], bold:true, fillColor: '#dddddd', alignment: "right"});
      }
      else{
        // col.push({text: columns[j].value, style: 'tableHeader', bold:true, fillColor: '#dddddd'});
        col.push({text: '', bold:true, fillColor: '#dddddd', alignment: "right"});

      }
    }

    // console.log('col', col);
    return col;

  }

  render_table(table_data, type){
    var data = []
    for (var j=0; j < table_data.length; j++){
      var row = this.render_row(table_data[j], type)
      data.push(row);
    }
    return data
  }

  render_title(title, type){
    return ({text: title, alignment:'center', bold:true, fontSize: 16})
  }

  render_subtitle(subtitle, type){
    return ({text: subtitle, bold:true, fontSize: 12})
  }

  _exportPdfTable = () => {
    const {dataSet} = this.state;
    const {type} = this.state;

    var title;
    var subtitle;
    var date;
    var columns;
    var content;
    var empty;
    var heights;

    var income;
    var income_data;
    var deduction;
    var outcome_data;
    var total;
    var widths;

    var body;


    if (type==='MonthlyPayroll'){
      // console.log('type: MonthlyPayroll');
      // console.log(dataSet);
      // [0] title
      // [1] empty row
      // [2] Administration Subtitle
      // [3] Administration table header
      // [4] Administration table data
      // [5] empty row
      // [6] Ministry Subtitle
      // [7] Ministry table header
      // [8] Ministry table data


      // [0] title
      title = this.render_title(dataSet[0].data[0][0].value, type);

      // [2] Administration Subtitle
      var admin_titles = []
      var admin_title1 = this.render_subtitle(dataSet[2].data[0][0].value, type);
      admin_title1['colSpan'] = 5;
      admin_title1['border'] = [false, false, false, false];
      admin_titles.push(admin_title1);
      admin_titles.push('');
      admin_titles.push('');
      admin_titles.push('');
      admin_titles.push('');


      var admin_title2 = this.render_subtitle(dataSet[2].data[0][5].value, type);
      admin_title2['colSpan'] = 4;
      admin_title2['alignment'] = 'center';
      admin_title2['border'] = [true, true, true, false];
      admin_titles.push(admin_title2);
      admin_titles.push('');
      admin_titles.push('');
      admin_titles.push('');

      admin_titles.push({text:'', border:[false, false, false, false]});
      admin_titles.push({text:'', border:[false, false, false, false]});



      var admin_title3 = this.render_subtitle(dataSet[2].data[0][11].value, type);
      admin_title3['colSpan'] = 2;
      admin_title3['alignment'] = 'center';
      admin_title3['border'] = [true, true, true, false];
      admin_titles.push(admin_title3);
      admin_titles.push('');

      admin_titles.push({text:'', border:[false, false, false, false]});
      admin_titles.push({text:'', border:[false, false, false, false]});




      // admin_titles.push(this.render_subtitle(dataSet[2].data[0][0].value, type).push({colSpan:2}));
      // admin_titles.push(this.render_subtitle(dataSet[2].data[0][1].value, type));
      // admin_titles.push(this.render_subtitle('', type));
      // admin_titles.push(this.render_subtitle('', type));
      // admin_titles.push(this.render_subtitle(dataSet[2].data[0][2].value, type));

      // var admin_title= {
      //   table: {
      //     headerRows: 0,
      //     dontBreakRows: true,
      //     body:[admin_titles]
      //   }
      // }

      // var admin_title = [admin_titles]

      // console.log(admin_title)

      // [3] Administration table header
      columns = this.render_columns(dataSet[3].data[0], type);

      // Merge all rows for admin_title
      var admin_table = [admin_titles];
      admin_table.push(columns);

      var admin_data = this.render_table(dataSet[4].data.slice(0, -1), type);
      admin_table.push.apply(admin_table, admin_data);

      var admin_total = this.render_columns(dataSet[4].data.slice(-1)[0], type);
      admin_table.push(admin_total);
      // admin_table.push.apply(admin_table, admin_data);
      // console.log('body', body);

      // console.log(admin_table)

      var admin= {
        table: {
          headerRows: 1,
          dontBreakRows: true,
          body:admin_table
        }
      }

      var ministry_titles = []
      var ministry_title1 = this.render_subtitle(dataSet[6].data[0][0].value, type);
      ministry_title1['colSpan'] = 5;
      ministry_title1['border'] = [false, false, false, false];
      ministry_titles.push(ministry_title1);
      ministry_titles.push('');
      ministry_titles.push('');
      ministry_titles.push('');
      ministry_titles.push('');


      var ministry_title2 = this.render_subtitle(dataSet[2].data[0][5].value, type);
      ministry_title2['colSpan'] = 4;
      ministry_title2['alignment'] = 'center';
      ministry_title2['border'] = [true, true, true, false];
      ministry_titles.push(ministry_title2);
      ministry_titles.push('');
      ministry_titles.push('');
      ministry_titles.push('');

      ministry_titles.push({text:'', border:[false, false, false, false]});
      ministry_titles.push({text:'', border:[false, false, false, false]});



      var ministry_title3 = this.render_subtitle(dataSet[2].data[0][11].value, type);
      ministry_title3['colSpan'] = 2;
      ministry_title3['alignment'] = 'center';
      ministry_title3['border'] = [true, true, true, false];
      ministry_titles.push(ministry_title3);
      ministry_titles.push('');

      ministry_titles.push({text:'', border:[false, false, false, false]});
      ministry_titles.push({text:'', border:[false, false, false, false]});
      ministry_titles.push({text:'', border:[false, false, false, false]});
      ministry_titles.push({text:'', border:[false, false, false, false]});
      ministry_titles.push({text:'', border:[false, false, false, false]});
      ministry_titles.push({text:'', border:[false, false, false, false]});


      var ministry_table = [ministry_titles];
      var ministry_columns = this.render_columns(dataSet[7].data[0], type);
      ministry_table.push(ministry_columns);


      var ministry_data = this.render_table(dataSet[8].data.slice(0,-2), type);
      ministry_table.push.apply(ministry_table, ministry_data);

      var ministry_total = this.render_columns(dataSet[8].data.slice(-2)[0], type);
      ministry_table.push( ministry_total);

      var final_total = this.render_columns(dataSet[8].data.slice(-1)[0], type);
      ministry_table.push( final_total);


      var ministry= {
        table: {
          headerRows: 1,
          dontBreakRows: true,
          body:ministry_table
        }
      }

      content = [
        title,
        '\n',
        // admin_title,
        // '\n',
        admin,
        '\n',
        // ministry_title,
        // '\n',
        ministry
      ]

      // console.log(content)

      // if (this._isMounted){
        this.setState({documentDefinition:{...this.state.documentDefinition, content:content}});
      // }

    }

    else if (type === 'Employee'){
      // console.log('type: Employee');
      empty = empty_row();

      title = this.render_title(dataSet[0].data[0][0].value, type);
      subtitle = this.render_title(dataSet[0].data[1][0].value, type);
      date = this.render_columns(dataSet[1].data[0], type);
      var staffname = this.render_columns(dataSet[2].data[0], 'Employee_name');

      columns = this.render_columns(dataSet[3].data[0], 'Employee_month');
      body = [columns];

      income_data = this.render_table(dataSet[3].data.slice(2), type);
      // income_data.push(empty);
      body.push.apply(body, income_data);

      body.push(empty);

      deduction = empty_row();
      deduction[0] = {text: 'Deduction', bold: true, alignment:'left',  decoration: 'underline', decorationStyle: 'solid', height:15};

      body.push(deduction);



      outcome_data = this.render_table(dataSet[4].data.slice(1), type);
      body.push.apply(body, outcome_data);

      body.push(empty);

      total = this.render_table(dataSet[5].data, type);
      body.push.apply(body, total);
      var staff =  {
        table: {
          headerRows: 0,
          dontBreakRows: true,
          body:[staffname]
        },
        layout: 'noBorders'
      }

      widths = [110, 3];
      for (var i = 0; i < 12; i++){
        widths.push(57);
      }
      widths.push(67, 55);

      // console.log(widths, 'widths');
      heights = [];
      for (var y = 0; y < 29; y++){
        heights.push(19);
      }
      // console.log(heights, 'heights');

      income =  {
        table: {
          widths:widths,
          heights:heights,
          headerRows: 1,
          dontBreakRows: true,
          body:body
        },
        layout: 'headerLineOnly'
      }

      // console.log('income', income)

      content = [
        title,
        subtitle,
        date,
        staff,
        '\n',
        income
      ]

      // console.log('content', content);
      // this.setState({documentDefinition:{...this.state.documentDefinition, pageSize: 'A3'}});
      // if (this._isMounted){

        this.setState({documentDefinition:{...this.state.documentDefinition, content:content, pageSize: 'A3', pageMargins:[13,25,10,0] } });

      // }
    }

    else if (type === 'Yearly'){
      // console.log('type: Yearly');
      // console.log(dataSet)
      empty = empty_row();

      // title = [{},{},{},{},{},{},{},{},{text:dataSet[0].data[0][0].value, alignment:'center', bold:true, fontSize:16, colSpan:9 }]

      title = this.render_title(dataSet[0].data[0][0].value, type);
      subtitle = this.render_title(dataSet[0].data[1][0].value, type);
      date = this.render_columns(dataSet[1].data[0], type);
      // var staffname = this.render_columns(dataSet[2].data[0], 'Employee_name');

      columns = this.render_columns(dataSet[3].data[0], 'Employee_month');
      body = [columns];

      income_data = this.render_table(dataSet[3].data.slice(2), type);
      // console.log('income_data', income_data);

      body.push.apply(body, income_data);

      // body.push(empty);

      deduction = empty_row();
      deduction[0] = {text: 'Deduction', bold: true, alignment:'left',  decoration: 'underline', decorationStyle: 'solid', height:15};

      body.push(deduction);


      // var outcome_col = this.render_columns(dataSet[4].data[0], type);
      // var outcome_table = [];
      //
      outcome_data = this.render_table(dataSet[4].data.slice(1, -1), type);
      body.push.apply(body, outcome_data);

      // body.push(empty);

      // var total = this.render_table(dataSet[5].data, type);
      total = this.render_table(dataSet[4].data.slice(-1), type);
      body.push.apply(body, total);


      widths = [63.2, 0];
      for (var a = 0; a < 12; a++){
        widths.push(62.8);
      }
      widths.push(68.7, 59.5);
      // console.log(widths, 'widths');
      heights = [];
      for (var b = 0; b < 29; b++){
        heights.push(18);
      }
      // console.log(heights, 'heights');

      income =  {
        table: {
          widths:widths,
          heights:heights,
          headerRows: 1,
          dontBreakRows: true,
          body:body
        },
        layout: 'headerLineOnly'
      }

      // console.log('income', income)

      content = [
        title,
        subtitle,
        date,
        '\n',
        income
      ]

      // console.log('content', content);

      this.setState({documentDefinition:{...this.state.documentDefinition, content:content, pageSize: 'A3', pageMargins:[3,5,7,0] } });
      // this.setState({documentDefinition:{...this.state.documentDefinition, content:content, pageSize: 'A3', pageMargins:[3,20,7,0] } });

    }
  }

  onClick = () => {
    logging.logToGoogle(loggingHeader + ' clicked download PDF');
    var documentDefinition = this.state.documentDefinition;
    const {vfs} = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    return pdfMake.createPdf(documentDefinition).open();
  }



  render(){

    // const {type} = this.state;
    // const {dataSet} = this.state;

    // console.log('type',type);
    // console.log('dataSet',{dataSet});

    return(<button type="button" className="btn btn-info col-sm-2 button-pdf" onClick = {this.onClick.bind()}>Download PDF</button>)

  }

}

export default ExportPDF;
