import React, {Component} from "react";
var config = require('../../config.js');
const backend = config.backend;

class TransferAction extends Component {

  constructor(props) {
    super(props);

    // console.log(this.props)

    this.state = {
      Id: props.data
    };
  }

  // deleteTransferFromList (id){
  //   return this.state.transfers.filter(transfers => transfers.Id !== id);
  // }


  onClickButton(transferid, type, value){
  // onClickButton(cell, id, type, value){
   console.log(transferid, type, value);
   var id = transferid.Id
   // this can be in a nice box
   // alert("Are you sure you want to " + value + " transfer ?");

   var answer = window.confirm("Are you sure you want to " + value + " transfer ?")

    if ( answer ) {

      fetch( backend + `api/transfer/update/` + id,{
        method:'PUT',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
               [type]:value
            })
      })
      .then(response => response.json())
      .then((response) => {
        console.log(response);
        if (response.Error !== undefined){
          alert(response.Error);
        }
        else{
          // console.log(type + "of" + id +' changed to' + value + ' successfully');
          alert( 'Transfer is processed successfully');

          // Delete from the list
         if (type === "status"){

            this.props.filter(id);

            if (this.props.source !== 'home'){
              console.log('popup should be closed');
              this.props.source();
            }
            else{
              console.log('popup is not open')

            }


         }
          else if(type === "comment"){
            // console.log('refresh this row');
          }
        }

       })
      .catch(err => console.error(err));

    } else {
        // Do nothing!
    }

  }


  render(){

    const Id = this.state;

    // console.log(Id)

    return (
       // <td id="action-buttons">
       <div className='action-buttons'>
         <button
            className="btn btn-success action-btn approve-btn"
            type="button"
            onClick={() =>
            this.onClickButton(Id,"status","approve")}
         >
         Approve
         </button>

         <button
            className="btn btn-danger action-btn reject-btn"
            type="button"
            onClick={() =>
            this.onClickButton(Id,"status","reject")}
         >
         Reject
         </button>
        </div>
       // </td>

    )

  }



}


export default TransferAction;
