import React, { Component } from "react";
// import Select from 'react-select';
import DropdownComponent from "../components/dropdown";
import moment from 'moment';
import ReactExport from "react-data-export";
// import DatasetTable from "../admin/per_employee_yearly_report/report_employee_table";
import ReportTable from "../components/reportTable";

import ExportPDF from "../components/exportPDF.js";
import { borderedCell_employee as borderedCell, changeFloat, roundup } from "../admin/service/excelService";
// import { NotificationManager } from 'react-notifications';
import { Toast } from "primereact/toast";
import CheckButton from "../components/checkButton";

var config = require('../config.js');
var service = require('../admin/service/getinfo.js')
// const customStyles = service.customStyles;

const backend = config.backend;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


function numberWithCommas(x) {
  if ((x !== null) && !isNaN(x)) {
    if (x === '0' || x === 0) { return '0.00' }
    var y = roundup(x).toFixed(2);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return null;
}



class YearlyReport extends Component {


  constructor(props) {
    super(props);
    this.state = {
      staff: this.props.id,
      staffname: '',
      year: '',
      years: [],
      errors: {},
      checked: false,
      space: {
        columns: [],
        data: []
      },
      multiData: [],
      filename: 'report'
    }
    this.showError = this.showError.bind(this);
    // this.showSuccess = this.showSuccess.bind(this);
  }


  componentDidMount() {
    this._isMounted = true;
    this.renderYear();
    this.getOneStaff();
  }

  // showSuccess(message) {
  //   this.toast.show({severity:'success', summary: 'Processing Success', detail:message, life: 3000});
  // }

  showError(message) {
    this.toast.show({severity:'error', summary: 'Processing Failed', detail:message, life: 3000});
  } 

  getOneStaff = _ => {

    const { staff } = this.state;
    fetch(backend + `api/staffname/` + staff, {
      method: 'GET',
      credentials: 'include'
    })
      .then(res => res.json())
      .then((res) => {
        this.setState({ staffname: res[0].name });
        // console.log('getOneStaff', res[0].name);
      })

  }

  renderYear = _ => {
    service.getYearList()
      .then((years_drop) => {
        if (this._isMounted) {
          this.setState({ years: years_drop });
        }
      })
  }

  handleYearChange = (y) => {
    this.setState({ year: y.value });
  }


  inputValid = () => {
    let errors = {};
    let formIsValid = true;
    const { year } = this.state;
    const { staff } = this.state;

    //Check To Staff Id
    if (!(isFinite(String(year)) && year !== '')) {
      errors['year'] = 'Please choose a year!';
      formIsValid = false;
    }

    if (!(isFinite(String(staff)) && staff !== '')) {
      errors['staff'] = 'Please choose a staff!';
      formIsValid = false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  generateStaffDetails() {

    const { staffname } = this.state;

    var staffdetail = {
      ySteps: -1,
      columns: [],
      data: [
        ['Name: ', staffname]
      ]
    };
    return staffdetail;
  }


  generateDate() {
    var date = moment().format('DD/MM/YYYY hh:mm:ss A');
    var datedetail = {
      ySteps: -2,
      xSteps: 13,
      columns: [],
      data: [
        [date]
      ]
    }
    return datedetail;
  }

  generateTitle(year) {

    // title and month will have its own function
    var title = {
      ySteps: -1,
      xSteps: 7,
      columns: [
      ],
      data: [
        [

          {
            value: 'The Navigators Singapore',
            style: {
              font: {
                sz: '18',
                bold: true
              },
              alignment: {
                horizontal: 'center',
                vertical: 'center',
                readingOrder: 4
              }
            }
          }

        ],

        [
          {
            value: 'Yearly Reserve Report for ' + year,
            style: {
              font: {
                bold: true
              },
              alignment: {
                horizontal: 'center',
                vertical: 'center'
              }
            }
          }
        ],


        // ['Name: ', staffname]

      ]
    }
    return title;
  }

  checkReserve = _ => {
    const { year } = this.state;
    const { staff } = this.state;
    // const {space} = this.state;
    // const {staffname} = this.state;


    var title = this.generateTitle(year);
    var staffDetails = this.generateStaffDetails();
    var date = this.generateDate();



    // var income = {
    //   columns: [
    //     {title: 'MONTH', width:{wch:30}},
    //     ''
    //   ],
    //   data: []
    // };

    var income = {
      columns: [
        { title: '', width: { wch: 22 } },
        { title: '', width: { wch: 1 } }
      ],
      data: []
    };

    // Empty Column row to set the cell width
    for (var j = 0; j < 12; j++) {
      income.columns.push({ title: '', width: { wch: 9 } })
    }
    income.columns.push({ title: '', width: { wch: 11 } })
    income.columns.push({ title: '', width: { wch: 9 } })




    // Generate months columns for income table
    var col_row = [
      {
        value: 'MONTH',
        style: {
          alignment: {
            horizontal: 'center',
            vertical: 'bottom'
          },
          font: { bold: true }

        }
      },
      ''
    ]
    const months = moment.monthsShort();
    for (var m in months) {
      // income['columns'].push(months[m]);
      col_row.push({
        value: months[m],
        style: {
          alignment: {
            horizontal: 'center',
            vertical: 'bottom'
          },
          font: { bold: true }

        }
      });

    }

    col_row.push({
      value: 'TOTAL',
      style: {
        alignment: {
          horizontal: 'center',
          vertical: 'bottom'
        },
        font: { bold: true }
      }
    });
    col_row.push({
      value: 'AVERAGE',
      style: {
        alignment: {
          horizontal: 'center',
          vertical: 'bottom'
        },
        font: { bold: true }
      }
    });

    // income['columns'].push('TOTAL');
    // income['columns'].push('AVERAGE');

    var months_data = [];
    for (var i = 0; i < 16; i++) {
      var cell = {};
      cell['value'] = '';
      cell['style'] = {
        border: {
          top: { style: 'thick', color: { rgb: '000000' } }
        },
        alignment: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      }
      months_data.push(cell);
      // console.log('months_data',months_data);
    }
    var incomedata = [];

    incomedata.push(col_row);

    incomedata.push(months_data);
    // incomedata.push(months_data);


    var deduct = {
      columns: [],
      data: []
    };
    // deduct['columns'] = [];


    var precentage = {
      columns: [],
      data: []
    };
    // precentage['columns'] = [];


    var deductdata = [
      [{ value: 'Deduction', style: { font: { underline: true, bold: true }, width: { wch: 32 } } }]
    ];
    var percentagedata = [];




    if (!this.inputValid()) {
      // NotificationManager.error('Please check your input!');
      this.showError('Please check your input!');
    }
    else {
      this.setState({ checked: true });
      // var valid_data = [];
      // this.generateTitle(staff);
      fetch(backend + `api/reserve/staff/` + staff + '/year/' + year, {
        method: 'GET',
        credentials: 'include'
      })
        .then(res => res.json())
        .then((res) => {

          console.log(res)

          var i = 12;

          var BalanceBF = ['Balance B/F', ''];
          var BalanceBF_total = 0;
          var Donations = ['Donations', ''];
          var Donations_total = 0;
          var CashProject = ['CashProject', ''];
          var CashProject_total = 0;
          var TransferIN = ['Tranfers (IN)', ''];
          var TransferIN_total = 0;
          var GovernCredit = ['Job Credit', ''];
          var GovernCredit_total = 0;
          var IncomeOthers = ['Others', ''];
          var IncomeOthers_total = 0;
          var IncomeTotal_final = ['Total', ''];
          var IncomeTotal_final_total = 0;
          var Budget = ['Budget', ''];
          var Budget_total = 0;
          var sOrd_addition_final = ['Surplus/Deficit', ''];
          // var sOrd_addition_final_total = 0;

          // Deduct.data
          var Insurance = ['Insurance', ''];
          var Insurance_total = 0;
          var AdminCharges = ['Singapore Admin Charges', ''];
          var AdminCharges_total = 0;
          var APLT = ['APLT', ''];
          var APLT_total = 0;
          var MinistryExp = ['Ministry Expenses', ''];
          var MinistryExp_total = 0;
          var TransferOUT = ['Transfers (OUT)', ''];
          var TransferOUT_total = 0;
          var OutcomeOthers = ['Others', ''];
          var OutcomeOthers_total = 0;
          var EmperCPF = ['Employer CPF', ''];
          var EmperCPF_total = 0;
          var CDAC = ['CDAC Fund', ''];
          var CDAC_total = 0;
          var EmpeeCPF = ['Employee CPF', ''];
          var EmpeeCPF_total = 0;
          var SINDA = ['SINDA Fund', ''];
          var SINDA_total = 0;
          var RelatedFund = ['Other Funds', ''];
          var RelatedFund_total = 0;
          var CreditCard = ['Credit Card Charges', ''];
          var CreditCard_total = 0;
          var Cheque = ['Cheque Charges', ''];
          var Cheque_total = 0;
          var NetSalary = ['Net Salary', ''];
          var NetSalary_total = 0;
          var OutcomeTotal_final = ['Total', ''];
          var OutcomeTotal_final_total = 0;
          var sOrd_deduction_final = ['Under/Over Spend', ''];
          var ReservedFundCF = ['Reserve Fund C/F', ''];

          // precentage.data
          // var total_Ratio = ['Total Collection Vs Budget (%)',''];

          // precentage.data
          var total_Ratio = [
            {
              value: 'Total Collection Vs Budget (%)',
              style: {
                alignment: { wrapText: true }
              }
            },
            ''
          ]

          for (var r in res) {
            if (res[r] !== null) {

              BalanceBF.push(borderedCell(res[r].BalanceBF));
              BalanceBF_total += changeFloat(res[r].BalanceBF);

              Donations.push(borderedCell(res[r].Donations));
              Donations_total += changeFloat(res[r].Donations);
              CashProject.push(borderedCell(res[r].CashProject));
              CashProject_total += changeFloat(res[r].CashProject);
              TransferIN.push(borderedCell(res[r].TransferIN));
              TransferIN_total += changeFloat(res[r].TransferIN);
              GovernCredit.push(borderedCell(res[r].GovernCredit));
              GovernCredit_total += changeFloat(res[r].GovernCredit);
              IncomeOthers.push(borderedCell(res[r].IncomeOthers));
              IncomeOthers_total += changeFloat(res[r].IncomeOthers);


              var incomeTotal = changeFloat(res[r].BalanceBF) + changeFloat(res[r].Donations) + changeFloat(res[r].CashProject) + changeFloat(res[r].TransferIN) + changeFloat(res[r].GovernCredit) + changeFloat(res[r].IncomeOthers);
              IncomeTotal_final.push(borderedCell(incomeTotal));
              IncomeTotal_final_total += changeFloat(incomeTotal);


              Budget.push(borderedCell(res[r].Budget));
              Budget_total += changeFloat(res[r].Budget);


              var sOrd_addition = roundup(changeFloat(res[r].Donations) - changeFloat(res[r].Budget));

              sOrd_addition_final.push(borderedCell(sOrd_addition));

              Insurance.push(borderedCell(res[r].Insurance));
              Insurance_total += changeFloat(res[r].Insurance);


              AdminCharges.push(borderedCell(res[r].AdminCharges));
              AdminCharges_total += changeFloat(res[r].AdminCharges);


              APLT.push(borderedCell(res[r].APLT));
              APLT_total += changeFloat(res[r].APLT);


              MinistryExp.push(borderedCell(res[r].MinistryExp));
              MinistryExp_total += changeFloat(res[r].MinistryExp);

              TransferOUT.push(borderedCell(res[r].TransferOUT));
              TransferOUT_total += changeFloat(res[r].TransferOUT);

              OutcomeOthers.push(borderedCell(res[r].OutcomeOthers));
              OutcomeOthers_total += changeFloat(res[r].OutcomeOthers);
              EmpeeCPF.push(borderedCell(res[r].EmpeeCPF));
              EmpeeCPF_total += changeFloat(res[r].EmpeeCPF);
              EmperCPF.push(borderedCell(res[r].EmperCPF));
              EmperCPF_total += changeFloat(res[r].EmperCPF);
              CDAC.push(borderedCell(res[r].CDAC));
              CDAC_total += changeFloat(res[r].CDAC);
              SINDA.push(borderedCell(res[r].SINDA));
              SINDA_total += changeFloat(res[r].SINDA);

              RelatedFund.push(borderedCell(res[r].RelatedFunds));
              RelatedFund_total += changeFloat(res[r].RelatedFunds);
              CreditCard.push(borderedCell(res[r].CreditCard));
              CreditCard_total += changeFloat(res[r].CreditCard);
              Cheque.push(borderedCell(res[r].Cheque));
              Cheque_total += changeFloat(res[r].Cheque);
              var outcomeTotal = changeFloat(res[r].CDAC) + changeFloat(res[r].SINDA);


              NetSalary.push(borderedCell(res[r].NetSalary));
              NetSalary_total += changeFloat(res[r].NetSalary);

              outcomeTotal = outcomeTotal + changeFloat(res[r].Insurance) + changeFloat(res[r].AdminCharges) + changeFloat(res[r].APLT) + changeFloat(res[r].MinistryExp) + changeFloat(res[r].TransferOUT) + changeFloat(res[r].CreditCard) + changeFloat(res[r].Cheque) + changeFloat(res[r].OutcomeOthers) + changeFloat(res[r].EmpeeCPF) + changeFloat(res[r].EmperCPF) + changeFloat(res[r].NetSalary) + changeFloat(res[r].RelatedFunds);


              // OutcomeTotal_final.push(Math.round((outcomeTotal) * 100) / 100);
              // OutcomeTotal_final_total = OutcomeTotal_final_total + outcomeTotal;

              OutcomeTotal_final.push(borderedCell(outcomeTotal));
              OutcomeTotal_final_total += changeFloat(outcomeTotal);

              // var sOrd_deduction = Math.round((outcomeTotal - parseFloat(res[r].Budget)) * 100) / 100;
              // sOrd_deduction_final.push(sOrd_deduction);
              //
              // ReservedFundCF.push(parseFloat(res[r].ReservedFundCF));

              var sOrd_deduction = roundup(outcomeTotal - changeFloat(res[r].Budget));
              sOrd_deduction_final.push(borderedCell(sOrd_deduction));

              ReservedFundCF.push(borderedCell(res[r].ReservedFundCF));


              var total_Ratio_final;
              if (changeFloat(res[r].Budget) === 0) {
                total_Ratio_final = 'Budget is not defined';
              }
              else {
                total_Ratio_final = numberWithCommas(Math.round((changeFloat(res[r].Donations) / changeFloat(res[r].Budget)) * 10000) / 100) + '%';
              }
              total_Ratio.push(borderedCell(total_Ratio_final, true, true));

            }
            else {
              BalanceBF.push('');
              Donations.push('');
              CashProject.push('');
              TransferIN.push('');
              GovernCredit.push('');
              IncomeOthers.push('');
              IncomeTotal_final.push('');
              Budget.push('');
              sOrd_addition_final.push('');

              Insurance.push('');
              AdminCharges.push('');
              APLT.push('');
              MinistryExp.push('');
              TransferOUT.push('');
              CreditCard.push('');
              Cheque.push('');
              RelatedFund.push('');
              OutcomeOthers.push('');
              EmpeeCPF.push('');
              EmperCPF.push('');
              CDAC.push('');
              SINDA.push('');
              NetSalary.push('');
              OutcomeTotal_final.push('');
              sOrd_deduction_final.push('');
              ReservedFundCF.push('');
              total_Ratio.push('');
            }
          }



          BalanceBF.push(borderedCell(BalanceBF_total));

          Donations.push(borderedCell(Donations_total));
          Donations.push(borderedCell(Donations_total / i));

          CashProject.push(borderedCell(CashProject_total));
          TransferIN.push(borderedCell(TransferIN_total));
          GovernCredit.push(borderedCell(GovernCredit_total));
          IncomeOthers.push(borderedCell(IncomeOthers_total));

          IncomeTotal_final.push(borderedCell(IncomeTotal_final_total));
          IncomeTotal_final.push(borderedCell(IncomeTotal_final_total / i));

          Budget.push(borderedCell(Budget_total));

          sOrd_addition_final.push(borderedCell(Donations_total - Budget_total));

          Insurance.push(borderedCell(Insurance_total));
          AdminCharges.push(borderedCell(AdminCharges_total));
          APLT.push(borderedCell(APLT_total));
          MinistryExp.push(borderedCell(MinistryExp_total));
          TransferOUT.push(borderedCell(TransferOUT_total));
          CreditCard.push(borderedCell(CreditCard_total));
          Cheque.push(borderedCell(Cheque_total));
          RelatedFund.push(borderedCell(RelatedFund_total));
          OutcomeOthers.push(borderedCell(OutcomeOthers_total));

          EmpeeCPF.push(borderedCell(EmpeeCPF_total));
          EmperCPF.push(borderedCell(EmperCPF_total));
          CDAC.push(borderedCell(CDAC_total));
          SINDA.push(borderedCell(SINDA_total));
          NetSalary.push(borderedCell(NetSalary_total));
          OutcomeTotal_final.push(borderedCell(OutcomeTotal_final_total));
          OutcomeTotal_final.push(borderedCell(OutcomeTotal_final_total / i));
          sOrd_deduction_final.push(borderedCell(OutcomeTotal_final_total - Budget_total));
          ReservedFundCF.push(borderedCell(IncomeTotal_final_total - OutcomeTotal_final_total));

          if (Budget_total === 0 || isNaN(Budget_total)) {
            total_Ratio.push('Budget is not defined');
          }
          else {
            // total_Ratio.push((Math.round((Donations_total / Budget_total) * 10000) / 100) + '%');
            var temp = ((Math.round((Donations_total / Budget_total) * 10000) / 100) + '%');
            total_Ratio.push(borderedCell(temp, true, true));
          }

          BalanceBF.push('');
          // Donations.push('');
          CashProject.push('');
          TransferIN.push('');
          GovernCredit.push('');
          IncomeOthers.push('');
          // IncomeTotal_final.push('');
          Budget.push('');
          sOrd_addition_final.push('');

          Insurance.push('');
          AdminCharges.push('');
          APLT.push('');
          MinistryExp.push('');
          TransferOUT.push('');
          CreditCard.push('');
          Cheque.push('');
          RelatedFund.push('');
          OutcomeOthers.push('');
          EmpeeCPF.push('');
          EmperCPF.push('');
          CDAC.push('');
          SINDA.push('');
          NetSalary.push('');
          // OutcomeTotal_final.push('');
          sOrd_deduction_final.push('');
          ReservedFundCF.push('');
          total_Ratio.push('');

          incomedata.push(BalanceBF);
          incomedata.push(Donations);
          incomedata.push(CashProject);
          incomedata.push(TransferIN);
          incomedata.push(GovernCredit);
          incomedata.push(IncomeOthers);
          incomedata.push(IncomeTotal_final);
          incomedata.push(Budget);
          incomedata.push(sOrd_addition_final);


          income['data'] = incomedata;

          deductdata.push(Insurance);
          deductdata.push(AdminCharges);
          deductdata.push(APLT);
          if (CDAC.length !== 0) {
            deductdata.push(CDAC);
          }
          else {
            deductdata.push(SINDA);
          }
          deductdata.push(RelatedFund);
          deductdata.push(EmperCPF);
          deductdata.push(EmpeeCPF);
          deductdata.push(NetSalary);
          deductdata.push(MinistryExp);
          deductdata.push(TransferOUT);
          deductdata.push(CreditCard);
          deductdata.push(Cheque);
          deductdata.push(OutcomeOthers);
          deductdata.push(OutcomeTotal_final);
          deductdata.push(Budget);
          deductdata.push(sOrd_deduction_final);
          deductdata.push(ReservedFundCF);

          deduct['data'] = deductdata;

          percentagedata.push(total_Ratio);

          precentage['data'] = percentagedata;

          if (this._isMounted) {
            // this.setState({multiData: [title, date, staffDetails, space, income, deduct, precentage] });
            this.setState({ multiData: [title, date, staffDetails, income, deduct, precentage] });

          }


          // console.log(this.state.multiData);

          // this.setState({multiData: columns });
          // this.setState({ multiData:{...this.state.multiData[0], data : data }});

        });

    }

  }


  renderReport() {
    const { staffname } = this.state;
    const { multiData } = this.state;
    const filename = staffname + "'s Yearly Report";
    // console.log(filename);

    let checked = this.state.checked;

    if (checked) {
      // console.log('if checked',multiData.length);

      if (multiData.length !== 0 && multiData.length !== undefined) {
        // console.log('multiData is updated', multiData);
        return (<div className="row">

          <div className="download-report col-sm-12">
            <ExcelFile element={<button type="button" className="btn btn-warning col-sm-2 button-excel ">Download Excel</button>} filename={filename}>
              <ExcelSheet dataSet={multiData} name="Report" />
            </ExcelFile>

            <ExportPDF data={multiData} type='Employee' />
          </div>

          <div className="row employee-report">
            <ReportTable dataSet={multiData} type='employee' />
          </div>

        </div>);
      }
      else {
        // console.log('multiData is empty')
        return <h2>Loading...</h2>
      }
    }
  }


  render() {

    const { years } = this.state;
    let report_table = this.renderReport();

    return (
      <div className="yearly-report">
        <Toast ref={(el) => this.toast = el} />
        <h4 className="title">Check Yearly Reserves Per Employee Report</h4>
        <div className="form-set">
          <form className="form-horizontal">
            <div className="form-sets">
              <fieldset>

                <div className="buttons col-sm-6">
                  <div className="btn-group ">
                    {/* <Select className='react-select-container' styles={customStyles} options={years} isSearchable="false" placeholder="year" onChange={this.handleYearChange} /> */}
                    <DropdownComponent
                    options={years}
                    placeholder="year"
                    handleChange={this.handleYearChange}
                    value={this.state.year} />
                    <span className="error">{this.state.errors["year"]}</span>
                  </div>
                </div>


                <div className="col-sm-6 check-button">
                  {/* <button type="button" className="btn check-btn btn-primary submit" onClick={this.checkReserve}>CHECK</button> */}
                  <CheckButton 
                checkReserve={this.checkReserve}/>
                </div>

              </fieldset>
            </div>
          </form>


        </div>
        <div className="report-table" id="reserve-table">
          {report_table}

        </div>


      </div>)

  }
}

export default YearlyReport;
