import React, {Component} from "react";
// import BootstrapTable from 'react-bootstrap-table-next';
// import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
// import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import TransferDetails from './transferDetails.js';
import TransferAction from './transferAction.js';
import TransferTable from "../../components/transferTable.js";
var logging = require('../../admin/service/loggingComponent.js');
const loggingHeader = '[frontend][mobile][admin][transfer]';

var config = require('../../config.js');
const backend = config.backend;


const fetch_retry = async (url, options, n) => {
    try {
        // console.log( 'trying left',n, url)

        return await fetch(url, options);

    } catch(err) {
        console.log('Retry left', n-1, 'url', url)
        if (n === 1) throw err;
        return await fetch_retry(url, options, n - 1);
    }
};




class AdminMobileTransfer extends Component {

  _isMounted = false;

  constructor(props){
    super(props);
    this.state = {
      transfers: [],
      staffs:{},
      check: false,
      showPopup: false,
      popup:'',
      transfer:{}

    }

    this.togglePopup = this.togglePopup.bind(this);
    this.filterTransfers = this.filterTransfers.bind(this);
    this.onClickButton = this.onClickButton.bind(this);
    this.deleteTransferFromList = this.deleteTransferFromList.bind(this);

  }


  componentDidMount() {
    this._isMounted = true;
    this.getPendingTransfers();
  }


  componentWillUnmount() {
    this._isMounted = false;
  }

  togglePopup(){
    // console.log('togglePopup');
    // console.log(this.state.showPopup);

    const transfer = this.state;

    this.setState({
      showPopup: !this.state.showPopup
    }, () => {
      console.log(this.state.showPopup);
      this.setState({popup:  ( <div>
          {(this.state.showPopup) ?
            <TransferDetails
              data = {transfer}
              closePopup={this.togglePopup.bind(this)}
              filter = {this.filterTransfers.bind(this)}
            />
            : null
          }
        </div>) })
    });


  }

  filterTransfers(id){

    console.log('filterTransfer ', id);

    console.log('before filter',this.state.transfers)

    this.setState({transfers: this.state.transfers.filter(transfers => transfers.Id !== id)}, () => {
      console.log('after filter',this.state.transfers)
    })


    // return this.state.transfers.filter(transfers => transfers.Id !== id);

  }


  sortIcon (column, index, { sortElement, filterElement }, length){
    if (length <= 1){
      return (
        <div> {column.text} </div>
      )
    }
    else{
      var {icon} = <i className="material-icons">unfold_more</i>;

      var { order } = sortElement.props;
      if (order === 'desc'){
        icon = <i className="material-icons">keyboard_arrow_up</i>
      }
      else if (order === 'asc'){
        icon = <i className="material-icons">keyboard_arrow_down</i>
      }
      else{
        icon = <i className="material-icons">unfold_more</i>
      }

      return (
        <div>
          {icon} {column.text}
        </div>
      );
    }
  }


  getPendingTransfers = _ => {

    fetch_retry(backend + `api/transfer/allpending`, {
      method: 'GET',
      credentials: 'include'
    }, 3)
    .then(response => response.json())
    .then((response) => {

      // Get all the staffs
      fetch_retry(backend + `api/staffsimple`, {
        method: 'GET',
        credentials: 'include'
      }, 3)
      // fetch(backend + `api/staffsimple`)
      .then(res => res.json())
      .then(({data}) => {

        // console.log('data',data);
        // console.log('response',response);

        var arr = [];
        var staffs_ = {};
        for (var s in data){

            staffs_[ data[s]['label']] = data[s]['label'];

        }

        if (this._isMounted){
          this.setState({staffs: staffs_});
        }
        // console.log(typeof this.state.staffs);
        for (var key in response){

            if(response[key]['Comment'] === 'null'){
              delete response[key]['Comment'];
            }



            for (var k in data){

              if ( (typeof(response[key]['ToStaffId']) === "number" && response[key]['ToStaffId'] === data[k]['value']) || (typeof(response[key]['ToStaffId']) === "string" && parseInt((response[key]['ToStaffId']).trim(), 10) === (data[k]['value']) ) ){

                response[key]['ToStaffId'] = data[k]['label'];
              }
              if ( (typeof(response[key]['FromStaffId']) === "number" && response[key]['FromStaffId'] === data[k]['value']) || (typeof(response[key]['FromStaffId']) === "string" && parseInt((response[key]['FromStaffId']).trim(), 10) === (data[k]['value']) ) ){

                response[key]['FromStaffId'] = data[k]['label'];
              }
            }
            arr.push(response[key]);

          // }
        }

        if (this._isMounted){

          // console.log(arr);

          arr.sort((a, b) => {
            return a.Id > b.Id
           ? -1
           : (a.Id < b.Id ? 1 : 0);
          })

          // console.log(arr);

          this.setState({transfers: arr});
          this.setState({check: true});
        }



    })
    .catch(err => console.error(err));

  })
  .catch(err => console.error(err));
  }


  fetchDetails = (ToStaffId, FromStaffId, Amount, Id, StartDate, EndDate, ProcessedDate, Times, SubmitDate) => {
    // console.log('hi')
    console.log(SubmitDate)

    var transfer = {
      'ToStaffId' : ToStaffId,
      'FromStaffId' : FromStaffId,
      'Amount' : Amount,
      'Id' : Id,
      'StartDate' : StartDate,
      'EndDate' : EndDate,
      'ProcessedDate' : ProcessedDate,
      'SubmitDate': SubmitDate,
      'Times' : Times
    }


    this.setState({transfer: transfer}, () => {
      this.togglePopup();
      console.log(this.state.showPopup)

    });

  }


  renderTable = ({ToStaffId, FromStaffId, Amount, Id, StartDate, EndDate, ProcessedDate, Times, SubmitDate}) => {
    // const {transfers} = this.state;
    // console.log(Amount);

    // console.log(transfers);

    return (<tr key={Id} >
        <td onClick={() => this.fetchDetails(ToStaffId, FromStaffId, Amount, Id, StartDate, EndDate, ProcessedDate, Times, SubmitDate)}>{FromStaffId}</td>
        <td onClick={() => this.fetchDetails(ToStaffId, FromStaffId, Amount, Id, StartDate, EndDate, ProcessedDate, Times, SubmitDate)}>{ToStaffId}</td>
        <td onClick={() => this.fetchDetails(ToStaffId, FromStaffId, Amount, Id, StartDate, EndDate, ProcessedDate, Times, SubmitDate)}>{Amount}</td>
        <td id="action-buttons"><TransferAction data = {Id} filter = {this.filterTransfers.bind(this)} source = 'home'/></td>
        {/*}{this.buttonFormatter(Id)}*/}
      </tr>)
  }

  deleteTransferFromList (id){
    return this.state.transfers.filter(transfers => 
    transfers["Id"]!==id
    );
  }

  onClickButton(id, type, value){
   // this can be in a nice box
    logging.logToGoogle(loggingHeader + ' clicked ' + value);
    var answer = window.confirm("Are you sure you want to " + value + " transfer ?")

    if ( answer ) {

      fetch( backend + `api/transfer/update/` + id,{
        method:'PUT',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
               [type]:value
            })
      })
      .then(response => response.json())
      .then((response) => {
        if (response.Error !== undefined){
          alert(response.Error);
        }
        else{
          alert( 'Transfer is processed successfully');

          // Delete from the list
         if (type === "status"){

           this.setState({transfers: this.deleteTransferFromList(id)})

         }
          else if(type === "comment"){
            // console.log('refresh this row');
          }
        }

       })
      .catch(err => {
        logging.logErrorToGoogle(loggingHeader + ' error ' + err);
        alert("Transfer is unable to be processed due to ", err)
      });

    } else {
        // Do nothing!
    }

  }


  render() {
    const {transfers} = this.state;
    const {check} = this.state;
    const {popup} = this.state;


    // var renderTable = this.renderTable();

    if(check === false){
      return (<div>
        <div className='mobile-tab'><span>Pending Applications</span></div>
        <div className = "table-container">
        <div className="only-row">Loading...</div>
       </div>
       </div>);
    }

    else{
      if (transfers.length === 0){
        return (<div className='pending-app'>
          <div className='mobile-tab'><span>Pending Applications</span></div>
          <div className = "table-container">
          <div className="only-row">No Transfer Found</div>
         </div>
         </div>);
      }
      else{
        return (
          <div className='pending-app'>
          <div className='mobile-tab'><span>Pending Applications</span></div>
          <div className = "table-container">
            <TransferTable transfers={transfers} pending={true} type="admin" device="mobile"
            onClickButton={this.onClickButton}/>
          </div>
          </div>
        // <div className='pending-app'>
        //   <div className='mobile-tab'><span>Pending Applications</span></div>
        //   <div className = "table-container">
            
          /*}<BootstrapTable bootstrap4 keyField='Id' data={ transfers } columns={ columns } bordered={ false } striped hover defaultSorted={ defaultSorted } filter={ filterFactory() } pagination={  paginationFactory({
            hidePageListOnlyOnePage: true,
            sizePerPageList: [ {
                  text: '5', value: 5
                }, {
                  text: '10', value: 10
                }, {
                  text: 'All', value: transfers.length
                } ]
          })  } />*/
        //   <table className="table table-striped table-hover">
        //     <thead>
        //       <tr>
        //         <th className='from'>From</th>
        //         <th className='to'>To</th>
        //         <th className='amount'>S$</th>
        //         <th className='action'>Action</th>
        //       </tr>
        //     </thead>

        //     <tbody>
        //         {transfers.map(this.renderTable)}
        //     </tbody>

        //   </table>
        //   {popup}

        // </div>
        // </div>);
        )
      }
    }




  }
}

export default AdminMobileTransfer;
