import React, {Component} from "react";
import { removeCommas } from "../admin/service/excelService";

class ProcessReserveTable extends Component {

    handleClick = (e) => {
        const { focusRow, list_new, handleData, inputValid, handleFocusRow } = this.props;
        if (!this.node.contains(e.target)){
          console.log('click outside', e.target, focusRow);
          console.log(e.target.tagName, e.target.type);
          // If click outside, reset the focus
          if (e !== undefined && e.target!== undefined && e.target.type !== 'textarea' && e.target.tagName !== 'TR'){ // if not clicking textarea, roundup the value and reset focusRow id
            if (focusRow){
            // if (focusRow){
              console.log('reset focusRow, can format the value:', list_new[focusRow].val)
              const data = {...list_new};
              data[focusRow].val = removeCommas(data[focusRow].val);
            //   this.setState({data});
              handleData(data);
              inputValid();
              // console.log(this.state.governList_new)
            }
    
            // this.setState({focusRow:''})
            handleFocusRow('');
            // focusRow = ''
          }
          return;
        }
      }

    render() {
        const { handleSubmit, renderMethod, data, columns } = this.props;

            return(
            
                <div className = "container" onClick = {this.handleClick}>

                    <div className="save-button">
                    <button type="button" className="btn btn-primary submit process-reserve" onClick={handleSubmit}>SAVE</button>
                    </div>
        
                    <div className = "table-container reserve-table react-bootstrap-table">
                    <form>
                        <table className="table">
                            <thead>
                            <tr>
                                {columns.map(col => {
                                   return (col=='Staff Id') ? <th ref={node => this.node = node}>{col}</th> : <th>{col}</th>
                                })}
                            </tr>
                            </thead>
                            {renderMethod(data)}
                        </table>
                    </form>
        
                    </div>
        
                    <div className="save-button">
                    <button type="button" className="btn btn-primary submit process-reserve" onClick={handleSubmit}>SAVE</button>
                    </div>
    
    
                </div>
            )
    }
}

export default ProcessReserveTable;