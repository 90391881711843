import React, { Component } from "react";
// import Select from 'react-select';
import DropdownComponent from "../../components/dropdown";
import moment from 'moment';
import { Redirect, BrowserRouter, Route, Switch } from "react-router-dom";
import Logout from "./../../logout";
import withRouter from "../../components/withRouter";

import ReactExport from "react-data-export";
// import DatasetTable from "./report_employee_table";
// import YearlyReportTable from "../yearly_report/report_yearly_table";
import ReportTable from "../../components/reportTable";
import ExportPDF from "../../components/exportPDF.js";
// import { NotificationManager } from 'react-notifications';

import { borderedCell_employee as borderedCell, changeFloat, roundup, numberWithCommas_display as numberWithCommas } from "../service/excelService";
import { Toast } from "primereact/toast";
import CheckButton from "../../components/checkButton";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

var service = require('../service/getinfo.js');
var logging = require('../service/loggingComponent.js');
const loggingHeader = '[frontend][report_employee]';
// const customStyles = service.customStyles;

var config = require('../../config.js');
const backend = config.backend;
const report_title = "The Navigators Singapore";
const report_subtitle = "Yearly Reserves Report for ";


class Report_Employee extends Component {

  constructor(props) {
    super(props);
    this.state = {
      staff: '',
      staffname: '',
      year: '',
      years: [],
      errors: {},
      staffs: [],
      checked: false,
      space: {
        columns: [],
        data: []
      },
      multiData: [],
      filename: 'report',
      redirectLogout: false

    }
    this.showError = this.showError.bind(this);
    // this.showSuccess = this.showSuccess.bind(this);
  }

  _isMounted = false;


  componentDidMount() {
    this._isMounted = true;
    this.renderYear();
    this.getAllStaff();

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // showSuccess(message) {
  //   this.toast.show({severity:'success', summary: 'Processing Success', detail:message, life: 3000});
  // }

  showError(message) {
    this.toast.show({severity:'error', summary: 'Processing Failed', detail:message, life: 3000});
  } 

  getAllStaff = _ => {
    // Get all the staffs
    // fetch(backend + `api/staffsimple`)
    fetch(backend + `api/allreservestaff`, { // 10 Feb 2021 add in former field staff
      method: 'GET',
      credentials: "include"
    })
      .then(res => res.json())
      .then(res => {

        if (res.redirect) {
          sessionStorage.setItem('int', 'logout');
          this.setState({ redirectLogout: true })
          window.location.reload(false);
        }

        if (this._isMounted) {
          this.setState({ staffs: res.data });
        }

        // console.log('staffs', this.state.staffs);

      });
  }


  renderYear = _ => {

    service.getYearList()
      .then((years_drop) => {

        if (this._isMounted) {
          this.setState({ years: years_drop });
        }

      })


  }

  handleYearChange = (y) => {
    logging.logToGoogle(loggingHeader + ' click year dropdown list');
    this.setState({ year: y.value });

  }

  handleStaffChange = (toStaff) => {
    logging.logToGoogle(loggingHeader + ' Click staff dropdown list');
    const { staffs } = this.state;
    var staffDetails = staffs.filter(staff => staff.value === toStaff.value)
    this.setState({ staff: toStaff.value });
    this.setState({ staffname: staffDetails[0].label });
    // console.log(this.state.staff);
    // console.log(toStaff.value);

  }

  inputValid = () => {
    let errors = {};
    let formIsValid = true;

    const { year } = this.state;
    const { staff } = this.state;

    // console.log('year', year);
    // console.log('staff', staff);

    //Check To Staff Id
    if (!(isFinite(String(year)) && year !== '')) {
      console.log(isFinite(String(year)));
      errors['year'] = 'Please choose a year!';
      formIsValid = false;
    }


    if (!(isFinite(String(staff)) && staff !== '')) {
      errors['staff'] = 'Please choose a staff!';
      formIsValid = false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }


  // Report table structure:
  // MultiData = [
  //       title: {
  //         columns: [],
  //         data: []
  //       },
  //       subtitle:{
  //         columns: [],
  //         data: []
  //       }
  //       income: {
  //             columns : ['MONTH', months],
  //             data: incomedata
  //             [
  //               [
  //                  {
  //                     value: '',
  //                     style: {
  //                       border:{top:{
  //                           style: '',
  //                           color:{rgb: ''}
  //                         }}
  //                     }
  //                  },
  //                  ...
  //               ]
  //               ['BalanceBF', staff's BalanceBF],
  //               ['Donations', staff's Donations],
  //               ['CashProject', staff's CashProject]
  //               ...
  //             ]
  //       },
  //       space,
  //       deduct:{
  //             columns : ['Deduction'],
  //             data: deductdata
  //             [
  //               ['Insurance', staff's Insurance],
  //               ['AdminCharges', staff's AdminCharges],
  //               ['APLT', staff's APLT]
  //               ...
  //             ]
  //       },
  //       space,
  //       precentage:{
  //             columns : [],
  //             data: percentagedata
  //             [
  //               ['Total Collection...', staff's total_ratio]
  //             ]
  //       }
  // ]


  checkReserve = _ => {
    logging.logToGoogle(loggingHeader + ' clicked CHECK button');
    const { year } = this.state;
    const { staff } = this.state;
    // const {space} = this.state;
    // const {staffname} = this.state;

    var title = this.generateTitle(year);
    var staffDetails = this.generateStaffDetails();
    console.log(staffDetails)
    var date = this.generateDate();

    var income = {
      columns: [
        { title: '', width: { wch: 22 } },
        { title: '', width: { wch: 1 } }
      ],
      data: []
    };

    // Empty Column row to set the cell width
    for (var j = 0; j < 12; j++) {
      income.columns.push({ title: '', width: { wch: 9 } })
    }
    income.columns.push({ title: '', width: { wch: 11 } })
    income.columns.push({ title: '', width: { wch: 9 } })

    // Generate months columns for income table
    var col_row = [
      {
        value: 'MONTH',
        style: {
          alignment: {
            horizontal: 'center',
            vertical: 'bottom'
          },
          font: { bold: true }

        }
      },
      ''
    ]
    const months = moment.monthsShort();
    for (var m in months) {
      col_row.push({
        value: months[m],
        style: {
          alignment: {
            horizontal: 'center',
            vertical: 'bottom'
          },
          font: { bold: true }

        }
      });
    }
    col_row.push({
      value: 'TOTAL',
      style: {
        alignment: {
          horizontal: 'center',
          vertical: 'bottom'
        },
        font: { bold: true }
      }
    });
    col_row.push({
      value: 'AVERAGE',
      style: {
        alignment: {
          horizontal: 'center',
          vertical: 'bottom'
        },
        font: { bold: true }
      }
    });

    // Generate empty row with bottom border line
    var months_data = [];
    for (var i = 0; i < 16; i++) {
      var cell = {};
      cell['value'] = '';
      cell['style'] = {
        border: {
          top: { style: 'thick', color: { rgb: '000000' } }
        },
        alignment: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      }
      months_data.push(cell);
      // console.log('months_data',months_data);
    }
    var incomedata = [];
    incomedata.push(col_row);

    incomedata.push(months_data);


    var deduct = {
      columns: [],
      data: []
    };
    // deduct['columns'] = [];


    var precentage = {
      columns: [],
      data: []
    };
    // precentage['columns'] = [];


    var deductdata = [
      [{ value: 'Deduction', style: { font: { underline: true, bold: true }, width: { wch: 32 } } }]
    ];
    var percentagedata = [];



    // console.log(year, staff);

    if (!this.inputValid()) {
      // NotificationManager.error('Please check your input!');
      this.showError('Please check your input!');
    }
    else {
      this.setState({ checked: true });

      service.fetch_retry(backend + `api/reserve/staff/` + staff + '/year/' + year, {
        method: 'GET',
        credentials: "include"
      }, 3)
        .then(res => res.json())
        .then((res) => {
    
          console.log('res',res);
          var i = 12;

          var BalanceBF = ['Balance B/F', ''];
          var BalanceBF_total = 0;
          var Donations = ['Donations', ''];
          var Donations_total = 0;
          var CashProject = ['CashProject', ''];
          var CashProject_total = 0;
          var TransferIN = ['Tranfers (IN)', ''];
          var TransferIN_total = 0;
          var GovernCredit = ['Job Credit', ''];
          var GovernCredit_total = 0;
          var IncomeOthers = ['Others', ''];
          var IncomeOthers_total = 0;
          var IncomeTotal_final = ['Total', ''];
          var IncomeTotal_final_total = 0;
          var Budget = ['Budget', ''];
          var Budget_total = 0;
          var sOrd_addition_final = ['Surplus/Deficit', ''];

          // Deduct.data
          var Insurance = ['Insurance', ''];
          var Insurance_total = 0;
          var AdminCharges = ['Singapore Admin Charges', ''];
          var AdminCharges_total = 0;
          var APLT = ['APLT', ''];
          var APLT_total = 0;
          var MinistryExp = ['Ministry Expenses', ''];
          var MinistryExp_total = 0;
          var TransferOUT = ['Transfers (OUT)', ''];
          var TransferOUT_total = 0;
          var OutcomeOthers = ['Others', ''];
          var OutcomeOthers_total = 0;
          var EmperCPF = ['Employer CPF', ''];
          var EmperCPF_total = 0;
          var CDAC = ['CDAC Fund', ''];
          var CDAC_total = 0;
          var EmpeeCPF = ['Employee CPF', ''];
          var EmpeeCPF_total = 0;
          var SINDA = ['SINDA Fund', ''];
          var SINDA_total = 0;
          var RelatedFund = ['Other Funds', ''];
          var RelatedFund_total = 0;
          var CreditCard = ['Credit Card Charges', ''];
          var CreditCard_total = 0;
          var Cheque = ['Cheque Charges', ''];
          var Cheque_total = 0;
          var NetSalary = ['Net Salary', ''];
          var NetSalary_total = 0;
          var OutcomeTotal_final = ['Total', ''];
          var OutcomeTotal_final_total = 0;
          var sOrd_deduction_final = ['Under/Over Spend', ''];
          var ReservedFundCF = ['Reserve Fund C/F', ''];

          // precentage.data
          var total_Ratio = [
            {
              value: 'Total Collection Vs Budget (%)',
              style: {
                alignment: { wrapText: true }
              }
            },
            ''
          ]
          // var total_Ratio = ['Total Collection Vs Budget (%)',''];

          for (var r in res) {
            if (res[r] !== null) {
              // i = i + 1;
              
              BalanceBF.push(borderedCell(res[r].BalanceBF));
              BalanceBF_total += changeFloat(res[r].BalanceBF);

              Donations.push(borderedCell(res[r].Donations));
              Donations_total += changeFloat(res[r].Donations);
              CashProject.push(borderedCell(res[r].CashProject));
              CashProject_total += changeFloat(res[r].CashProject);
              TransferIN.push(borderedCell(res[r].TransferIN));
              TransferIN_total += changeFloat(res[r].TransferIN);
              GovernCredit.push(borderedCell(res[r].GovernCredit));
              GovernCredit_total += changeFloat(res[r].GovernCredit);
              IncomeOthers.push(borderedCell(res[r].IncomeOthers));
              IncomeOthers_total += changeFloat(res[r].IncomeOthers);

              var incomeTotal = changeFloat(res[r].BalanceBF) + changeFloat(res[r].Donations) + changeFloat(res[r].CashProject) + changeFloat(res[r].TransferIN) + changeFloat(res[r].GovernCredit) + changeFloat(res[r].IncomeOthers);
              IncomeTotal_final.push(borderedCell(incomeTotal));
              IncomeTotal_final_total += changeFloat(incomeTotal);


              Budget.push(borderedCell(res[r].Budget));
              Budget_total += changeFloat(res[r].Budget);

              var sOrd_addition = roundup(changeFloat(res[r].Donations) - changeFloat(res[r].Budget));

              sOrd_addition_final.push(borderedCell(sOrd_addition));

              Insurance.push(borderedCell(res[r].Insurance));
              Insurance_total += changeFloat(res[r].Insurance);

              AdminCharges.push(borderedCell(res[r].AdminCharges));
              AdminCharges_total += changeFloat(res[r].AdminCharges);

              APLT.push(borderedCell(res[r].APLT));
              APLT_total += changeFloat(res[r].APLT);

              MinistryExp.push(borderedCell(res[r].MinistryExp));
              MinistryExp_total += changeFloat(res[r].MinistryExp);

              TransferOUT.push(borderedCell(res[r].TransferOUT));
              TransferOUT_total += changeFloat(res[r].TransferOUT);

              OutcomeOthers.push(borderedCell(res[r].OutcomeOthers));
              OutcomeOthers_total += changeFloat(res[r].OutcomeOthers);
              EmpeeCPF.push(borderedCell(res[r].EmpeeCPF));
              EmpeeCPF_total += changeFloat(res[r].EmpeeCPF);
              EmperCPF.push(borderedCell(res[r].EmperCPF));
              EmperCPF_total += changeFloat(res[r].EmperCPF);
              CDAC.push(borderedCell(res[r].CDAC));
              CDAC_total += changeFloat(res[r].CDAC);
              SINDA.push(borderedCell(res[r].SINDA));
              SINDA_total += changeFloat(res[r].SINDA);

              RelatedFund.push(borderedCell(res[r].RelatedFunds));
              RelatedFund_total += changeFloat(res[r].RelatedFunds);
              CreditCard.push(borderedCell(res[r].CreditCard));
              CreditCard_total += changeFloat(res[r].CreditCard);
              Cheque.push(borderedCell(res[r].Cheque));
              Cheque_total += changeFloat(res[r].Cheque);
              var outcomeTotal = changeFloat(res[r].CDAC) + changeFloat(res[r].SINDA);

              // if (res[r].CDAC !== '0') {
              //   CDAC.push(parseFloat(res[r].CDAC));
              //   CDAC_total = CDAC_total + parseFloat(res[r].CDAC);
              //   var outcomeTotal = parseFloat(res[r].CDAC);
              // }
              // else if(res[r].SINDA !== '0'){
              // // else {
              //   SINDA.push(parseFloat(res[r].SINDA));
              //   SINDA_total = SINDA_total + parseFloat(res[r].SINDA);
              //   var outcomeTotal = parseFloat(res[r].SINDA);
              // }
              // else{
              //   CDAC.push('');
              //   SINDA.push('');
              
              // }
              NetSalary.push(borderedCell(res[r].NetSalary));
              NetSalary_total += changeFloat(res[r].NetSalary);

              outcomeTotal = outcomeTotal + changeFloat(res[r].Insurance) + changeFloat(res[r].AdminCharges) + changeFloat(res[r].APLT) + changeFloat(res[r].MinistryExp) + changeFloat(res[r].TransferOUT) + changeFloat(res[r].CreditCard) + changeFloat(res[r].Cheque) + changeFloat(res[r].OutcomeOthers) + changeFloat(res[r].EmpeeCPF) + changeFloat(res[r].EmperCPF) + changeFloat(res[r].NetSalary) + changeFloat(res[r].RelatedFunds);

              OutcomeTotal_final.push(borderedCell(outcomeTotal));
              OutcomeTotal_final_total += changeFloat(outcomeTotal);

              var sOrd_deduction = roundup(changeFloat(res[r].Budget) - outcomeTotal);
              sOrd_deduction_final.push(borderedCell(sOrd_deduction));

              // ReservedFundCF.push(borderedCell(res[r].ReservedFundCF));
              ReservedFundCF.push(borderedCell(incomeTotal - outcomeTotal));

              var total_Ratio_final;
              if (changeFloat(res[r].Budget) === 0) {
                total_Ratio_final = 'Budget is not defined';
              }
              else {
                total_Ratio_final = numberWithCommas(Math.round((changeFloat(res[r].Donations) / changeFloat(res[r].Budget)) * 10000) / 100) + '%';
              }
              total_Ratio.push(borderedCell(total_Ratio_final, true, true));
              // console.log('total_Ratio_final', total_Ratio);

            }
            else {
              BalanceBF.push('');
              Donations.push('');
              CashProject.push('');
              TransferIN.push('');
              GovernCredit.push('');
              IncomeOthers.push('');
              IncomeTotal_final.push('');
              Budget.push('');
              sOrd_addition_final.push('');

              Insurance.push('');
              AdminCharges.push('');
              APLT.push('');
              MinistryExp.push('');
              TransferOUT.push('');
              CreditCard.push('');
              Cheque.push('');
              RelatedFund.push('');
              OutcomeOthers.push('');
              EmpeeCPF.push('');
              EmperCPF.push('');
              CDAC.push('');
              SINDA.push('');
              NetSalary.push('');
              OutcomeTotal_final.push('');
              sOrd_deduction_final.push('');
              ReservedFundCF.push('');
              total_Ratio.push('');
            }
          }

          // balance_avg = balance_total / i;
          BalanceBF.push(borderedCell(BalanceBF_total));

          Donations.push(borderedCell(Donations_total));
          Donations.push(borderedCell(Donations_total / i));

          CashProject.push(borderedCell(CashProject_total));
          TransferIN.push(borderedCell(TransferIN_total));
          GovernCredit.push(borderedCell(GovernCredit_total));
          IncomeOthers.push(borderedCell(IncomeOthers_total));

          IncomeTotal_final.push(borderedCell(IncomeTotal_final_total));
          IncomeTotal_final.push(borderedCell(IncomeTotal_final_total / i));

          Budget.push(borderedCell(Budget_total));

          sOrd_addition_final.push(borderedCell(Donations_total - Budget_total));

          Insurance.push(borderedCell(Insurance_total));
          AdminCharges.push(borderedCell(AdminCharges_total));
          APLT.push(borderedCell(APLT_total));
          MinistryExp.push(borderedCell(MinistryExp_total));
          TransferOUT.push(borderedCell(TransferOUT_total));
          CreditCard.push(borderedCell(CreditCard_total));
          Cheque.push(borderedCell(Cheque_total));
          RelatedFund.push(borderedCell(RelatedFund_total));
          OutcomeOthers.push(borderedCell(OutcomeOthers_total));

          EmpeeCPF.push(borderedCell(EmpeeCPF_total));
          EmperCPF.push(borderedCell(EmperCPF_total));
          CDAC.push(borderedCell(CDAC_total));
          SINDA.push(borderedCell(SINDA_total));
          NetSalary.push(borderedCell(NetSalary_total));
          OutcomeTotal_final.push(borderedCell(OutcomeTotal_final_total));
          OutcomeTotal_final.push(borderedCell(OutcomeTotal_final_total / i));
          sOrd_deduction_final.push(borderedCell(OutcomeTotal_final_total - Budget_total));
          ReservedFundCF.push(borderedCell(IncomeTotal_final_total - OutcomeTotal_final_total));

          if (Budget_total === 0 || isNaN(Budget_total)) {
            total_Ratio.push(borderedCell('Budget is not defined'));

          }
          else {
            var temp = ((Math.round((Donations_total / Budget_total) * 10000) / 100) + '%');
            // console.log('temp', temp);
            total_Ratio.push(borderedCell(temp, true, true));
          }

          BalanceBF.push('');
          // Donations.push('');
          CashProject.push('');
          TransferIN.push('');
          GovernCredit.push('');
          IncomeOthers.push('');
          // IncomeTotal_final.push('');
          Budget.push('');
          sOrd_addition_final.push('');

          Insurance.push('');
          AdminCharges.push('');
          APLT.push('');
          MinistryExp.push('');
          TransferOUT.push('');
          CreditCard.push('');
          Cheque.push('');
          RelatedFund.push('');
          OutcomeOthers.push('');
          EmpeeCPF.push('');
          EmperCPF.push('');
          CDAC.push('');
          SINDA.push('');
          NetSalary.push('');
          // OutcomeTotal_final.push('');
          sOrd_deduction_final.push('');
          ReservedFundCF.push('');
          total_Ratio.push('');

          incomedata.push(BalanceBF);
          incomedata.push(Donations);
          incomedata.push(CashProject);
          incomedata.push(TransferIN);
          incomedata.push(GovernCredit);
          incomedata.push(IncomeOthers);
          incomedata.push(IncomeTotal_final);
          incomedata.push(Budget);
          incomedata.push(sOrd_addition_final);


          income['data'] = incomedata;

          deductdata.push(Insurance);
          deductdata.push(AdminCharges);
          deductdata.push(APLT);
          // Decide which row to add in
          if (CDAC.length !== 0) {
            // console.log('CDAC added in', CDAC);
            deductdata.push(CDAC);
          }
          else {
            // console.log('SINDA added in', SINDA);
            deductdata.push(SINDA);
          }
          deductdata.push(RelatedFund);;
          deductdata.push(EmperCPF);
          deductdata.push(EmpeeCPF);
          deductdata.push(NetSalary);
          deductdata.push(MinistryExp);
          deductdata.push(TransferOUT);
          deductdata.push(CreditCard);
          deductdata.push(Cheque);
          deductdata.push(OutcomeOthers);
          deductdata.push(OutcomeTotal_final);
          deductdata.push(Budget);
          deductdata.push(sOrd_deduction_final);
          deductdata.push(ReservedFundCF);

          deduct['data'] = deductdata;

          percentagedata.push(total_Ratio);

          precentage['data'] = percentagedata;

          if (this._isMounted) {
            this.setState({ multiData: [title, date, staffDetails, income, deduct, precentage] });
            this.setState({ checked: true });
          }

          // console.log(this.state.multiData);

        })
        .catch((err) => {
          this.showError('Reserve is unable to generate. Please contact our IT staff for assistance.');
        })

    }

  }


  generateTitle(year) {

    // title and month will have its own function
    var title = {
      ySteps: -1,
      xSteps: 7,
      columns: [
      ],
      data: [
        [

          {
            value: report_title,
            style: {
              font: {
                sz: '18',
                bold: true
              },
              alignment: {
                horizontal: 'center',
                vertical: 'center',
                readingOrder: 4
              }
            }
          }

        ],

        [
          {
            value: report_subtitle + year,
            style: {
              font: {
                bold: true
              },
              alignment: {
                horizontal: 'center',
                vertical: 'center'
              }
            }
          }
        ],


        // ['Name: ', staffname]

      ]
    }
    return title;
  }

  generateStaffDetails() {
    const { staffname } = this.state;

    var staffdetail = {
      ySteps: -1,
      columns: [],
      data: [
        ['Name: ', staffname]
      ]
    };
    return staffdetail;
  }

  generateDate() {
    var date = moment().format('DD/MM/YYYY hh:mm:ss A');
    var datedetail = {
      ySteps: -2,
      xSteps: 13,
      columns: [],
      data: [
        [date]
      ]
    }
    return datedetail;
  }

  renderReport() {
    const { staffname } = this.state;
    const { multiData } = this.state;
    const { year } = this.state;
    console.log(multiData);

    const filename = staffname + "'s " + year + " Yearly Report";
    // console.log(filename);

    let checked = this.state.checked;

    if (checked) {
      // console.log('if checked',multiData.length);

      if (multiData.length !== 0 && multiData.length !== undefined) {
        // console.log('multiData is updated', multiData);
        return (<div className="row">

          <div className="download-report col-sm-12">

            <ExcelFile element={<button type="button" className="btn btn-warning col-sm-2 button-excel">Download Excel</button>} filename={filename}>
              <ExcelSheet dataSet={multiData} name="Report" />
            </ExcelFile>
            <ExportPDF data={multiData} type='Employee' />

          </div>

          <div className="row">
            <ReportTable dataSet={multiData} type='employee' />
          </div>

        </div>);
      }
      else {
        // console.log('multiData is empty');
        return <h4 className='title'>Loading...</h4>
      }
    }
    // else{
    //   return (<h1>a excel form suppose to be here</h1>);
    // }
  }


  render() {

    const { redirectLogout } = this.state;

    if (redirectLogout) {
      return (
        <BrowserRouter>
          <Switch>
          <Route path={this.props.location.pathname} render={() => <Redirect to="/logout" />} />
            {/* <Route exact path="/logout" component={Logout} />
            <Redirect to="/logout" /> */}
          </Switch>
        </BrowserRouter>
      )
    }

    const { staffs } = this.state;
    const { years } = this.state;

    console.log(staffs)

    staffs.sort((a, b) => {
      return a.label > b.label ? 1 : -1;
    });

    let report_table = this.renderReport();
    console.log(staffs)
    // console.log('multiData',multiData);


    return (
      <div className="yearly-report">
        <Toast ref={(el) => this.toast = el} />
        <h4 className="title">Generate Yearly Reserves Per Employee Report</h4>
        <div className="form-set row">
          <form className="form-horizontal">
            <div className="form-sets col-sm-12">
              <fieldset>
                <div className="fieldset">

                  <div className="buttons year col-sm-4">
                    <div className="btn-group">
                      {/* <Select className='react-select-container' styles={customStyles} options={years} isSearchable="false" placeholder="year" onChange={this.handleYearChange} /> */}
                      <DropdownComponent
                      options={years}
                      placeholder="year"
                      handleChange={this.handleYearChange}
                      value={this.state.year} />
                      <span className="error">{this.state.errors["year"]}</span>
                    </div>
                  </div>

                  <div className="buttons month col-sm-4">
                    <div className="btn-group">
                      {/* <Select className='react-select-container' styles={customStyles} options={staffs} isSearchable="false" placeholder="staff" onChange={this.handleStaffChange} /> */}
                      <DropdownComponent
                      options={staffs}
                      placeholder="staff"
                      handleChange={this.handleStaffChange}
                      value={this.state.staff} />
                      <span className="error">{this.state.errors["staff"]}</span>

                    </div>
                  </div>

                  <div className="col-sm-4 check-button">
                    {/* <button type="button" className="btn btn-check btn-primary submit" onClick={this.checkReserve}>CHECK</button> */}
                    <CheckButton 
                    checkReserve={this.checkReserve}/>
                  </div>

                </div>
              </fieldset>
            </div>
          </form>
        </div>
        <div className="report-table" id="reserve-table">
          {report_table}

        </div>

      </div>


    )

  }
}

export default withRouter(Report_Employee);
