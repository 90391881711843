import React, {Component} from "react";
// import '../index.css';
import {fetch_retry} from "./service/getinfo";
import { roundup } from "./service/excelService";

var config = require('../config.js');
const backend = config.backend;


class Params_List extends Component {
  state = {
    APLT_Rate:null,
    AdminCharges_Rate:null,
    NDName: null,
    ND_Allow: null,
    ND_CDAC: null,
    ND_CPFRate: null,
    CreditCard:{},
    Cheque:{},
    email:{},
    redis:{}
  }


  componentWillMount() {
    this.getParams();

  }


  getParams = _ => {

    // Get all the staffs
    fetch_retry(backend + `api/params`, {
      method: 'GET',
      credentials: 'include'
    }, 3)
    .then(res => res.json())
    .then(({data}) => {

      // console.log('data',data);

      this.setState({
        APLT_Rate:data.APLT_Rate,
        AdminCharges_Rate: data.AdminCharges_Rate,
        CreditCard: data.CreditCard,
        Cheque: data.Cheque,
        NDName : data.NDName,
        ND_Allow : data.ND_Allow,
        ND_CDAC : data.ND_CDAC,
        ND_CPFRate : data.ND_CPFRate,
        email: data.email,
        redis: data.redis
        }, ()=>{
        // console.log('CreditCard', this.state.CreditCard);
      });

    })
    .catch(err => console.error(err))

  }

  render(){

    const {APLT_Rate}= this.state;
    const {AdminCharges_Rate}= this.state;
    const {NDName}= this.state;
    const {ND_CPFRate}= this.state;
    const {ND_Allow}= this.state;
    const {ND_CDAC}= this.state;
    const {CreditCard}= this.state;
    const {Cheque}= this.state;
    const {email}= this.state;
    // const {redis}= this.state;
    //
    // console.log('email', email);
    // console.log('email.transfer', email.transfer);

    var email_transfer;
    if (email.transfer){
      email_transfer = email.transfer;
      // console.log('email_transfer', email_transfer);
      // var admin = email_transfer.Admin_Email;
      // console.log(admin)
    }

    var APLT = parseFloat(APLT_Rate) * 100;
    var AdminCharges = parseFloat(AdminCharges_Rate) * 100;
    var credit_rate = (CreditCard.Rate) ? roundup(parseFloat(CreditCard.Rate.New) * 100) : 0;
    var ND_CPF = parseFloat(ND_CPFRate) * 100;

    if (APLT_Rate !== {} && APLT_Rate !== undefined && email_transfer){
      return (
        <div>

          <h1>
            Parameter List
          </h1>

          <div className="paramsTable">

          <table className="table table-hover">

              <thead>
                <tr>
                  <td>Admin Charges</td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Admin Charges Rate</td><td>{AdminCharges}%</td>
                </tr>
                <tr>
                  <td>APLT Rate</td><td>{APLT}%</td>
                </tr>
              </tbody>


              <thead>
                <tr>
                  <td>Credit Card Charges</td><td></td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Cash Advance Fee Per Transaction</td><td>{credit_rate}%</td>
                </tr>
                <tr>
                  <td>Transaction Fee</td><td>${CreditCard.Trans.New.toFixed(2)}</td>
                </tr>
              </tbody>

              <thead>
                <tr>
                  <td>Cheque Charges</td><td></td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Transaction Fee</td><td>${Cheque.Trans.New}</td>
                </tr>
              </tbody>

              <thead>
                <tr>
                  <td>National Director Details</td><td></td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>National Director Name</td><td>{NDName}</td>
                </tr>
                <tr>
                  <td>ND Allowance</td><td>${ND_Allow}</td>
                </tr>
                <tr>
                  <td>ND CDAC</td><td>${ND_CDAC}</td>
                </tr>
                <tr>
                  <td>CPF Rate</td><td>{ND_CPF}%</td>
                </tr>
                <tr>
                  <td>Formula</td> <td>CPF = (Basic Pay + Allowance - ND Allowance) * CPF Rate </td>
                </tr>
                <tr>
                  <td></td> <td>Net Pay = (Basic Pay + Allowance - ND Allowance) - CPF - ND CDAC</td>
                </tr>
                <tr>
                  <td></td> <td>Admin Charges = Net Pay * Admin Charges Rate</td>
                </tr>
                <tr>
                  <td></td> <td>APLT Charges = Net Pay * APLT Rate</td>
                </tr>

              </tbody>


              <thead>
                <tr>
                  <td>Transfer Email Details</td><td></td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Admin Email</td><td>{email_transfer.Admin_Email}</td>
                </tr>
                <tr>
                  <td>Sender</td><td>{email_transfer.sender}</td>
                </tr>
                <tr>
                  <td>Signature</td><td>{email_transfer.signature}</td>
                </tr>

              </tbody>



          </table>


          </div>

        </div>

      );
    }
    else{

      return (<h2> Loading ... </h2>)
    }



  }

}


export default Params_List;
