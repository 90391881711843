import React, { Component } from "react";
import Cookies from 'js-cookie';

class Logout extends Component {


  componentDidMount() {

    // Cookies.remove('user');
    // Cookies.set('auth', false);
    Cookies.remove('jwt');

    sessionStorage.removeItem('staff');
    sessionStorage.removeItem('super');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('id_s');
    sessionStorage.removeItem('admin');
    sessionStorage.removeItem('id_e');
    sessionStorage.removeItem('version');
    sessionStorage.removeItem('email');

  }

  setIntToLogin() {
    return new Promise(function (resolve, reject) {
      sessionStorage.setItem('int', 'login');
      resolve(1);
    });
  }



  handleLogin = _ => {
    this.setIntToLogin()
      .then((result) => {
        console.log('redirect to login')
        this.props.history.push('/login');
      });
  }


  render() {

    return (
      <div>
        <div className='center'>

          <h1>You have been signed out.</h1>
          <h3><a href="# " onClick={() => { this.handleLogin() }}>Return to the Reserves Service.</a></h3>

        </div>
      </div>
    );
  }
}


// export { strategy }
export default Logout;
