import React, { Component } from "react";
import { fetch_retry } from "./service/getinfo";
import { NotificationManager } from 'react-notifications';
import ScrollContainer from 'react-indiana-drag-scroll'
import { Redirect, BrowserRouter, Route, Switch } from "react-router-dom";
import Logout from "./../logout";

var config = require('../config.js');
const backend = config.backend;

class Manage_Staff extends Component {

  _isMounted = false;

  state = {
    staffs: [],
    redirectLogout: false
  }

  componentDidMount() {
    this._isMounted = true;
    this.getStaffs();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getStaffs = _ => {
    fetch_retry(backend + `api/staff/all`, {
      method: 'GET',
      credentials: "include"
    }, 3)
      .then(response => response.json())
      .then(response => {

        if (response.redirect) {
          this.setState({ redirectLogout: true })
          window.location.reload(false);
        }

        var data = response.data;

        data.sort((a, b) => {
          if (a.StaffType === b.StaffType) {
            return a.Name.toUpperCase() - b.Name.toUpperCase()
          }
          else {
            return a.StaffType.toUpperCase() < b.StaffType.toUpperCase() ? -1 : 1
          }
        })
        // console.log(data)
        return (data)

      })
      .then((data) => {
        if (this._isMounted) {
          this.setState({ staffs: data });
        }

      })
      .catch(err => console.error(err))

  }

  formatDate = (d, p) => {
    var moment = require('moment');
    var new_date = '';
    JSON.stringify(d);
    if (d[JSON.parse(p)]) {
      new_date = moment(new Date(d[JSON.parse(p)])).format("MMM-DD-YYYY");
      // new_date = moment(d,"MMM-DD-YYYY" );
    }
    // console.log(new_date);
    return new_date;
  }

  renderStaff = ({ StaffId, employeeId, StaffType, Name, NRIC, DoB, Marital, Profile, CurrentBudget, DateJoined, LastRevisionDate, psedBudget, wef }) => {

    var DoB_ = this.formatDate({ DoB }, '"DoB"');
    var DateJoined_ = this.formatDate({ DateJoined }, '"DateJoined"');
    var LastRevisionDate_ = this.formatDate({ LastRevisionDate }, '"LastRevisionDate"');
    var wef_ = this.formatDate({ wef }, '"wef"');

    return <tr key={employeeId}>
      <td>{StaffId}</td>
      <td>{StaffType}</td>
      <td>{Name}</td>
      <td>{NRIC}</td>
      <td type="date">{DoB_}</td>
      <td>{Marital}</td>
      <td>{Profile}</td>
      <td type="date">{DateJoined_}</td>
      <td>{CurrentBudget}</td>
      <td type="date">{LastRevisionDate_}</td>
      <td>{psedBudget}</td>
      <td type="date">{wef_}</td>
      {/* <td>
        <button className="btn btn-default btn-sm" onClick={this.deleteStaff.bind(this, {StaffId})}><i className="material-icons">clear</i></button>
      </td> */}
    </tr>


  }

  deleteStaff = (staffId) => {
    console.log(staffId["StaffId"]);

    NotificationManager.warning("Deleting this staff will result in deleting all the reserve and budget records.");


    // Delete from the list
    this.setState(state => {
      // this.state.staffs = this.deleteStaffFromList(staffId["StaffId"]);

      this.setState({ staffs: this.deleteStaffFromList(staffId["StaffId"]) })
      return this.state;
    });


    // Delte from the SQL Database by calling HTTP delete request to api
    this.deleteStaffFromSQL(staffId["StaffId"])
      .then((data) => {
        console.log('finish deleting');
        console.log(data);
      })
      .catch(err => err);

  }

  deleteStaffFromSQL(id) {
    console.log('sql param is ' + id);

    return fetch(backend + 'api/staff/' + id, {
      method: 'DELETE',
      credentials: "include"
    })
      .then(res => res)
      .catch(err => err);
  }

  deleteStaffFromList(id) {
    return this.state.staffs.filter(staffs => staffs.StaffId !== id);
  }

  render() {

    const { redirectLogout } = this.state;

    if (redirectLogout) {
      return (
        <BrowserRouter>
          <Switch>
            <Route exact path="/logout" component={Logout} />
            <Redirect to="/logout" />
          </Switch>
        </BrowserRouter>
      )
    }

    const { staffs } = this.state;
    staffs.sort((a, b) => {
      return a.StaffType - b.StaffType || a.Name - b.Name;
    });

    if (staffs.length !== 0) {

      return (<div>
        <h2>Manage Staff</h2>

        <div className="react-bootstrap-table">
          <ScrollContainer className="scroll-container">

            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Desig ID</th>
                  <th>Type</th>
                  <th>Name</th>
                  <th>NRIC</th>
                  <th>DoB</th>
                  <th>Marital</th>
                  <th>Profile</th>
                  <th>Date Joined</th>
                  <th>Current Budget</th>
                  <th>Last Revision Date</th>
                  <th>Proposed Budget</th>
                  <th>w.e.f</th>
                  {/*<th>Action</th>*/}
                </tr>
              </thead>
              <tbody>
                {staffs.map(this.renderStaff)}
              </tbody>
            </table>

          </ScrollContainer>
        </div>

      </div>);

    }
    else {
      return (<h2> Loading... </h2>)
    }

  }
}

export default Manage_Staff;
