import React, {Component} from "react";
// import Select from 'react-select';
import DropdownComponent from "../components/dropdown";
// import {NotificationManager} from 'react-notifications';

import {numberWithCommas_display as numberWithCommas} from "./service/excelService";
import {sortDic} from "./service/tableProcess";
import {fetch_retry} from "./service/getinfo";
import CheckButton from "../components/checkButton";
import ProcessReserveTable from "../components/process_reserve_table";
import { Toast } from "primereact/toast";

var config = require('../config.js');
const backend = config.backend;
var service = require('./service/getinfo.js');
var logging = require('./service/loggingComponent.js');
const loggingHeader = '[frontend][process_reserve_insurance]';
const field = 'Insurance';
// const customStyles = service.customStyles;


class Insurance extends Component {

  _isMounted = false;

  constructor(props){
    super(props);

    this.state = {

      month:'',
      year:'',
      months: [],
      staffs: [],
      years:[],
      errors:{},
      checked: 'false',
      list:{},
      list_new:{},
      focusRow:''
    }

    this.setFocusRow = this.setFocusRow.bind(this);
    this.moveCursorToEnd = this.moveCursorToEnd.bind(this);
    // this.handleClick = this.handleClick.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
    this.showError = this.showError.bind(this);
  }


  componentDidMount() {
    this.getAllStaff();
    this.renderMonth();
    this.renderYear();
    this.renderTable();
    this._isMounted = true;
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  showSuccess = (message) => {
    this.toast.show({severity:'success', summary: 'Save Success', detail:message, life: 3000});
  }

  showError = (message) => {
    this.toast.show({severity:'error', summary: 'Save Failed', detail:message, life: 3000});
  } 

  handleData = (data) => {
    this.setState({data});
  }

  handleFocusRow = (value) => {
    this.setState({focusRow: value})
  }

  // handleClick = (e) => {
  //   if (!this.node.contains(e.target)){
  //     // console.log('click outside', e.target, this.state.focusRow);
  //     // console.log(e.target.tagName, e.target.type);
  //     // If click outside, reset the focus
  //     if (e !== undefined && e.target!== undefined && e.target.type !== 'textarea' && e.target.tagName !== 'TR'){ // if not clicking textarea, roundup the value and reset focusRow id
  //       if (this.state.focusRow){
  //       // if (focusRow){
  //         // console.log('reset focusRow, can format the value:', this.state.list_new[this.state.focusRow].val)
  //         const data = {...this.state.list_new};
  //         data[this.state.focusRow].val = removeCommas(data[this.state.focusRow].val);
  //         this.setState({data});
  //         this.inputValid();
  //         // console.log(this.state.governList_new)
  //       }

  //       this.setState({focusRow:''})
  //       // focusRow = ''
  //     }
  //     return;
  //   }
  // }


  getAllStaff = _ => {

    service.getStaffList()
    .then((staffs) => {
      if (this._isMounted){
        this.setState({staffs: staffs});
      }
    })

  }

  renderMonth = _ =>{

    service.getMonthList()
    .then((months_drop) => {
      // this.setState({months:months_drop});
      if (this._isMounted){
        this.setState({months:months_drop});
      }
    })

  }

  renderYear = _ => {

    service.getYearList()
    .then((years_drop) => {
      // this.setState({years:years_drop});
      if (this._isMounted){
        this.setState({years:years_drop});
      }
    })

  }


  handleMonthChange = (m) => {

    var month = parseInt(m.value, 10 )
    logging.logToGoogle(loggingHeader + ' choosing month from dropdown list')
    // console.log(month);
    this.setState({ month: month.toString()});
    // console.log(this.state.month);

  }

  handleYearChange = (y) => {
    logging.logToGoogle(loggingHeader + ' choosing year from dropdown list')
    this.setState({ year: y.value});

  }


  inputValid = () => {
    let errors = {};
    let formIsValid = true;

    const {year}  =  this.state;
    const {month}  =  this.state;

    // console.log('year', year);
    // console.log('month', month);


      //Check To Staff Id
      if (!(isFinite(String(year)) && year !== '' )){
        // console.log(isFinite(String(year)));
        errors['year'] = 'Please choose a year!';
        formIsValid = false;
      }

      if (!(isFinite(String(month)) && month !== '' && month !== null )){
        errors['month'] = 'Please choose a month!';
        formIsValid = false;
      }


      // Check list_new
      const {list_new} = this.state;
      for (var id in list_new){
        if (isNaN(list_new[id].val)){
          errors[id] = 'Please enter a number';
          formIsValid = false
        }
      }

    this.setState({errors: errors});
    return formIsValid;
  }


  checkReserve = _ => {
    logging.logToGoogle(loggingHeader + ' clicked CHECK')
    const {year}  =  this.state;
    const {month}  =  this.state;
    const {staffs} = this.state;
    this.setState ({focusRow:''});

    if (!this.inputValid()){
      // NotificationManager.error('please check your input!');
      this.showError('please check your input!')
    }
    else{
      this.setState({checked: 'process', errors:''});

      fetch_retry(backend + `api/reserve/allstaffonemonth/` + month + '/' + year + '/0', {
        method: 'GET',
        credentials: "include"
      }, 2)
      .then(res => res.json())
      .then((res) => {


        if (res.hasOwnProperty('error')){
          console.log('error found', res['error']);
          this.setState({checked: 'error'});
        }
        else{
          res = sortDic(res.data, staffs)

          this.setState({list: res});
          var data = res.data;
          var data_new = {};
          for (var i in data){
            var info = data[i];
            // console.log(info);

            var id = Object.keys(info);

            for (var key in info){
              // console.log(info[key] == null);
              // console.log(id[0], info[key]);

              if (info[key] == null){
                // data_new[id[0]] = 0;
                data_new[id[0]] = {
                  val: 0,
                  rmk: ''
                }
              }
              else{

                // data_new[id[0]] = info[key].Insurance;

                data_new[id[0]] = {};
                data_new[id[0]].rmk = (info[key].Insurance_rmk === '0' || info[key].Insurance_rmk === 0 || info[key].Insurance_rmk === undefined || info[key].Insurance_rmk === '') ? '' : info[key].Insurance_rmk;
                data_new[id[0]].val = (info[key].Insurance === null) ? 0 : info[key].Insurance

              }

            }
          }

          // console.log(data_new)
          this.setState({list_new: data_new});
          this.setState({checked: 'true'});
        }


      })
      .catch((error) => {
        logging.logErrorToGoogle(loggingHeader + ' error: ' + error)
        console.log(error)
      });
    }

  }

  setFocusRow = (e) => {
    var id = e.target.id;
    this.setState({ focusRow: id});
  }

  moveCursorToEnd = (e) => {
    var temp_value = e.target.value
    e.target.value = ''
    e.target.value = temp_value
  }


  renderCell = (row) =>{
    var {staffs} = this.state;
    var name = staffs[row[0]];
    var {list_new} = this.state;
    var {focusRow} = this.state;

    var staffid = row[0];

      // console.log(list_new);

    return (

    <tr key = {row[0]}>

      <td>{row[0]}</td>
      <td>{name}</td>

      {
        (focusRow !== row[0]) ?
        <td key = {row[0]}>
        <textarea rows="1" wrap="hard" id = {row[0]} value= {numberWithCommas(list_new[row[0]].val)} onFocus={this.setFocusRow} onChange={e=> {
          const data = {...this.state.list_new};
          data[row[0]].val = e.target.value;
          this.setState({data});
          // console.log(this.state.list_new)
         }}> </textarea>
         <span className="error">{this.state.errors[staffid]}</span>
         </td>
         :
         <td key = {row[0]}>
         <textarea autoFocus rows="1" wrap="hard" id = {row[0]} value= {list_new[row[0]].val} onFocus={this.moveCursorToEnd} onChange={e=> {
           const data = {...this.state.list_new};
           data[row[0]].val = e.target.value;
           this.setState({data});
           // console.log(this.state.list_new)
          }}> </textarea>
          <span className="error">{this.state.errors[staffid]}</span>
          </td>
      }



       <td><textarea rows="1" wrap="hard" value= {list_new[row[0]].rmk} onChange={e=> {
         const data = {...this.state.list_new};
         data[row[0]].rmk = e.target.value;
         this.setState({data});
         // console.log(this.state.list_new)
        }}> </textarea></td>

    </tr>

    )


  }


  renderRow = (row) => {

    // console.log(row);
    return Object.entries(row)
    .map(this.renderCell);

  }


  renderList = (list) => {
    return list.map(this.renderRow)
  }


  renderField = (list) => {

    return (
      <tbody>

          {this.renderList(list)}

      </tbody>
    )

  }

  renderTable = _ => {
    var {list} = this.state;
    let checked = this.state.checked;
    // console.log(list);

    if (checked === 'true'){

      return (
        <ProcessReserveTable 
        // handleClick={this.handleClick}
        handleSubmit={this.handleSubmit}
        renderMethod={this.renderField}
        data={list.data}
        columns={['Staff Id', 'Staff Name', 'Amount', 'Remark']}
        focusRow={this.state.focusRow}
        list_new={this.state.list_new}
        handleData={this.handleData}
        inputValid={this.inputValid}
        handleFocusRow={this.handleFocusRow}/>
        // <div className = "container" onClick = {this.handleClick}>

        //   <div className="save-button">
        //      <button type="button" className="btn btn-primary submit process-reserve" onClick={this.handleSubmit}>SAVE</button>
        //   </div>

        //   <div className = "table-container reserve-table react-bootstrap-table">

        //     <form>
        //         <table className="table  ">
        //           <thead>
        //             <tr>
        //               <th ref={node => this.node = node}>Staff Id</th>
        //               <th>Staff Name</th>
        //               <th>Amount</th>
        //               <th>Remark</th>
        //             </tr>
        //           </thead>
        //           {this.renderField(list.data)}
        //         </table>
        //     </form>

        //   </div>

        //   <div className="save-button">
        //      <button type="button" className="btn btn-primary submit process-reserve" onClick={this.handleSubmit}>SAVE</button>
        //   </div>

        // </div>
      )


    }
    else if (checked === 'process'){
      return (<h2>Loading...</h2>)
    }
    else if (checked === 'error'){
      return (<h2>Error Returned</h2>)
    }


  }


  handleSubmit = _ => {
    logging.logToGoogle(loggingHeader + ' clicked save in process reserve insurance');
    const {list_new} = this.state;
    var {month} = this.state;
    const {year} = this.state;

    console.log(list_new)
    if (!this.inputValid()){
      // NotificationManager.error('Unable to save! Please check your input! ');
      this.showError('Unable to save! Please check your input! ');
    }


    else{

      if (month.length === 1){
        month = '0' + month;
      }


      fetch_retry(backend + `api/reserve/updateOneField/` + field + `/` + month + `/` + year,{
        method: 'PUT',
        credentials: "include",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
           data: list_new
        })
      }, 3)
      .then(response => {
        // console.log(response);
        if (response.status === 200){
          // NotificationManager.success('Updated succesfully!');
          this.showSuccess('Updated succesfully!');
        }
        // return response.json()
      });

    }

  }


  render() {

    const {months} = this.state;
    const {years} = this.state;

    let table = this.renderTable();

    return(
      <div>
        <Toast ref={(el) => this.toast = el} />
        <h3 className = 'title'>All Staff's Insurance</h3>

        <div>
          <form className="form-horizontal">
            <fieldset>
              <div className = "buttons col-sm-4">
                <div className="btn-group">
                  {/* <Select className='react-select-container' styles={customStyles} options={months} isSearchable="false" placeholder="month" onChange={this.handleMonthChange}/> */}
                  <DropdownComponent
                  options={months}
                  placeholder="month"
                  handleChange={this.handleMonthChange}
                  value={this.state.month} />
                  <span className="error">{this.state.errors["month"]}</span>
                </div>
              </div>

              <div className = "buttons col-sm-4">
                <div className="btn-group">
                  {/* <Select className='react-select-container' styles={customStyles} options={years} isSearchable="false" placeholder="year" onChange={this.handleYearChange}/> */}
                  <DropdownComponent
                  options={years}
                  placeholder="year"
                  handleChange={this.handleYearChange}
                  value={this.state.year} />
                  <span className="error">{this.state.errors["year"]}</span>
                </div>
              </div>

              <div className="col-sm-4 check-button">
                 {/* <button type="button" className="btn btn-primary submit" onClick={this.checkReserve}>CHECK</button> */}
                 <CheckButton 
                  checkReserve={this.checkReserve}/>
              </div>

            </fieldset>
          </form>
        </div>


        <div>
          <div>
            {table}
          </div>
        </div>

      </div>
    )

  }

}


export default Insurance;
