import React, {Component} from 'react';
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Link
} from "react-router-dom";
import CurrentReserve from "./current_reserve";
import ReserveHistory from "./reserve_history";
import TransferApply from "./transfer_apply";
import TransferPending from "./transfer_pending";
import TransferReceive from "./transfer_received";
import TransferGive from "./transfer_give";
import TransferHistory from "./transfer_history";
import YearlyReport from "./yearly_report";

import '../css/index_d.css';



function isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};

class Staff extends Component {

  constructor(props){
    super(props);
    this.state = {

      name:'',
      staff:'',
      employeeid:'',
      switch_tab:'',
      validUser:'',
      default_content:''

    }
  }

  componentDidMount() {
    this.renderNavBar();
    this.default();
  }

  refresh = _ =>{
     window.location.reload();
  }

  handlelogout = _ => {
    sessionStorage.setItem('int', 'logout');
  }

  deleteSession = _ =>{
    sessionStorage.removeItem('staff');
    sessionStorage.removeItem('super');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('id_s');
    sessionStorage.removeItem('admin');
    sessionStorage.removeItem('id_e');
    sessionStorage.removeItem('version');
  }

  renderNavBar = _ => {

      var staff_access = sessionStorage.getItem('staff');
      var admin_access = sessionStorage.getItem('admin');
      var super_access = sessionStorage.getItem('super');
      var employee = sessionStorage.getItem('id_e');
      var staff = sessionStorage.getItem('id_s');
      var name = sessionStorage.getItem('name');

      this.setState({name: name});
      this.setState({staff: staff});

      if (staff_access){
        this.setState({validUser: true});
      }

      // <Link to={{ pathname:`/${employee}/admin`, state:'refresh'}} onClick={this.refresh}>

      if (super_access === true || admin_access === true){

          console.log(super_access, admin_access);
          var switch_tab = (
            <li className="">
              <Link to={{ pathname:`/${employee}/admin`}} onClick={ () => this.props.changePortal('admin').bind(this)}>
                SWITCH TO ADMIN<span className="sr-only"></span>
              </Link>
            </li>)
          this.setState({switch_tab: switch_tab});

      }

  }


  default = _ => {
    var {staff} = this.state;

    var url = window.location.href;

    // Redirect to current reserve page
    if (url.endsWith('/staff')){
      this.setState({default_content: (<CurrentReserve id={staff}></CurrentReserve>) })
    }

  }


  render(){

    var {name} = this.state;
    var {staff} = this.state;
    // console.log(staff);
    // console.log({staff});
    let {switch_tab} = this.state;
    let {validUser} = this.state;

    let {default_content} = this.state;

    var mobile = isMobileDevice();
    // console.log(mobile);

    if (validUser){
      return (
        <div className = "Staff">
          <Router>
            <div>

            <nav className="navbar navbar-default">
              <div className="container-fluid">
                <div className="navbar-header">
                  <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  <a className="navbar-brand" href="/"><img id="logo" src="/img/logo.png" alt="Navlogo"/></a>
                </div>

                <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                  <ul className="nav navbar-nav">
                    <li className="" data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink active="active" to="/{staff}/staff/current_reserve" >CURRENT RESERVE <span className="sr-only">(current)</span></NavLink></li>
                    <li className="" data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink active="active" to="/{staff}/staff/reserve_history">RESERVE HISTORY </NavLink></li>
                    <li className="" data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/staff/yearly_report" active="active">YEARLY RESERVES REPORT </NavLink></li>
                    <li className="dropdown">
                      <button className="dropdown-button dropdown-toggle" data-toggle="dropdown" aria-expanded="false" active="active">MANAGE TRANSFER <span className="caret"></span></button>
                      <ul className="dropdown-menu" role="menu">
                        <li data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/staff/transfer_apply">Apply</NavLink></li>
                        <li className="divider"></li>
                        <li className="dropdown-title">Ongoing</li>
                        <li data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/staff/transfer_received"><span className="dropdown-point">Received</span></NavLink></li>
                        <li data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/staff/transfer_give"><span className="dropdown-point">Give</span></NavLink></li>
                        <li className="divider"></li>
                        <li data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/staff/transfer_pending">Pending</NavLink></li>
                        <li className="divider"></li>
                        <li data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/staff/transfer_history">History</NavLink></li>
                      </ul>
                    </li>
                    {switch_tab}
                  </ul>
                  <ul className="nav navbar-nav navbar-right">
                    <li>
                      <a href='/logout' onClick = {() => this.handlelogout()}>
                        LOG OUT<i className="material-icons" style={{fill:'white'}}>exit_to_app</i>
                      </a>
                    </li>
                    <li>
                      { mobile ?
                        <a href="/{staff}/staffmobile/home"> Switch to Mobile Device Version
                        </a> : <span>{null}</span>
                      }
                    </li>
                  </ul>
                </div>
              </div>
            </nav>

            <div className="content container">
              {/*<h1 className="staff_greeting">Hi staff {name} !</h1>*/}
              {/*}<Route exact path="/current_reserve" id={this.props.match.params.id}  component={Current_Reserve}/>*/}
              <Route path="/:staff/staff/current_reserve" children={<CurrentReserve id={staff} /> } />
              <Route path="/:staff/staff/reserve_history" children={<ReserveHistory id={staff} /> } />
              <Route path="/:staff/staff/transfer_apply" children={<TransferApply id={staff} />}/>
              <Route path="/:staff/staff/transfer_pending" children={<TransferPending id={staff} />}/>
              <Route exact path="/:staff/staff/transfer_received" children={<TransferReceive id={staff} />}/>
              <Route exact path="/:staff/staff/transfer_give" children={<TransferGive id={staff} />}/>
              <Route exact path="/:staff/staff/transfer_history" children={<TransferHistory id={staff} />}/>
              <Route exact path="/:staff/staff/yearly_report" children={<YearlyReport id={staff} />}/>
              {/* <Route path="/:staff/staff/current_reserve" render={() => <CurrentReserve id={staff} /> } />
              <Route path="/:staff/staff/reserve_history" render={() => <ReserveHistory id={staff} /> } />
              <Route path="/:staff/staff/transfer_apply" render={() => <TransferApply id={staff} />}/>
              <Route path="/:staff/staff/transfer_pending" render={() => <TransferPending id={staff} />}/>
              <Route exact path="/:staff/staff/transfer_received" render={() => <TransferReceive id={staff} />}/>
              <Route exact path="/:staff/staff/transfer_give" render={() => <TransferGive id={staff} />}/>
              <Route exact path="/:staff/staff/transfer_history" render={() => <TransferHistory id={staff} />}/>
              <Route exact path="/:staff/staff/yearly_report" render={() => <YearlyReport id={staff} />}/> */}
              {/*}<Route exact path="/:staff/staffmobile/home" render={() => <StaffMobile id={staff} />}/>*/}

            </div>

            {default_content}


            </div>
          </Router>
        </div>
      );
    }
    else{
      return (
        <div className = "Staff">
          <Router>
            <div>
            <nav className="navbar navbar-default">
              <div className="container-fluid">
                <div className="navbar-header">
                  <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  <a className="navbar-brand" href="/"><img id="logo" src="/img/logo.png" alt="Navlogo"/></a>
                </div>
              </div>
            </nav>

            <div className="content container">
              <h1 className="staff_greeting">Hi {name} !</h1>
              <h1>Sorry that you do not have access to this page!</h1>
              <h1>If this is a mistake, please contact it@navigators.org.sg</h1>
            </div>

            </div>
          </Router>
        </div>
      );
    }




  }
}


// ========================================
export default Staff;
