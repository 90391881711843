
//sort data that nested under names array
export const sortDic = (data, names) => {
  // console.log('data', data);
  // console.log('names', names);

  var final = [];
  // console.log(data)

  for (var key in data){
    var d = data[key] ;
    // console.log(d)

    // if (d !== {} && d.length){

      var id = Object.keys(d);
      // console.log(id);

      // if (d[id]){
        d[id]['Name'] = names[id];

        final.push(d)
        // console.log('final',final)
      // }

    // }




  }

  // console.log(final)

  data = sort('Name', final)
  // console.log(data)

  return {data: data}
}


export const sort = function (prop, arr) {
    var nameA;
    var nameB;

    arr.sort(function (a, b) {

      for (var ka in a){
          if (a[ka].Name){
            nameA = a[ka].Name.toUpperCase();
          }
          else{
            console.log('no name found',a[ka])
          }
      }
      for (var kb in b){
          nameB = b[kb].Name.toUpperCase();
      }
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;

    })

    return arr;

};
