import React, { useEffect, useRef, useState } from "react";
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { RadioButton, RadioButtonChangeEvent } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { TabView, TabPanel } from 'primereact/tabview';
import moment from 'moment';
import { changeFloat, validInput, roundup, numberWithCommas_display as numberWithCommas, removeCommas } from "./service/excelService";

const UpdateInfo = () => {
  const [overseasDonor, setOverseasDonor] = useState("");
  const [overseasDonorDate, setOverseasDonorDate] = useState(moment().format("YYYY-MM-DD"));
  const [overseasDate, setOverseasDate] = useState(moment().format("YYYY-MM-DD"))
  const [CCDate, setCCDate] = useState(moment().format("YYYY-MM-DD"));
  const [CHQDate, setCHQDate] = useState(moment().format("YYYY-MM-DD"));
  const [APLT_Rate, setAPLT_Rate] = useState(null);
  const [AdminCharges_Rate, setAdminCharges_Rate] = useState(null);
  const [overseasCharge, setOverseasCharge] = useState(0);
  const [CCCharge, setCCCharge] = useState(0);
  const [CCTrans, setCCTrans] = useState(0);
  const [CHQCharge, setCHQCharge] = useState(0);
  const [CHQTrans, setCHQTrans] = useState(0);
  const [APLT, setAPLT] = useState(0);
  const [APLTDate, setAPLTDate] = useState(moment().format("YYYY-MM-DD"));
  const [AdminCharge, setAdminCharge] = useState(0);
  const [AdminChargeDate, setAdminChargeDate] = useState(moment().format("YYYY-MM-DD"));
  const [VendorCharge, setVendorCharge] = useState(0);
  const [VendorChargeDate, setVendorChargeDate] = useState(moment().format("YYYY-MM-DD"));
  const [globalFilter, setGlobalFilter] = useState('');
  const [formDialog, setFormDialog] = useState(false);

//   state = {
//     overseasDonor: "",
//     overseasDonorDate: moment().format("YYYY-MM-DD"),
//     overseasDate: moment().format("YYYY-MM-DD"),
//     CCDate: moment().format("YYYY-MM-DD"),
//     CHQDate: moment().format("YYYY-MM-DD"),
//     APLT_Rate:null,
//     AdminCharges_Rate:null,
//     overseasCharge:0.00,
//     CCCharge:0.00,
//     CCTrans:0,
//     CHQCharge:0.00,
//     CHQTrans:0,
//     APLT: 0,
//     APLTDate: moment().format("YYYY-MM-DD"),
//     AdminCharge: 0,
//     AdminChargeDate: moment().format("YYYY-MM-DD"),
//     VendorCharge: 0,
//     VendorChargeDate: moment().format("YYYY-MM-DD")
//   };

  const onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  const openNew = () => {
    // setSubmitted(false);
    // setProductDialog(true);
    setFormDialog(true);
};

  const leftToolbarTemplate = () => {
    return (
        <React.Fragment>
            <div className="my-2">
                <Button label="New" icon="pi pi-plus" severity="success" className=" mr-2" onClick={openNew} />
            </div>
        </React.Fragment>
    );
};

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    {/* <Toast ref={(el) => this.toast = el} /> */}
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

                    <TabView>
                        <TabPanel header="Overseas Donor" leftIcon="pi pi-user">
                            <DataTable>
                                <Column selectionMode="multiple" headerStyle={{ width: '4rem' }}></Column>
                                <Column field="Donor Name" header="Donor Name" sortable ></Column>
                                <Column field="Start Date" header="Start Date" sortable ></Column>
                            </DataTable>
                        </TabPanel>
                        <TabPanel header="Charges Rate" leftIcon="pi pi-user">
                            <DataTable>
                                <Column selectionMode="multiple" headerStyle={{ width: '4rem' }}></Column>
                                <Column field="Payment Type" header="Payment Type" sortable ></Column>
                                <Column field="Charge Rate" header="Charge Rate" sortable ></Column>
                                <Column field="Transaction Date" header="Transaction Date" sortable ></Column>
                                <Column field="Start Date" header="Start Date" sortable ></Column>
                            </DataTable>
                        </TabPanel>
                    </TabView>
                </div>
            </div>

            {/* <div>
                <form className="form-horizontal">
                    <fieldset>
                        <h2 style={{"textAlign": "left"}}>Modify Overseas Donor</h2>
                            <div className="col-sm-6">
                                <h5>Overseas donor</h5>
                                    <InputTextarea id="overseasDonor" value={this.state.overseasDonor} onChange={this.onChange} placeholder="Add an overseas donor" rows={1} cols={50}></InputTextarea>
                            </div>
                            <div className="col-sm-6">
                                <h5>Start Date</h5>
                                    <Calendar id="overseasDonorDate" value={this.state.overseasDonorDate} onChange={this.onChange} placeholder="Input a start date" dateFormat="dd/mm/yy" style={{"width": "70%"}} showIcon></Calendar>
                                
                            </div>
                    </fieldset>
                    <fieldset>
                        <h2 style={{"textAlign": "left"}}>Modify Charges Rate</h2>
                        <div>
                            <div className="col-sm-4">
                                <h5>Creditcard Charge Rate</h5>
                                    <InputTextarea id="CCCharge" value={removeCommas(this.state.CCCharge)} onChange={e => this.setState({ CCCharge: validInput(e.target.value) })}placeholder="Update Creditcard charges rate" rows={1} cols={45} type="number"></InputTextarea>
                            </div>
                            <div className="col-sm-4">
                                <h5>Creditcard Transaction Rate</h5>
                                    <InputTextarea id="CCTrans" value={removeCommas(this.state.CCTrans)} onChange={e => this.setState({ CCTrans: validInput(e.target.value) })} placeholder="Update Creditcard Transaction rate" rows={1} cols={45} type="number"></InputTextarea>
                            </div>
                            <div className="col-sm-4">
                                <h5>Start Date</h5>
                                    <Calendar id="CCDate" value={this.state.CCDate} onChange={this.onChange} placeholder="Input a start date" dateFormat="dd/mm/yy" style={{"width": "70%", "paddingBottom": "10px"}} showIcon></Calendar>
                            </div>
                        </div>
                        <div>
                            <div className="col-sm-4">
                                <h5>Cheque Charge Rate</h5>
                                    <InputTextarea id="CHQCharge" value={removeCommas(this.state.CHQCharge)} onChange={e => this.setState({ CHQCharge: validInput(e.target.value) })} placeholder="Update Cheque charges rate" rows={1} cols={45} type="number" style={{"paddingBottom": "10px"}}></InputTextarea>
                            </div>
                            <div className="col-sm-4">
                                <h5>Cheque Transaction Rate</h5>
                                    <InputTextarea id="CHQTrans" value={removeCommas(this.state.CHQTrans)} onChange={e => this.setState({ CHQTrans: validInput(e.target.value) })} placeholder="Update Cheque Transaction rate" rows={1} cols={45} type="number" style={{"paddingBottom": "10px"}}></InputTextarea>
                            </div>
                            <div className="col-sm-4">
                                <h5>Start Date</h5>
                                    <Calendar id="CHQDate" value={this.state.CHQDate} onChange={this.onChange} placeholder="Input a start date" dateFormat="dd/mm/yy" style={{"width": "70%", "paddingBottom": "10px"}} showIcon></Calendar>
                            </div>
                        </div>
                        <div>
                            <div className="col-sm-6">
                                <h5>Overseas Charge Rate</h5>
                                    <InputTextarea id="overseasCharge" value={removeCommas(this.state.overseasCharge)} onChange={e => this.setState({ overseasCharge: validInput(e.target.value) })}placeholder="Update overseas charges rate" rows={1} cols={45} type="number"></InputTextarea>
                            </div>
                            <div className="col-sm-6">
                                <h5>Start Date</h5>
                                    <Calendar id="overseasDate" value={this.state.overseasDate} onChange={this.onChange} placeholder="Input a start date" dateFormat="dd/mm/yy" style={{"width": "70%", "paddingBottom": "10px"}} showIcon></Calendar>
                            </div>
                        </div>
                        <div>
                            <div className="col-sm-6">
                                <h5>APLT Rate</h5>
                                    <InputTextarea id="APLTCharge" value={removeCommas(this.state.APLT)} onChange={e => this.setState({ APLT: validInput(e.target.value) })} placeholder="Update APLT rate" rows={1} cols={45} type="number"></InputTextarea>
                            </div>
                            <div className="col-sm-6">
                                <h5>Start Date</h5>
                                    <Calendar id="APLTDate" value={this.state.APLTDate} onChange={this.onChange} placeholder="Input a start date" dateFormat="dd/mm/yy" style={{"width": "70%", "paddingBottom": "10px"}} showIcon></Calendar>
                            </div>
                        </div>
                        <div>
                            <div className="col-sm-6">
                                <h5>Admin Charges Rate</h5>
                                    <InputTextarea id="AdminCharge" value={removeCommas(this.state.AdminCharge)} onChange={e => this.setState({ AdminCharge: validInput(e.target.value) })} placeholder="Update APLT rate" rows={1} cols={45} type="number"></InputTextarea>
                            </div>
                            <div className="col-sm-6">
                                <h5>Start Date</h5>
                                    <Calendar id="APLTDate" value={this.state.AdminChargeDate} onChange={this.onChange} placeholder="Input a start date" dateFormat="dd/mm/yy" style={{"width": "70%", "paddingBottom": "10px"}} showIcon></Calendar>
                            </div>
                        </div>
                        <div>
                            <div className="col-sm-6">
                                <h5>Vendor Charges Rate</h5>
                                    <InputTextarea id="AdminCharge" value={removeCommas(this.state.VendorCharge)} onChange={e => this.setState({ VendorCharge: validInput(e.target.value) })} placeholder="Update APLT rate" rows={1} cols={45} type="number"></InputTextarea>
                            </div>
                            <div className="col-sm-6">
                                <h5>Start Date</h5>
                                    <Calendar id="APLTDate" value={this.state.VendorChargeDate} onChange={this.onChange} placeholder="Input a start date" dateFormat="dd/mm/yy" style={{"width": "70%", "paddingBottom": "10px"}} showIcon></Calendar>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <div className="col-sm-12" style={{"paddingTop": "10px", "paddingBottom": "10px"}}>
                            <button type="button" className="btn btn-primary submit">SUBMIT</button>
                        </div>
                    </fieldset>
                </form>
            </div> */}
        </div>
    )
}

export default UpdateInfo;