import React, { Component } from 'react';
import ReactDOM from 'react-dom';
// import { createRoot } from 'react-dom/client';
// import { withRouter } from 'react-router';
import withRouter from '../src/components/withRouter'
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Cookies from 'js-cookie';
// import { NotificationContainer } from 'react-notifications';
import queryString from 'query-string';

import Staff from "./staff/staff";
import Admin from "./admin/admin";

import StaffMobile from "./mobile/staff/home";
import AdminMobile from "./mobile/admin/home";

import Warning from "./warning";
import Login from "./login";
import Logout from "./logout";
// import ErrorBoundary from './components/error_boundary';
// import 'react-notifications/lib/notifications.css';
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primeicons/primeicons.css';
// import 'primereact/resources/primereact.css';
import '../src/css/primereact.css';
import 'primeflex/primeflex.css';


const getUserFromData = data => {
  if (data) {
    try {
      return JSON.parse(atob(data));
    } catch (error) {
      // ignore
    }
  }

  return null;
};


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portal: '',
      jwt: Cookies.get('jwt')
    }

    this.changePortal = this.changePortal.bind(this)
    this.setSession = this.setSession.bind(this)
    this.setPortal = this.setPortal.bind(this)
    this.renderRedirect = this.renderRedirect.bind(this)
  }

  componentWillMount() {

    this.checkRedirect();

  }

  checkRedirect = _ => {

    const values = queryString.parse(this.props.location.search);

    console.log('values', values)

    if (Object.keys(values).length > 0) { // Just login

      // console.log('first time login')

      var data = values.data;
      var user = getUserFromData(data).user;
      console.log(user)

      this.setSession(user);

      this.setPortal()
        .then(portal => {
          // console.log('redirect to', portal)
          this.renderRedirect(portal);
        })
        .catch((err) => {
          console.log('setPortal error found:', err);
          this.props.history.push('/warning');
          // this.props.navigate('/warning')

        })


    }

    else {
      
      let intention = sessionStorage.getItem('int');
      let id_e = sessionStorage.getItem('id_e');
      console.log(intention, id_e)

      if (intention === 'logout') {
        // console.log('logout')
        this.props.history.push('/logout');
        // this.props.navigate('/logout');
      }

      // let auth = this.state.auth;

      else if (id_e !== undefined && id_e !== null) {
        // console.log('refresh')

        this.setPortal()
          .then(portal => {
            // console.log('redirect to', portal)
            this.renderRedirect(portal);
          });

      }

      else {
        console.log('login');
        this.props.history.push('/login')
        // this.props.navigate('/login')
      }

    }

  }


  setSession = (user) => {

    // console.log(user.reserve_admin)
    sessionStorage.setItem('id_e', user.EmployeeId);
    sessionStorage.setItem('id_s', user.StaffId);
    sessionStorage.setItem('name', user.Name);
    sessionStorage.setItem('email', user.email);
    sessionStorage.setItem('int', 'refresh');

    if (user.reserve_staff === true) {
      sessionStorage.setItem('staff', true);
    }

    if (user.reserve_admin === true) {
      sessionStorage.setItem('admin', true);
    }

    if (user.super_admin === true) {
      sessionStorage.setItem('admin', true);
      sessionStorage.setItem('super', true);
    }


  }

  // Return a promise
  setPortal = _ => {
    var portal;

    var admin = sessionStorage.getItem('admin');
    var super_ = sessionStorage.getItem('super');
    var staff = sessionStorage.getItem('staff');

    // console.log()

    return new Promise(function (resolve, reject) {

      if (staff === 'true') {

        portal = 'staff';
        resolve(portal)

      }

      else if (admin === 'true') {

        portal = 'admin';
        resolve(portal)

      }

      else if (super_ === 'true') {

        portal = 'admin';
        resolve(portal)

      }
      else {
        // console.log(admin, staff, super_)
        reject('cannot find role')
      }

    })
  }

  renderRedirect() {

    var id = sessionStorage.getItem('id_e');
    var path;
    var url = window.location.href;
    console.log(url)
    // var user = this.state.user;

    var admin = sessionStorage.getItem('admin');
    var super_ = sessionStorage.getItem('super');
    var staff = sessionStorage.getItem('staff');

    // console.log(typeof(admin))
    // console.log(typeof(super_))
    // console.log(typeof(staff))
    //
    // console.log('admin',admin)
    // console.log('super_',super_)
    // console.log('staff',staff)

    // If this is a mobile device, redirect to mobile url
    // 1. from a mobile version and going to a mobile version (trying to refresh)
    // 2. from a desktop version and tyring to switch to mobile version
    // 3. frist time login and a mobile device
    if ((sessionStorage.getItem('version') === 'mobile' && (url.endsWith('/staffmobile/home') || url.endsWith('/adminmobile/home'))) ||
      (sessionStorage.getItem('version') === 'desktop' && (url.endsWith('/staffmobile/home') || url.endsWith('/adminmobile/home'))) ||
      (((typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1))
        && (sessionStorage.getItem('version') !== 'desktop')
        && (sessionStorage.getItem('version') !== 'mobile')
      )) {

      if (staff === 'true') {
        // user.staff_access = true;
        path = '/' + id + '/staffmobile/home';
        sessionStorage.setItem('version', 'mobile')
      }
      else if (admin === 'true' || super_ === 'true') {
        // user.admin_access = true;
        path = '/' + id + '/adminmobile/home';
        sessionStorage.setItem('version', 'mobile')
      }
      else {
        path = '/warning';
      }

    }

    else {
      console.log('not mobile')

      if (staff === 'true') {

        path = '/' + id + '/staff/current_reserve';
        sessionStorage.setItem('version', 'desktop')

      }
      else if (admin === 'true' || super_ === 'true') {

        path = '/' + id + '/admin/reserve/per_employee';
        sessionStorage.setItem('version', 'desktop')
      }
      else {
        path = '/warning';
      }

    }

    // console.log(path)
    // console.log(this.props)
    this.props.history.push(path);
  }


  changePortal = (value) => {
    console.log('index.js wants to change portal to ', value);
    this.setState({ portal: value });
  }


  render() {

    return (
      <div className="App">
        <div className="content">

          <Switch>
            {/* <Route exact path="/login" component={Login} /> */}
            <Route exact path="/login"><Login /></Route>
            <Route exact path="/logout"><Logout /></Route>

            {/* <Route path='/:employee_id/staff/' render={(props) => (
              <Staff {...props} changePortal={this.changePortal} />
            )} /> */}

            <Route path='/:employee_id/staff/' children={<Staff changePortal={this.changePortal} />} />

            {/* <Route path='/:employee_id/admin/' render={(props) => (
              <Admin {...props} changePortal={this.changePortal} />
            )} /> */}

            <Route path='/:employee_id/admin/' children={<Admin changePortal={this.changePortal} />} />

            {/* <Route path='/:employee_id/admin/*' component={Admin changePortal={this.changePortal} />} /> */}

            {/* <Route path='/:employee_id/staffmobile/home' render={(props) => (
              <StaffMobile {...props} changePortal={this.changePortal} />
            )} /> */}

            <Route path='/:employee_id/staffmobile/home/' children={<StaffMobile changePortal={this.changePortal} />} />

            {/* <Route path='/:employee_id/adminmobile/home' render={(props) => (
              <AdminMobile {...props} changePortal={this.changePortal} />
            )} /> */}

            <Route path='/:employee_id/adminmobile/home/' children={<AdminMobile changePortal={this.changePortal} />} />

            <Route path="/warning"><Warning /></Route>
          </Switch>

          {/* <NotificationContainer /> */}

        </div>



      </div>
    )


  }
}


// const App_r = withRouter(connect(mapStateToProps)(App));
const Appr = withRouter(App);

ReactDOM.render(
  <BrowserRouter>
    <Appr />
  </BrowserRouter>

  , document.getElementById('root'));
// const container = document.getElementById('root');
// const root = createRoot(container);
// root.render(
//   <BrowserRouter>
//     <Appr />
//   </BrowserRouter>
// );
