import React, {Component} from "react";

class CheckButton extends Component {
    render() {
        const { checkReserve } = this.props;

        return (
            <button type="button" className="btn btn-check btn-primary submit" onClick={checkReserve}>CHECK</button>
        )
    }
}

export default CheckButton;