import React from 'react';

function numberWithCommas(x) {
  if((x!== null) && !isNaN(x)){
    if (x === '0' || x === 0) {return '0.00'}
    var y = roundup(x).toFixed(2);
    return '$' + y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return null;
}

function changeFloat(x) {
  if((x!== null) && !isNaN(x)){
    if (x === '0' || x === 0) {return 0}
    return parseFloat(x);
  }
  return 0;
}


function roundup(x) {
  return (Math.round(x*100) / 100);
}



class Popup extends React.Component {

  constructor(props) {
    super(props);

    // console.log(this.props)

    this.state = {
      reserve: props.data
    };
  }


  componentWillMount(){
    this.setState({reserve: this.props.data});
  }


  componentDidMount(){
    this.setState({reserve: this.props.data});
  }



  // Clean out the null, 0, undefined
  processReserveDate(reserve){
    var reserve_new = reserve;

    (Object.keys(reserve)).map((k)=>{
      if (k.match(/_rmk/gi)){
        // // console.log('find _rmk');
        // if (reserve[k] === "null" || reserve[k] === '0' || reserve[k] === 0 || reserve[k] === undefined){
        //   reserve_new[k] = "";
        // }
      }
      else{
        if (reserve[k] === "null" || reserve[k] === '0' || reserve[k] === "" || reserve[k] === undefined){
          reserve_new[k] = 0;
        }

      }
      return 0;

    });
    return reserve_new;
  }



  renderReserve(reserve_o){

    var reserve = this.processReserveDate(reserve_o);
    var role = reserve_o.role;


    var outcomeTotal = roundup(changeFloat(reserve.Insurance) + changeFloat(reserve.AdminCharges) + changeFloat(reserve.APLT) + changeFloat(reserve.CDAC) + changeFloat(reserve.SINDA) + changeFloat(reserve.EmpeeCPF) + changeFloat(reserve.EmperCPF) + changeFloat(reserve.NetSalary) + changeFloat(reserve.MinistryExp) + changeFloat(reserve.TransferOUT) + changeFloat(reserve.CreditCard) + changeFloat(reserve.Cheque) + changeFloat(reserve.OutcomeOthers));

    var sOrd_deduction = roundup(changeFloat(outcomeTotal) - changeFloat(reserve.Budget)) ;

    var incomeTotal = roundup(changeFloat(reserve.BalanceBF) + changeFloat(reserve.CashProject) + changeFloat(reserve.TransferIN) + changeFloat(reserve.GovernCredit) + changeFloat(reserve.IncomeOthers)) + changeFloat(reserve.Donations) ;

    var sOrd_addition = roundup(changeFloat(reserve.Donations) - changeFloat(reserve.Budget));

    var couple_donation = roundup(changeFloat(reserve.WifeDonation) + changeFloat(reserve.Donations));
    var couple_reserve = roundup(changeFloat(reserve.WifeBalance) + changeFloat(reserve.ReservedFundCF));

    // console.log(reserve.WifeBalance)
    // console.log(reserve.ReservedFundCF)
    // console.log(couple_reserve)

    var total_Ratio;

    if (reserve.Budget === 0){
      total_Ratio = '0%';
    }
    else{
      total_Ratio = (Math.round((reserve.Donations / reserve.Budget) * 10000) / 100) + '%';
    }

    return (
      <tbody>
        <tr>
          <td><label>Addition</label></td>
          <td></td>
        </tr>
        <tr>
          <td>Balance B/F</td>
          <td key='BalanceBF' style={{textAlign:"right"}}>{numberWithCommas(reserve.BalanceBF)}</td>
        </tr>
        <tr>
          <td>Donations</td>
          <td key='Donations' style={{textAlign:"right"}}>{numberWithCommas(reserve.Donations)}</td>
        </tr>
        {(role === 3)?
          <tr>
          <td>Couple's Total Donations</td>
          <td key='Donations' style={{textAlign:"right"}}>{numberWithCommas(couple_donation)}</td>
          </tr>
          :
          <tr></tr>
        }
        <tr>
          <td>Cash Project</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.CashProject)}</td>
        </tr>
        <tr>
          <td>Transfer(IN)</td>
          <td style={{textAlign:"right"}} className='transfer'>
            {numberWithCommas(reserve.TransferIN)}
            <ul style={{textAlign:"left"}} className="dropdown" dangerouslySetInnerHTML={{__html: (reserve.TransferIN_details || '')}}></ul>
          </td>
        </tr>
        <tr>
          <td>Government Credit</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.GovernCredit)}</td>
        </tr>
        <tr>
          <td>Others</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.IncomeOthers)}</td>
        </tr>
        <tr>
          <td>Total</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(incomeTotal)}</td>
        </tr>
        <tr>
          <td>Budget</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.Budget)}</td>
        </tr>
        <tr>
          <td>Surplus/Deficit</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(sOrd_addition)}</td>
        </tr>


        <tr>
          <td><label>Deduction</label></td>
          <td></td>
        </tr>
        <tr>
          <td>Insurance</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.Insurance)}</td>
        </tr>
        <tr>
          <td>Singapore Admin Charges</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.AdminCharges)}</td>
        </tr>
        <tr>
          <td>APLT</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.APLT)}</td>
        </tr>
        {/*{this.CDAC_SINDA(reserve)}
        <tr>
          <td>CDAC Funds</td>
          <td>{CDAC}</td>
          <td>{reserve.CDAC_rmk}</td>
        </tr>*/}
        <tr>
          <td>Employer CPF</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.EmperCPF)}</td>
        </tr>
        <tr>
          <td>Employee CPF</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.EmpeeCPF)}</td>
        </tr>
        <tr>
          <td>Net Salary</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.NetSalary)}</td>
        </tr>
        <tr>
          <td>Ministry Expenses</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.MinistryExp)}</td>
        </tr>
        <tr>
          <td>Transfer (Out)</td>
          <td style={{textAlign:"right"}} className='transfer'>
            {numberWithCommas(reserve.TransferOUT)}
            <ul style={{textAlign:"left"}} className="dropdown" dangerouslySetInnerHTML={{__html: ( reserve.TransferOUT_details || '')}}></ul>
          </td>

        </tr>
        <tr>
          <td>Credit Card Charges</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.CreditCard)}</td>
        </tr>
        <tr>
          <td>Cheque Charges</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.Cheque)}</td>
        </tr>
        <tr>
          <td>Others</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.OutcomeOthers)}</td>
        </tr>
        <tr>
          <td>Total</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(outcomeTotal)}</td>
        </tr>

        <tr>
          <td>Budget</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.Budget)}</td>
        </tr>


        <tr>
          <td>Surplus/Deficit</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(sOrd_deduction)}</td>
        </tr>

        <tr className='reserveCF'>
          <td>Reserve Fund C/F</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.ReservedFundCF)}</td>
        </tr>

        {(role === 3)?
          <tr>
            <td>Wife's Reserve</td>
            <td style={{textAlign:"right"}}>{numberWithCommas(reserve.WifeBalance)}</td>
          </tr>
          :
          <tr></tr>
        }

        {(role === 3)?
          <tr className='reserveCF'>
            <td>Couple's Total Reserve</td>
            <td style={{textAlign:"right"}}>{numberWithCommas(couple_reserve)}</td>
          </tr>
          :
          <tr></tr>
        }

        <tr>
          <td>Total Collection vs Budget</td>
          <td style={{textAlign:"right"}}>{total_Ratio}</td>
        </tr>

      </tbody>)

  }

  render() {

    var {reserve} = this.state;
    var reserve_details = this.renderReserve(reserve);


    return (
      <div className='popup'>

        <span className="close" onClick={this.props.closePopup}>×</span>

        <div className='popup_inner'>

          <div className = "table-container2">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th className='category'>Category</th>
                  <th className='amount'>Amount</th>
                </tr>
              </thead>
              {reserve_details}
            </table>
          </div>

        </div>


      </div>
    );
  }
}


export default Popup;
