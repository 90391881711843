import React, {Component} from "react";
import TransferAction from './transferAction.js';

class TransferDetails extends Component {


  constructor(props) {
    super(props);

    // console.log(this.props)

    this.state = {
      data: props.data
    };
  }

  componentWillMount(){
    this.setState({data: this.props.data});
    // console.log('willMount', this.props.data);
  }


  componentDidMount(){
    // console.log('DidMount', this.props.data);
    this.setState({data: this.props.data});
  }

  render() {
    const data = this.state;

    // const transfer = data['transfer'];
    const transfer = data.data.transfer;
    console.log(transfer)


    const DATE_OPTIONS = {year: 'numeric', month: 'short', day: 'numeric' };

    // var start = new Date(transfer.EndDate);
    var start = (new Date(transfer.StartDate)).toLocaleDateString('en-GB', DATE_OPTIONS)
    var end = (new Date(transfer.EndDate)).toLocaleDateString('en-GB', DATE_OPTIONS)
    // start = start.toString();

    return (
      <div className='transfer-popup'>

        <span className="close" onClick={this.props.closePopup}>×</span>

        <div className='popup_inner'>

          <div className = "table-container">
            <div className = "title">Application Details</div>
            <table className="table table-striped table-hover">

              <tbody>
                <tr>
                  <td>From</td><td>{transfer.FromStaffId}</td>
                </tr>
                <tr>
                  <td>To</td><td>{transfer.ToStaffId}</td>
                </tr>
                <tr>
                  <td>Amount</td><td>S${transfer.Amount}</td>
                </tr>
                <tr>
                  <td>Start From</td><td>{start}</td>
                </tr>
                <tr>
                  <td>Times</td><td>{transfer.Times}</td>
                </tr>
                <tr>
                  <td>End At</td><td>{end}</td>
                </tr>
              </tbody>
            </table>

            <div className = "actionbuttons">
            <TransferAction data = {transfer.Id}  filter = {this.props.filter} source = {this.props.closePopup}/>
            </div>
          </div>

        </div>


      </div>)
  }
}


export default TransferDetails;
