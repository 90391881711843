import React, {Component} from "react";
import ScrollContainer from 'react-indiana-drag-scroll'

class PayrollTable extends Component {
  constructor(props){
    super(props);
    this.state = {
      dataSet: this.props.dataSet,
      admin:[],
      office:[]
    }
  }

  renderColumns = (col, index) => {
    // console.log('col is ', col);
    return (<th key={index} style={{ textAlign:"center"} } >{col.value}</th>);
  }

  renderRow = (row, index) => {
    // console.log('row', row);
    return <tr key={index}>{row.map(this.renderCell)}</tr>
  }

  renderCell = (cell, index) => {
    // console.log('cell', cell);
    if(String(cell.value).startsWith('-')){
      return <td key={index} align="right" className="red">{cell.value}</td>
    }
    else{
      return <td key={index} align="right">{cell.value}</td>
    }
  }

  renderTitle(title){
    return <tr>{title.map(this.renderColumns)}</tr>

  }

  renderTable(data){
    return data.map(this.renderRow);
  }

  render(){
    const dataSet = this.props.dataSet;
    // const type = this.props.type;
    // console.log(dataSet);
    // console.log(type);


    if (dataSet.length !== 0){
      var title = dataSet[0].data;
      var admin_title = dataSet[2].data;
      var admin_col = dataSet[3].data[0];
      var admin = dataSet[4].data;
      var ministry_title = dataSet[6].data;
      var ministry_col = dataSet[7].data[0];
      var ministry = dataSet[8].data;

      // console.log('title', title);
      // console.log('admin_title', admin_title);
      // console.log('admin_col', admin_col);
      // console.log('admin', admin);
      // console.log('ministry_title', ministry_title);
      // console.log('ministry_col', ministry_col);
      // console.log('ministry', ministry);

    }

    return (<div>
        <div className = "container-fluid report">
          <div className = "table-container">
          <ScrollContainer className="scroll-container">

            <form>
              <table className="table table-striped table-hover">
                <thead>
                  <tr className="title" >
                    <th colSpan='18'> {title[0][0].value}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                  <tr>
                    <th colSpan = '5'> {admin_title[0][0].value}</th>
                    <th colSpan = '4'  style={{ textAlign:"center", 'borderBottom':'1px solid black'} } > {admin_title[0][5].value}</th>
                    <th colSpan = '2'> </th>
                    <th colSpan = '2' style={{ textAlign:"center", 'borderBottom':'1px solid black'} }> {admin_title[0][11].value}</th>
                  </tr>
                  {this.renderTitle(admin_col)}
                  {this.renderTable(admin)}
                  <tr></tr>
                  <tr>
                    <th colSpan = '5'> {ministry_title[0][0].value}</th>
                    <th colSpan = '4'  style={{ textAlign:"center", 'borderBottom':'1px solid black'} } > {admin_title[0][5].value}</th>
                    <th colSpan = '2'> </th>
                    <th colSpan = '2' style={{ textAlign:"center", 'borderBottom':'1px solid black'} }> {admin_title[0][11].value}</th>
                  </tr>
                  {this.renderTitle(ministry_col)}
                  {this.renderTable(ministry)}
                </tbody>
              </table>
            </form>
            </ScrollContainer>
            
          </div>
        </div>
      </div>);
  }


}

export default PayrollTable;
