import React, {Component} from 'react';
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Link,
  Switch
} from "react-router-dom";
// import withRouter from '../components/withRouter';
import Import_Budgets from "./import_budgets";
import Import_Payroll from "./import_payroll";
import Process_Reserve from "./process_reserve_per_employee";
import Govern_Credit from "./process_reserve_govern_credit";
import Insurance from "./process_reserve_insurance";
import Ministry_Exp from "./process_reserve_ministry_exp";
import Manage_Staff from "./manage_staff";
import Transfer_All_Pending from "./transfer_all_pending";
import Transfers_All from "./transfers_all";
import Transfer_Approved from "./transfer_approved";
import Transfer_Rejected from "./transfer_rejected";
import Report_Employee from "./per_employee_yearly_report/report_employee";
import Report_Yearly from "./yearly_report/report_yearly";
import Report_Monthly from "./report_monthly";
import Params_List from "./params_list";
import UpdateInfo from './updateInfo';
import '../css/index_d.css';

function isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};


class Admin extends Component {

  constructor(props){
    super(props);
    this.state = {
      super_tab:'',
      switch_tab:'',
      validUser: false,
      name:''
    }

  }


  componentDidMount() {
    this.renderNavBar();
  }

  handlelogout = _ => {
    sessionStorage.setItem('int', 'logout');
  }


  renderNavBar = _ => {

    var staff_access = sessionStorage.getItem('staff');
    var admin_access = sessionStorage.getItem('admin');
    var super_access = sessionStorage.getItem('super');
    var employee = sessionStorage.getItem('id_e');
    var staff = sessionStorage.getItem('id_s');
    var name = sessionStorage.getItem('name');

    this.setState({name: name});
    this.setState({staff: staff});


    if (admin_access){
      this.setState({validUser: true});
    }
    if (super_access){
      var super_tab = (
        <li className="dropdown">
            <NavLink to="/{staff}/admin/manage_staff" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">MANAGE<span className="caret"></span></NavLink>
            <ul className="dropdown-menu" role="menu">
              <li data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/admin/manage_staff">Manage Staff</NavLink></li>
              <li className="divider"></li>
              <li data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/admin/params">Manage Charges Rates</NavLink></li>
              <li className="divider"></li>
              <li data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/admin/updateInfo">Modify Overseas Donor & Charges Rate</NavLink></li>
            </ul>
        </li>)

      this.setState({super_tab: super_tab});
    }

    if(staff_access){
              // <Link to={{ pathname:`/${employee}/staff` }} onClick={ () => this.props.changePortal('staff')}>
      var link = '/' + employee + '/staff';
      var switch_tab = (
        <li className="">

          <Link to={link} onClick={ () => this.props.changePortal('staff').bind(this)}>
            SWITCH TO STAFF<span className="sr-only"></span>
          </Link>
        </li>)
      this.setState({switch_tab: switch_tab});
    }

  }

  render(){

    let {super_tab} = this.state;
    // let {switch_tab} = this.state;
    let {validUser} = this.state;
    let {name} = this.state;
    // var {staff} = this.state;
    var mobile = isMobileDevice();

    // <li className="dropdown"><NavLink to="/{staff}/admin/process_reserve">PROCESS RESERVE <span className="sr-only"></span></NavLink></li>


    if(validUser){
      return (
        <div className = "Admin">
          <Router>
            <div>
              <nav className="navbar navbar-default">
                <div className="container-fluid">
                  <div className="navbar-header">
                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                    <a className="navbar-brand" href="/"><img id="logo" src="/img/logo.png" alt="Navlogo"/></a>
                  </div>

                  <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                    <ul className="nav navbar-nav">
                      <li className="dropdown">
                        <button className="dropdown-button dropdown-toggle" data-toggle="dropdown" aria-expanded="false">PROCESS RESERVE <span className="caret"></span></button>
                        <ul className="dropdown-menu" role="menu">
                          <li data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/admin/reserve/per_employee">Per Employee</NavLink></li>
                          <li className="divider"></li>
                          <li data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/admin/reserve/governCredit">Government Credit</NavLink></li>
                          <li className="divider"></li>
                          <li data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/admin/reserve/insurance">Insurance</NavLink></li>
                          <li className="divider"></li>
                          <li data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/admin/reserve/ministry">Ministry Expenses</NavLink></li>
                        </ul>
                      </li>

                      <li className="dropdown">
                        <button className="dropdown-button dropdown-toggle" data-toggle="dropdown" aria-expanded="false">MANAGE TRANSFER <span className="caret"></span></button>
                        <ul className="dropdown-menu" role="menu">
                          <li data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/admin/transfer/all_pending">Pending</NavLink></li>
                          <li className="divider"></li>
                          <li data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/admin/transfer/approved">Approved</NavLink></li>
                          <li className="divider"></li>
                          <li data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/admin/transfer/rejected">Rejected</NavLink></li>
                          <li className="divider"></li>
                          <li data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/admin/transfer/all">All</NavLink></li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <button className="dropdown-button dropdown-toggle" data-toggle="dropdown"  aria-expanded="false">GENERATE REPORTS <span className="caret"></span></button>
                        <ul className="dropdown-menu" role="menu">
                          <li data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/admin/report/employee_yearly">Yearly Reserves Per Employee Report</NavLink></li>
                          <li className="divider"></li>
                          <li data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/admin/report/total_yearly">Total Yearly Reserves Report</NavLink></li>
                          <li className="divider"></li>
                          <li data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/admin/report/monthly">Monthly Payroll Detail Report</NavLink></li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <NavLink to="/{staff}/admin/import_budgets" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">IMPORT FILES <span className="caret"></span></NavLink>
                        <ul className="dropdown-menu" role="menu">
                          <li data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/admin/import_payroll">Import Monthly Payroll</NavLink></li>
                          <li className="divider"></li>
                          <li data-toggle="collapse" data-target=".navbar-collapse.show"><NavLink to="/{staff}/admin/import_budgets">Import Staff Budget Form</NavLink></li>
                        </ul>
                      </li>
                      {super_tab}
                    </ul>
                    <ul className="nav navbar-nav navbar-right">

                      <li>
                        {/*}<a href="/.auth/logout" onClick={this.deleteSession}>*/}
                        <a href='/logout' onClick = {() => this.handlelogout()}>
                          LOG OUT<i className="material-icons" style={{fill:'white'}}>exit_to_app</i>
                        </a>
                      </li>

                      <li>
                        { mobile ?
                          <a href="/{staff}/adminmobile/home"> Switch to Mobile Device Version
                          </a> : <span>{null}</span>
                        }
                      </li>

                    </ul>
                  </div>
                </div>
              </nav>

              <div className="content container">
                {/*}<h1 className="staff_greeting">Hi admin {name} !</h1>*/}
                <Switch>
                  <Route path="/:staff/admin/import_budgets"><Import_Budgets /></Route>
                  <Route path="/:staff/admin/import_payroll"><Import_Payroll /></Route>
                  <Route path="/:staff/admin/reserve/per_employee"><Process_Reserve /></Route>
                  <Route path="/:staff/admin/reserve/governCredit"><Govern_Credit /></Route>
                  <Route path="/:staff/admin/reserve/insurance"><Insurance /></Route>
                  <Route path="/:staff/admin/reserve/ministry"><Ministry_Exp /></Route>
                  <Route path="/:staff/admin/manage_staff"><Manage_Staff /></Route>
                  <Route path="/:staff/admin/transfer/all_pending"><Transfer_All_Pending /></Route>
                  <Route path="/:staff/admin/transfer/all"><Transfers_All /></Route>
                  <Route path="/:staff/admin/transfer/approved"><Transfer_Approved /></Route>
                  <Route path="/:staff/admin/transfer/rejected"><Transfer_Rejected /></Route>
                  <Route path="/:staff/admin/report/employee_yearly"><Report_Employee /></Route>
                  <Route path="/:staff/admin/report/total_yearly"><Report_Yearly /></Route>
                  <Route path="/:staff/admin/report/monthly"><Report_Monthly /></Route>
                  <Route path="/:staff/admin/params"><Params_List /></Route>
                  <Route path="/:staff/admin/updateInfo"><UpdateInfo /></Route>
                  {/* <Route path="/:staff/admin/import_budgets" component={Import_Budgets}/>
                  <Route path="/:staff/admin/import_payroll" component={Import_Payroll}/>
                  <Route path="/:staff/admin/reserve/per_employee" component={Process_Reserve}/>
                  <Route path="/:staff/admin/reserve/governCredit" component={Govern_Credit}/>
                  <Route path="/:staff/admin/reserve/insurance" component={Insurance}/>
                  <Route path="/:staff/admin/reserve/ministry" component={Ministry_Exp}/>
                  <Route path="/:staff/admin/manage_staff" component={Manage_Staff}/>
                  <Route path="/:staff/admin/transfer/all_pending" component={Transfer_All_Pending}/>
                  <Route path="/:staff/admin/transfer/all" component={Transfers_All}/>
                  <Route path="/:staff/admin/transfer/approved" component={Transfer_Approved}/>
                  <Route path="/:staff/admin/transfer/rejected" component={Transfer_Rejected}/>
                  <Route path="/:staff/admin/report/employee_yearly" component={Report_Employee}/>
                  <Route path="/:staff/admin/report/total_yearly" component={Report_Yearly}/>
                  <Route path="/:staff/admin/report/monthly" component={Report_Monthly}/>
                  <Route path="/:staff/admin/params" component={Params_List}/> */}
                </Switch>
              </div>

            </div>
          </Router>
        </div>
      );
    }

    else{
      return(
        <div className = "Admin">
          <Router>
            <div>
            <nav className="navbar navbar-default">
              <div className="container-fluid">
                <div className="navbar-header">
                  <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  <a className="navbar-brand" href="/"><img id="logo" src="/img/logo.png" alt="Navlogo"/></a>
                </div>
              </div>
            </nav>

            <div className="content container">
              <h1 className="staff_greeting">Hi {name}! </h1>
              <h1>Sorry that you do not have access to this page!</h1>
              <h1>If this is a mistake, please contact it@navigators.org.sg</h1>
            </div>

            </div>
          </Router>
        </div>
      )
    }


  }
}


// ========================================
export default Admin;
