import React, { Component } from "react";
import moment from 'moment';
import {changeFloat, roundup, numberWithCommas_display as numberWithCommas } from "../admin/service/excelService";

var config = require('../config.js');
const backend = config.backend;

class CurrentReserve extends Component {

  _isMounted = false;


  constructor(props){
    super(props);

    this.state = {
      staff:this.props.id,
      month: moment().month(),
      // month: '11',
      year: moment().year(),
      // year: '2019',
      reserve:{},
      reserve_fields:[
        'Id',
        'StaffId',
        'Month',
        'Year',
        'BalanceBF',
        'BalanceBF_rmk',
        'Donations',
        'Donations_rmk',
        'CashProject',
        'CashProject_rmk',
        'TransferIN',
        'TransferIN_rmk',
        'GovernCredit',
        'GovernCredit_rmk',
        'IncomeOthers',
        'IncomeOthers_rmk',
        'Budget',
        'Budget_rmk',
        'Insurance',
        'Insurance_rmk',
        'AdminCharges',
        'AdminCharges_rmk',
        'APLT',
        'APLT_rmk',
        'CDAC',
        'CDAC_rmk',
        'SINDA',
        'SINDA_rmk',
        'EmperCPF',
        'EmperCPF_rmk',
        'EmpeeCPF',
        'EmpeeCPF_rmk',
        'NetSalary',
        'NetSalary_rmk',
        'MinistryExp',
        'MinistryExp_rmk',
        'TransferOUT',
        'TransferOUT_rmk',
        'CreditCard',
        'CreditCard_rmk',
        'OutcomeOthers',
        'OutcomeOthers_rmk',
        'ReservedFundCF',
        'ReservedFundCF_rmk'
      ],
      getData: false,
      role:''


    }
  }


  componentDidMount() {
    this._isMounted = true;
    this.getCurrentReserve();
    this.renderReserveTable();
  }

  componentWillUnmount(){
    this._isMounted = false;
  }


  getCurrentReserve(){

    const {staff} = this.state;
    const {year} = this.state;
    var {month} = this.state;

    month = parseInt(month) + 1;

    // console.log(month, year)

    fetch(backend + `api/scheduler/displayReserve/` + staff + '/'+ month + '/' + year, {
      method: 'GET',
      credentials: 'include'
    })
    .then(res => res.json())
    .then((res) => {

      if (this._isMounted){
        this.setState({getData: true})
      }

      // console.log('return from fetch', res);
      if(res.length > 1){
        console.log('error: multiple records returned');
      }
      else if(res.length !== 0 && res.hasOwnProperty('data')){
        // console.log('record found', res.data);
        if (this._isMounted){
          this.setState({reserve: res.data});
          this.setState({role: res.data.role})
        }

      }
      else if (res.length !== 0 && res.hasOwnProperty('error')){
        console.log('error found',res['error'] )
      }
      else{
        console.log('error', res);
      }

    });


  }

  // Clean out the null, 0, undefined
  processReserveDate(reserve){
      var reserve_new = reserve;
      // console.log(Object.keys(reserve));

      (Object.keys(reserve)).map((k)=>{
        // console.log(k);
        // console.log(reserve[k]);
        if (k.match(/_rmk/gi)){
          // console.log('find _rmk');
          if (reserve[k] === "null" || reserve[k] === '0' || reserve[k] === 0 || reserve[k] === undefined){
            reserve_new[k] = "";
          }
        }
        else{
          if (reserve[k] === "null" || reserve[k] === '0' || reserve[k] === "" || reserve[k] === undefined){
            reserve_new[k] = 0;
          }

        }
        return 0;

      });
      // this.setState({resreve: reserve_new});
      return reserve_new;
    }



  CDAC_SINDA(reserve){

    if(reserve.hasOwnProperty('SINDA') && reserve.SINDA !== 0){
      // console.log('has SINDA')

      return (<tr>
        <td>SINDA Funds</td>
        <td style={{textAlign:"right"}}>{numberWithCommas(reserve.SINDA)}</td>
        <td>{reserve.SINDA_rmk}</td>
      </tr>)
    }

    else if (reserve.hasOwnProperty('CDAC')){
      // console.log('has CDAC')
      return (<tr>
        <td>CDAC Funds</td>
        <td style={{textAlign:"right"}}>{numberWithCommas(reserve.CDAC)}</td>
        <td>{reserve.CDAC_rmk}</td>
      </tr>)
    }

  }



  renderReserve(reserve_o, role){
    // console.log(Object.keys(reserve_o));
    // const {reserve} = this.state;

    // const {role} = this.state;
    var reserve = this.processReserveDate(reserve_o);
    // console.log(reserve);

    var incomeTotal;
    var outcomeTotal = roundup(changeFloat(reserve.Insurance) + changeFloat(reserve.AdminCharges) + changeFloat(reserve.APLT) + changeFloat(reserve.CDAC) + changeFloat(reserve.SINDA) + changeFloat(reserve.RelatedFunds) + changeFloat(reserve.EmpeeCPF) + changeFloat(reserve.EmperCPF) + changeFloat(reserve.NetSalary) + changeFloat(reserve.MinistryExp) + changeFloat(reserve.TransferOUT) + changeFloat(reserve.CreditCard) + changeFloat(reserve.Cheque) + changeFloat(reserve.OutcomeOthers));
    var sOrd_addition;
    var sOrd_deduction = roundup(changeFloat(outcomeTotal) - changeFloat(reserve.Budget)) ;

    var reserveCF;

    role = reserve.role;

    // if (role === 2){
    //   var total_Donation = changeFloat(reserve.Couple);
    //   incomeTotal = roundup(changeFloat(reserve.BalanceBF) + changeFloat(reserve.CashProject) + changeFloat(reserve.TransferIN) + changeFloat(reserve.GovernCredit) + changeFloat(reserve.IncomeOthers)) ;
    //
    //   var bala_donation = roundup(changeFloat(outcomeTotal) - changeFloat(incomeTotal));
    //
    //   if (bala_donation < 0){
    //     reserve.Donations = 0;
    //
    //     // donation_process = bala_donation
    //
    //     incomeTotal = roundup(incomeTotal + 0);
    //     sOrd_addition = roundup(0 - changeFloat(reserve.Budget));
    //
    //     reserve.ReservedFundCF = roundup(changeFloat(incomeTotal) - changeFloat(outcomeTotal));
    //   }
    //
    //   else{
    //     // enought donation for wife
    //     if (bala_donation < total_Donation){
    //       reserve.Donations = bala_donation;
    //
    //       // donation_process = bala_donation
    //
    //       incomeTotal = roundup(incomeTotal + bala_donation);
    //       sOrd_addition = roundup(bala_donation - changeFloat(reserve.Budget));
    //
    //       reserve.ReservedFundCF = 0;
    //       // console.log('wife new Donation', donation_process)
    //
    //
    //     }
    //     else{
    //       reserve.Donations = total_Donation;
    //
    //       // donation_process = total_Donation
    //
    //       // console.log('wife new Donation', donation_process)
    //
    //       incomeTotal = roundup(incomeTotal+ total_Donation) ;
    //       sOrd_addition = roundup(total_Donation - changeFloat(reserve.Budget));
    //
    //       reserveCF = roundup(changeFloat(incomeTotal) - changeFloat(outcomeTotal));
    //
    //       reserve.ReservedFundCF = reserveCF;
    //     }
    //   }
    //
    // }

    // else{
      incomeTotal = roundup(changeFloat(reserve.BalanceBF) + changeFloat(reserve.Donations) + changeFloat(reserve.CashProject) + changeFloat(reserve.TransferIN) + changeFloat(reserve.GovernCredit) + changeFloat(reserve.IncomeOthers)) ;
      sOrd_addition = roundup(changeFloat(reserve.Donations) - changeFloat(reserve.Budget));

      reserveCF = roundup(changeFloat(incomeTotal) - changeFloat(outcomeTotal));

      reserve.ReservedFundCF = reserveCF;
    // }


    var total_Ratio;
    if (reserve.Budget === 0){
      // total_Ratio = 'Budget is not defined';
      total_Ratio = '0%';
    }
    else{
      total_Ratio = (Math.round((reserve.Donations / reserve.Budget) * 10000) / 100) + '%';

    }

    return(
      <tbody>
        <tr>
          <td><label>Addition</label></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Balance B/F</td>
          <td key='BalanceBF' style={{textAlign:"right"}}>{numberWithCommas(reserve.BalanceBF)}</td>
          <td key='BalanceBF_rmk'>{reserve.BalanceBF_rmk}</td>
        </tr>
        <tr>
          <td>Donations</td>
          <td key='Donations' style={{textAlign:"right"}}>{numberWithCommas(reserve.Donations)}</td>
          {(role===2 || role===3) ?
            <td key='Donations_rmk'>(Couple's Total Donations: {numberWithCommas(reserve.Couple)}) &nbsp;&nbsp;&nbsp; {reserve.Donations_rmk}</td>
           :
            <td key='Donations_rmk'>{reserve.Donations_rmk}</td>
          }
        </tr>
        <tr>
          <td>Cash Project</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.CashProject)}</td>
          <td>{reserve.CashProject_rmk}</td>
        </tr>
        <tr>
          <td>Transfer(IN)</td>
          <td style={{textAlign:"right"}} className='transfer'>
            {numberWithCommas(reserve.TransferIN)}
          </td>
          <td>
            <ul className="dropdown" dangerouslySetInnerHTML={{__html: (reserve.TransferIN_details || '')}}></ul>
            {reserve.TransferIN_rmk}
          </td>
        </tr>
        <tr>
          <td>Government Credit</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.GovernCredit)}</td>
          <td>{reserve.GovernCredit_rmk}</td>
        </tr>
        <tr>
          <td>Others</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.IncomeOthers)}</td>
          <td>{reserve.IncomeOthers_rmk}</td>
        </tr>
        <tr>
          <td>Total</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(incomeTotal)}</td>
          <td></td>
        </tr>
        <tr>
          <td>Budget</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.Budget)}</td>
          <td>{reserve.Budget_rmk}</td>
        </tr>
        <tr>
          <td>Surplus/Deficit</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(sOrd_addition)}</td>
          <td></td>
        </tr>

        <tr><td></td><td></td><td></td></tr>

        <tr>
          <td><label>Deduction</label></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Insurance</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.Insurance)}</td>
          <td>{reserve.Insurance_rmk}</td>
        </tr>
        <tr>
          <td>Singapore Admin Charges</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.AdminCharges)}</td>
          <td>{reserve.AdminCharges_rmk}</td>
        </tr>
        <tr>
          <td>APLT</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.APLT)}</td>
          <td>{reserve.APLT_rmk}</td>
        </tr>
        {this.CDAC_SINDA(reserve)}
        {/*<tr>
          <td>CDAC Funds</td>
          <td>{CDAC}</td>
          <td>{reserve.CDAC_rmk}</td>
        </tr>*/}
        <tr>
          <td>Other Funds</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.RelatedFunds)}</td>
          <td>{reserve.RelatedFunds_rmk}</td>
        </tr>
        <tr>
          <td>Employer CPF</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.EmperCPF)}</td>
          <td>{reserve.EmperCPF_rmk}</td>
        </tr>
        <tr>
          <td>Employee CPF</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.EmpeeCPF)}</td>
          <td>{reserve.EmpeeCPF_rmk}</td>
        </tr>
        <tr>
          <td>Net Salary</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.NetSalary)}</td>
          <td>{reserve.NetSalary_rmk}</td>
        </tr>
        <tr>
          <td>Ministry Expenses</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.MinistryExp)}</td>
          <td>{reserve.MinistryExp_rmk}</td>
        </tr>
        <tr>
          <td>Transfer (Out)</td>
          <td style={{textAlign:"right"}} className='transfer'>
            {numberWithCommas(reserve.TransferOUT)}
          </td>
          <td>
            <ul className="dropdown" dangerouslySetInnerHTML={{__html: ( reserve.TransferOUT_details || '')}}></ul>
            {reserve.TransferOUT_rmk}
          </td>
        </tr>
        <tr>
          <td>Credit Card Charges</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.CreditCard)}</td>
          <td>{reserve.CreditCard_rmk}</td>
        </tr>
        <tr>
          <td>Cheque Charges</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.Cheque)}</td>
          <td>{reserve.Cheque_rmk}</td>
        </tr>
        <tr>
          <td>Others</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.OutcomeOthers)}</td>
          <td>{reserve.OutcomeOthers_rmk}</td>
        </tr>
        <tr>
          <td>Total</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(outcomeTotal)}</td>
          <td></td>
        </tr>

        <tr>
          <td>Budget</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.Budget)}</td>
          <td>{reserve.Budget_rmk}</td>
        </tr>


        <tr>
          <td>Surplus/Deficit</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(sOrd_deduction)}</td>
          <td></td>
        </tr>

        <tr>
          <td></td><td></td><td></td>
        </tr>

        <tr>
          <td>Reserve Fund C/F</td>
          <td style={{textAlign:"right"}}>{numberWithCommas(reserve.ReservedFundCF)}</td>

          {(role === 3)?
            <td>(Wife's Reserve: {numberWithCommas(reserve.WifeBalance)}, Couple's Total Reserve: {numberWithCommas(reserve.ReservedFundCF + reserve.WifeBalance)}) &nbsp;&nbsp;&nbsp;{reserve.ReservedFundCF_rmk}</td>
            :
            <td>{reserve.ReservedFundCF_rmk}</td>
          }


        </tr>

        <tr>
          <td>Total Collection vs Budget</td>
          <td style={{textAlign:"right"}}>{total_Ratio}</td>
          <td></td>
        </tr>
      </tbody>
    )
  }

  isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

            // <h2>Reserve of {reserve.Year} {reserve.Month}: {reserve.Id}</h2>

  renderReserveTable(){
    const {reserve} = this.state;
    // const {month} = this.state;
    // const {year} = this.state;
    let role = this.state.role;

    // console.log(reserve);

    let getData = this.state.getData;

    if (getData === false){
      return  (<div className="only-row">Loading... </div>);
    }
    else if (reserve === undefined || this.isEmpty(reserve)  ){
      return  (<div className="only-row">No Reserve Found</div>);
    }
    else{
      // console.log('reserve exists');
      return (<div>
        <h4 className = 'title'>Current Month Reserve</h4>
        <div className = "table-container">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>Category</th>
                <th>Amount</th>
                <th>Remark</th>
              </tr>
            </thead>
            {this.renderReserve(reserve, role)}
          </table>
        </div>
      </div>);
    }
    }




  render() {
    let reserve_table = this.renderReserveTable();

    return (
      <div>
        <div className="reserve-table" id="reserve-table">
          {reserve_table}
        </div>
      </div>
    );

  }



}

export default CurrentReserve;
