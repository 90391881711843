import React, {Component} from "react";
import moment from 'moment';
// import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import TransferTable from "../components/transferTable.js";
var config = require('../config.js');
const backend = config.backend;


class TransferReceive extends Component {

  _isMounted = false;


  state = {
    id : this.props.id,
    transfers: [],
    check: false
  }



  componentDidMount() {
    // console.log("Component did mount");
    this._isMounted = true;
    this.getReceivedTransfers();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  sortIcon (column, index, { sortElement, filterElement }, length){
    if (length <= 1){
      return (
        <div> {column.text} </div>
      )
    }
    else{
      var {icon} = <i className="material-icons">unfold_more</i>;

      var { order } = sortElement.props;
      if (order === 'desc'){
        icon = <i className="material-icons">keyboard_arrow_up</i>
      }
      else if (order === 'asc'){
        icon = <i className="material-icons">keyboard_arrow_down</i>
      }
      else{
        icon = <i className="material-icons">unfold_more</i>
      }

      return (
        <div>
          {icon} {column.text}
        </div>
      );
    }
  }


  getReceivedTransfers = _ => {

    fetch(backend + `api/transfer/to/`+this.state.id, {
      method: 'GET',
      credentials: 'include'
    })
    .then(response => response.json())
    .then((response) => {
      // console.log(response);

      fetch(backend + `api/staffsimple`, {
        method: 'GET',
        credentials: 'include'
      })
      .then(res => res.json())
      .then(({data}) => {

        var now = new Date();
        now = now.setHours(0,0,0,0);
        now = moment(now);
        var arr = [];

        for (var key in response){
          if (moment(response[key]['EndDate']).isSameOrAfter(now, 'month') && response[key]['Status'] === "approve"){
            if(response[key]['Comment'] === 'null'){
              delete response[key]['Comment'];
            }

            for (var k in data){

              if ( (typeof(response[key]['FromStaffId']) === "number" && response[key]['FromStaffId'] === data[k]['value']) || (typeof(response[key]['FromStaffId']) === "string" && parseInt((response[key]['FromStaffId']).trim(), 10) === (data[k]['value']) ) ){
                response[key]['FromStaffId'] = data[k]['label'];

                // console.log(data[k]['label']);
                break;
              }

            }
            // Can be comment if all the staffId is stored
            arr.push(response[key]);
          }
        }

        if (this._isMounted){
          this.setState({transfers: arr});
          this.setState({check: true});
        }

      })
      .catch(err => console.error(err));

    }).catch(err => console.error(err))

  }

  sortDates (a, b, order) {
    var dateA = new Date(a), dateB = new Date(b);
    if (order === 'asc'){
      return dateA - dateB;
    }
    return dateB - dateA;
  }


  render() {
    const {transfers} = this.state;
    const {check} = this.state;

    const columns = [{
      dataField: 'FromStaffId',
      text:'FROM',
      sort: true,
      headerFormatter: (column, index, { sortElement, filterElement }) => {
        return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
      }
    }, {
      dataField: 'Amount',
      text:'AMOUNT (SGD)',
      sort: true,
      formatter: (amount) => {
        return '$ '+amount;
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === 'asc') {
          return b - a;
        }
        return a - b; // desc
      },
      headerFormatter: (column, index, { sortElement, filterElement }) => {
        return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
      },
      style:{
        // 'padding-left': '34px'
        'paddingLeft': '34px'
      }
    }, {
      dataField: 'StartDate',
      text:'FIRST TRANSFER DATE',
      sort: true,
      sortFunc: this.sortDates,
      formatter: (from) => {
        return moment(from).format('DD-MMM-YYYY');
      },
      headerFormatter: (column, index, { sortElement, filterElement }) => {
        return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
      },
      style:{
        // 'padding-left': '34px'
        'paddingLeft': '34px'
      }
    }, {
      dataField: 'Times',
      text:'MONTH(S)',
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === 'asc') {
          return b - a;
        }
        return a - b; // desc
      },
      headerFormatter: (column, index, { sortElement, filterElement }) => {
        return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
      },
      style:{
        // 'padding-left': '34px'
        'paddingLeft': '34px'
      }
    }, {
      dataField: 'EndDate',
      text:'LAST TRANSFER DATE',
      sort: true,
      sortFunc: this.sortDates,
      formatter: (end) => {
        return moment(end).format('DD-MMM-YYYY');
      },
      headerFormatter: (column, index, { sortElement, filterElement }) => {
        return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
      },
      style:{
        // 'padding-left': '34px'
        'paddingLeft': '34px'
      }
    }, {
      dataField: 'Status',
      text:'STATUS',
      sort: true,
      formatter: (status) => {
        // return status.charAt(0).toUpperCase() + status.substr(1);
        if (status === undefined || status === 'null' || status === null)
        {
          return status;
        }
        else if (status === 'approve')
        {
          return status.charAt(0).toUpperCase() + status.substr(1) + 'd';
        }
        else if (status === 'reject')
        {
          return status.charAt(0).toUpperCase() + status.substr(1) + 'ed';
        }
        else{
          return status.charAt(0).toUpperCase() + status.substr(1);
        }
      }
    }];

    const defaultSorted = [{
      dataField: 'StartDate',
      order: 'desc'
    }];

    // const {transfers} = this.state;

    if (check === false){
      return (<div className ='transfer-display'>
        <div className='tab'><span>Ongoing Transfers To You</span></div>
        <div className = "transfer-table-container">
        <div className="only-row">Loading...</div>
       </div>
       </div>);
    }

    else{

      if (transfers.length === 0){
        return (<div className ='transfer-display'>
          <div className='tab'><span>Ongoing Transfers To You</span></div>
        <div className = "transfer-table-container">
          <div className="only-row">No Transfer Found</div>
         </div>
         </div>);
      }
      else{
        return (<div className ='transfer-display'>
          <div className='tab'><span>Ongoing Transfers To You</span></div>
          <div className = "table-container">
            <TransferTable transfers={transfers} pending={false} type="Staff" transferType="Received"/>
          {/* <BootstrapTable bootstrap4 keyField='Id' data={ transfers } columns={ columns } bordered={ false } striped hover defaultSorted={ defaultSorted } /> */}
          </div>
        </div>);
      }

    }

  }
}

export default TransferReceive;
