import React, {Component} from "react";
import moment from 'moment';
// import BootstrapTable from 'react-bootstrap-table-next';
import TransferTable from "../components/transferTable.js";

var config = require('../config.js');
const backend = config.backend;

class TransferPending extends Component {

  _isMounted = false;


  state = {
    id : this.props.id,
    transfers: [],
    check: false
  }


  componentDidMount() {
    this._isMounted = true;
    this.getPendingTransfers();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  sortIcon (column, index, { sortElement, filterElement }, length){
    if (length <= 1){
      return (
        <div> {column.text} </div>
      )
    }
    else{
      var {icon} = <i className="material-icons">unfold_more</i>;

      var { order } = sortElement.props;
      if (order === 'desc'){
        icon = <i className="material-icons">keyboard_arrow_up</i>
      }
      else if (order === 'asc'){
        icon = <i className="material-icons">keyboard_arrow_down</i>
      }
      else{
        icon = <i className="material-icons">unfold_more</i>
      }

      return (
        <div>
          {icon} {column.text}
        </div>
      );
    }
  }


  sortDates (a, b, order) {
    var dateA = new Date(a), dateB = new Date(b);
    if (order === 'asc'){
      return dateA - dateB;
    }
    return dateB - dateA;
  }


  getPendingTransfers = _ => {

    fetch(backend + `api/transfer/from/`+this.state.id, {
      method: 'GET',
      credentials: 'include'
    })
    .then(response => response.json())
    .then((response) => {
      // Get all the staffs
      fetch(backend + `api/staffsimple`, {
        method: 'GET',
        credentials: 'include'
      })
      .then(res => res.json())
      .then(({data}) => {
        // console.log('data',data);
        // console.log('response',response);

        var arr = [];
        for (var key in response){
          if (response[key]['Status'] === "Pending"){
            // console.log('pending transfer');
            if(response[key]['Comment'] === 'null'){
              delete response[key]['Comment'];
              // console.log('delete',response[key]['Id'] );
            }

            for (var k in data){
              // console.log('value before trim', typeof(response[key]['ToStaffId']));
              if ( (typeof(response[key]['ToStaffId']) === "number" && response[key]['ToStaffId'] === data[k]['value']) || (typeof(response[key]['ToStaffId']) === "string" && parseInt((response[key]['ToStaffId']).trim(), 10) === (data[k]['value']) ) ){
                // console.log('hi');
                response[key]['ToStaffId'] = data[k]['label'];
                break;
              }
            }
            arr.push(response[key]);

          }
        }

        if (this._isMounted){
          this.setState({transfers: arr});
          this.setState({check: true});
        }
        // console.log('transfers after setState',this.state.transfers);
      })
      .catch(err => console.error(err));

    })
    .catch(err => console.error(err))

  }

  renderTransfer = ({Id, FromStaffId, ToStaffId, Amount, StartDate, Times, EndDate, Comment, Status }) => {
    return(<tr key={Id}>
        <td>{ToStaffId}</td>
        <td>{Amount}</td>
        <td type="date">{moment(StartDate).format('DD-MMM-YYYY')}</td>
        <td>{Times}</td>
        <td type="date">{moment(EndDate).format('DD-MMM-YYYY')}</td>
        <td>{(Comment==='null') ? '': Comment }</td>
        <td>{Status.charAt(0).toUpperCase() + Status.substr(1)}</td>
      </tr> )
  }

  render() {
    const {transfers} = this.state;
    const {check} = this.state;

    if (check === false){
      return (<div className ='transfer-display'>
        <div className='tab'><span>Pending Applications</span></div>
        <div className = "transfer-table-container">
        <div className="only-row">Loading...</div>
       </div>
       </div>);
    }

    else{
      if (transfers.length === 0){
        return (<div className ='transfer-display'>
          <div className='tab'><span>Pending Applications</span></div>
          <div className = "transfer-table-container">
          <div className="only-row">No Transfer Found</div>
         </div>
         </div>);
      }
      else{
        const columns = [{
          dataField: 'ToStaffId',
          text:'TO',
          sort: true,
          headerFormatter: (column, index, { sortElement, filterElement }) => {
            return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
          }
        }, {
          dataField: 'SubmitDate',
          text:'SUBMIT DATE',
          sort: true,
          sortFunc: this.sortDates,
          formatter: (submit) => {
            return moment(submit).format('DD-MMM-YYYY');
          },
          headerFormatter: (column, index, { sortElement, filterElement }) => {
            return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
          },
          style:{
            'padding-left': '34px'
          }
        },{
          dataField: 'Amount',
          text:'AMOUNT ( S$ )',
          sort: true,
          sortFunc: (a, b, order, dataField, rowA, rowB) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b; // desc
          },
          formatter: (amount) => {
            return '$ '+amount;
          },
          headerFormatter: (column, index, { sortElement, filterElement }) => {
            return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
          },
          style:{
            'padding-left': '34px'
          }
        }, {
          dataField: 'StartDate',
          text:'FIRST TRSF DATE',
          sort: true,
          sortFunc: this.sortDates,
          formatter: (from) => {
            return moment(from).format('DD-MMM-YYYY');
          },
          headerFormatter: (column, index, { sortElement, filterElement }) => {
            return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
          },
          style:{
            'padding-left': '34px'
          }
        }, {
          dataField: 'Times',
          text:'MONTH(S)',
          sort: true,
          sortFunc: (a, b, order, dataField, rowA, rowB) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b; // desc
          },
          headerFormatter: (column, index, { sortElement, filterElement }) => {
            return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
          },
          style:{
            'padding-left': '34px'
          }
        }, {
          dataField: 'EndDate',
          text:'LAST TRSF DATE',
          sort: true,
          sortFunc: this.sortDates,
          formatter: (end) => {
            return moment(end).format('DD-MMM-YYYY');
          },
          headerFormatter: (column, index, { sortElement, filterElement }) => {
            return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
          },
          style:{
            'padding-left': '34px'
          }
        }, {
          dataField: 'Status',
          text:'STATUS',
          formatter: (status) => {
            if (status === undefined || status === 'null' || status === null)
            {
              return status;
            }
            else if (status === 'approve')
            {
              return status.charAt(0).toUpperCase() + status.substr(1) + 'd';
            }
            else if (status === 'reject')
            {
              return status.charAt(0).toUpperCase() + status.substr(1) + 'ed';
            }
            else{
              return status.charAt(0).toUpperCase() + status.substr(1);
            }
          }
        }];

        const defaultSorted = [{
          dataField: 'SubmitDate',
          order: 'desc'
        }];


        return (<div className ='transfer-display'>
          <div className='tab'><span>Pending Applications</span></div>
          <div className = "table-container">
            <TransferTable transfers={transfers} pending={false} type="Staff" transferType="Pending"/>
            {/* <BootstrapTable bootstrap4 keyField='Id' data={ transfers } columns={ columns } bordered={ false } striped hover defaultSorted={ defaultSorted } /> */}
          </div>
        </div>);
      }
    }

    // console.log(transfers);


  }
}

export default TransferPending;
