const localFlag = 0;
var config = {};

// config.backend = `https://reserve-backend.azurewebsites.net/`;
// config.backend = `https://reserve-backend-new.azurewebsites.net/`;
// config.backend =  `https://reserve-backend-new.navigators.org.sg/`;
// config.backend = `https://reserve-backend-live-gdgyb4hjcjc0ata8.southeastasia-01.azurewebsites.net/`;
config.backend =  `https://reserve-backend-live.navigators.org.sg/`;
// config.backend = `https://reserve-backend-reserve-backend-staging.azurewebsites.net/`;

// config.website = "https://navsgstaffreserve.azurewebsites.net";
config.website = "https://reserve.navigators.org.sg";
// config.website = "https://reserve-frontend-live-hpe3a6fhckcdcea2.southeastasia-01.azurewebsites.net";
// config.website =  "https://reserve-staging.navigators.org.sg";
// config.website = "https://navsgstaffreserve-frontend-staging.azurewebsites.net/"
// config.website = "https://reserve-frontend.azurewebsites.net";

if (localFlag) {
    config.backend = `http://localhost:9000/`;
    config.website = `http://localhost:3000`;
}

config.debug = false;

config.params = {}

config.params.monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

config.logging = {};
config.logging.severity = "INFO";
config.logging.label = "Reserve Service";
config.logging.error = "ERROR";


config.ServiceBus = {}
config.ServiceBus.connectionString = "Endpoint=sb://staffreserve.servicebus.windows.net/;SharedAccessKeyName=RootManageSharedAccessKey;SharedAccessKey=hNUhCS9sf81iQn238AFqrDrF+bWXrN3fDDet95kvFYE=";

config.start_year = 2018;



module.exports = config;
