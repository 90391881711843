import React, { Component } from "react";
import axios from 'axios'
// import { NotificationManager } from 'react-notifications';
import { Toast } from "primereact/toast";

var config = require('../config.js');
var logging = require('./service/loggingComponent.js');
const loggingHeader = '[frontend][import_budgets]';

const debug = config.debug;
var backend_root = config.backend + `api/budget/uploadBudget/`;

class Import_Budgets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      loaded: 0,
      newStaff: false

    }
    this.setNewStaff = this.setNewStaff.bind(this);
    this.showError = this.showError.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
  }

  showSuccess(message) {
    this.toast.show({severity:'success', summary: 'Import Success', detail:message, life: 3000});
  }

  showError(message) {
    this.toast.show({severity:'error', summary: 'Import Failed', detail:message, life: 3000});
  } 

  setNewStaff = (e) => {
    var checkBox = e.target;

    // If the checkbox is checked, display the output text
    if (checkBox.checked === true) {
      console.log('checked');
      this.setState({ newStaff: true })
    } else {
      console.log('unchecked');
      this.setState({ newStaff: false })
    }
  }

  handleUpload = () => {
    logging.logToGoogle(loggingHeader + ' clicked upload');
    if (this.state.selectedFile === null) {
      // console.log('Please choose a file to upload');
      // NotificationManager.error('Please choose a file to upload');
      this.showError('Please choose a file to upload');
    }
    else {
      // console.log(req.body.newstaff);
      // console.log(this.state.selectedFile);
      const data = new FormData();
      data.append('file', this.state.selectedFile, this.state.selectedFile.name);

      var backend = backend_root + this.state.newStaff;

      console.log(backend)

      if (!debug) {

        axios
          .post(backend, data, {
            withCredentials: true,
            onUploadProgress: ProgressEvent => {
              this.setState({
                loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
              })
            },
          })
          .then(res => {
            if (res.data.error === 'Incorrect File') {

              // NotificationManager.error('Incorrect file detected, Please check again!');
              this.showError('Incorrect file detected, Please check again!')

              // this.setState({loaded:0 });
            }
            else {
              // this.setState({updating: 1})
              // this.countMsg()
              // window.confirm('File uploaded successfully!');
              // NotificationManager.success('File uploaded successfully!');
              this.showSuccess('File uploaded successfully!')

              // this.loadData()

            }
            console.log('res', res);

          })

      }

      else {
        for (var key of data.entries()) {
          console.log(key[0] + ', ' + key[1]);
        }
      }

    }

  }

  handleselectedFile = event => {
    console.log('file is ', event.target.files[0]);
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    })
  }

  render() {
    return (
      <div>
        <Toast ref={(el) => this.toast = el} />
        <h2>Import Budget Form </h2>

        <div className='upload-container row'>

          <div className="form-group">
            <div className="input-group">


              <div className='upload-note'>
                <h4>You can only upload a budget form of <b>an existing</b> staff.</h4>
                <h4>Upload before <b>15th of the month</b> to be able to reflect in the following month.</h4>
                <h5><b>Please upload an excel file (.xls or .xlsx)</b></h5>
              </div>

              <div className='upload-file'>

                <div className="custom-file">
                  <input type="file" className="custom-file-input" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" id="inputGroupFile02" onChange={this.handleselectedFile} />
                  {/*<label className="custom-file-label" htmlFor="inputGroupFile02">Choose file</label>*/}
                </div>

                <label><input checked={this.state.checked} onChange={this.setNewStaff} name="newstaff" id="newstaff" type="checkbox" value="exist" /> New Staff</label>

              </div>

              <div className="input-group-append">
                <button className="input-group-text" id="" onClick={this.handleUpload}>Upload</button>
              </div>

            </div>
          </div>


        </div>
      </div>);
  }
}

export default Import_Budgets;
