import React, { Component } from "react";
// import Select from 'react-select';
// import DatePicker from 'react-datepicker';
// import { InputText } from 'primereact/inputtext';
import moment from 'moment';
// import { NotificationManager } from 'react-notifications';
import TransferApplyTable from "../components/transferApplyTable.js";
import { Toast } from "primereact/toast";
var logging = require('../admin/service/loggingComponent.js');
const loggingHeader = '[frontend][staff][transfer_apply]';


// import 'react-datepicker/dist/react-datepicker.css';

var config = require('../config.js');
const backend = config.backend;
// var service = require('../admin/service/getinfo.js');


class TransferApply extends Component {

  constructor(props) {
    super(props);
    // this.transfer = props;
    // this.handleDateChange = this.handleDateChange.bind(this);
    this.handleStaffChange = this.handleStaffChange.bind(this);
    this.addTransfer = this.addTransfer.bind(this);
    this.resetTransfer = this.resetTransfer.bind(this);
    this.showError = this.showError.bind(this);
    this.showSuccess = this.showSuccess.bind(this);

    this.state = {
      staffs: [],
      transfer: {
        fromStaffId: this.props.id,
        toStaffId: '',
        amount: '',
        startDate: moment(),
        times: '1',
        comments: ''

      },
      errors: {},
      loading: 'true',
      toStaff: null,
      isButtonDisabled: false
    }

  }

  showSuccess(message) {
    this.toast.show({severity:'success', summary: 'Application Success', detail:message, life: 3000});
  }

  showError(message) {
    this.toast.show({severity:'error', summary: 'Application Failed', detail:message, life: 3000});
  } 

  handleDateChange(date) {

    const { transfer } = this.state;
    this.setState({
      startDate: date,
      transfer: { ...transfer, startDate: date }
    });

  }

  handleStaffChange(toStaff) {
    const { transfer } = this.state;
    console.log(toStaff)

    if (toStaff) {
      // console.log(toStaff)
      this.setState({ transfer: { ...transfer, toStaffId: toStaff.value } });
    }
    else {
      // console.log('null',toStaff)
      this.setState({ transfer: { ...transfer, toStaffId: '' } });

    }
  }


  inputValid = () => {
    let { transfer } = this.state;
    let errors = {};
    let formIsValid = true;

    // var promise = new Promise((resolve, reject) => {
    //Check To Staff Id
    if (!(isFinite(String(transfer.toStaffId)) && transfer.toStaffId !== '' && String(transfer.toStaffId) !== transfer.fromStaffId)) {
      errors['toStaff'] = 'Please choose correct staff!';
      formIsValid = false;
    }

    if (!(isFinite(String(transfer.amount)) && transfer.amount !== '' && transfer.amount !== null && Math.sign(transfer.amount) !== -1)) {
      errors['amount'] = 'Please enter correct amount!';
      formIsValid = false;
    }


    this.setState({ errors: errors });
    return formIsValid;
    // return promise;
  }

  componentWillMount() {
    this.getAllStaffs();
  }

  addTransfer = _ => {
    logging.logToGoogle(loggingHeader + ' click submit to add transfer');
    const { transfer } = this.state;

    // this.inputValid();
    if (!this.inputValid()) {
      // NotificationManager.error('Please check your input!');
      this.showError('Please check your input!')

    }
    else {
      this.setState({
        isButtonDisabled: true
      });
      fetch(backend + `api/transfer/new`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          transfer
        })
      })
        .then((response) => {
          this.resetTransfer();
          return response.json()
        })
        .then((body) => {

          console.log(body)

          if (body[0].Id !== undefined) {
            // NotificationManager.success('Your application is submitted successfully');
            this.showSuccess('Your application is submitted successfully');

          }

        })
        .catch((err) => {
          // NotificationManager.error('Your application does not submitted successfully.\nPlease try again.');
          logging.logErrorToGoogle(loggingHeader + ' add transfer error: ' + err);
          this.showError('Your application does not submitted successfully.\nPlease try again.')
          console.log(err)
        });
    }

  }

  resetTransfer = _ => {

    var { transfer } = this.state;
    var toStaff = transfer.toStaffId

    this.setState({
      transfer: {
        fromStaffId: this.props.id,
        toStaffId: toStaff,
        amount: '',
        // startDate:'',
        startDate: moment(),
        times: '1',
        comments: ''
      }
    })


    this.setState({
      isButtonDisabled: false
    });


  }


  getAllStaffs = _ => {

    var promise = new Promise((resolve, reject) => {
      fetch(backend + `api/staffsimple`, {
        method: 'GET',
        credentials: 'include'
      })
        .then(response => response.json())
        .then(({ data }) => {
          // console.log(data);
          this.setState({
            staffs: data,
            loading: 'false'
          });
          // });
          resolve(data)
        })
        .catch(err => console.error(err));
    });

    return promise;

  }

  textChange = (e) => {
    const { transfer } = this.state;
    this.setState({ transfer: { ...transfer, amount: e.target.value } })
  }

  notesChange = (e) => {
    const { transfer } = this.state;
    this.setState({ transfer: { ...transfer, comments: e.target.value } })
  }


  render() {
    const { transfer, staffs, loading, errors, isButtonDisabled } = this.state;

    staffs.sort((a, b) => {
      return a.label > b.label ? 1 : -1;
    });

    console.log(transfer)

    // const styles = {
    //   singleValue: (base, state) => ({
    //     ...base,
    //     color: state.selectProps.menuIsOpen ? '#C0C0C0' : base.color,
    //   }),
    // }

    // if (this.state.loading === 'true') {

    //   return (
    //     <div className='transfer-apply-form'>
    //       <div className='tab '><span>Reserve Transfer Application</span></div>
    //       <div className="well">
    //         <h4 className='title'>Loading...</h4>
    //       </div>
    //     </div>
    //   )

    // }


    return (
      <div>
      <Toast ref={(el) => this.toast = el} />
      <TransferApplyTable 
        transfer={transfer}
        staffs={staffs}
        loading={loading}
        errors={errors}
        handleStaffChange={this.handleStaffChange}
        addTransfer={this.addTransfer}
        isButtonDisabled={isButtonDisabled}
        textChange={this.textChange}
        notesChange={this.notesChange}
        /></div>
      // <div className='transfer-apply-form'>
      //   {/*<h2>Transfer_Apply {this.props.id}</h2>*/}
      //   <div className='tab '><span>Reserve Transfer Application</span></div>
      //   <div className="well">
      //     <form className="form-horizontal">
      //       <fieldset>
      //         <div className="form-group">
      //           <label className="col-lg-3 control-lebel"> TRANSFER TO</label>
      //           <div className="col-lg-9">
      //             <Select className='react-select-container' options={staffs} styles={styles} isSearchable="true" isClearable="true" placeholder="TYPE TO SEARCH FOR STAFF" onChange={this.handleStaffChange} />
      //             <span className="error">{this.state.errors["toStaff"]}</span>
      //           </div>
      //         </div>

      //         <div className="form-group">
      //           <label className="col-lg-3 control-lebel"> AMOUNT</label>
      //           <div className="col-lg-9">
      //             <input className="form-control" type="number" placeholder="AMOUNT (ONLY NUMBER ACCEPTED)" value={transfer.amount} onChange={e => this.setState({ transfer: { ...transfer, amount: e.target.value } })} />
      //             <span className="error">{this.state.errors["amount"]}</span>
      //           </div>
      //         </div>

      //         <div className="form-group">
      //           <label className="col-lg-3 control-lebel"> Date of Transfer</label>
      //           <div className="col-lg-9">
      //             <div className="date">
      //               <InputText
      //               value={(this.state.startDate === '') ? moment().format('DD-MM-YYYY') : moment(this.state.startDate).format('DD-MM-YYYY')}
      //               disabled
      //               />
      //               {/* <DatePicker
      //                 selected={(this.state.startDate === '') ? moment() : moment(this.state.startDate)}
      //                 // selected={this.state.startDate}
      //                 // onChange={this.handleDateChange}
      //                 dateFormat='DD-MM-YYYY'
      //                 disabled={true}
      //               /> */}
      //               {/* <span className="error">{this.state.errors["startDate"]}</span> */}

      //             </div>

      //           </div>
      //         </div>

      //         <div className="form-group">
      //           <label className="col-lg-3 control-lebel two-line"> <span>NOTES</span><br /><span>( SEND TO ADMIN STAFF )</span></label>
      //           <div className="col-lg-9">
      //             {/*<input className="form-control notes" style={{height: '100px'}} placeholder="NOTES" value={transfer.comments} onChange={e=> this.setState({ transfer:{...transfer, comments: e.target.value}})}/>*/}
      //             <textarea className="form-control notes" rows="3" wrap="hard" placeholder="NOTES" value={transfer.comments} onChange={e => this.setState({ transfer: { ...transfer, comments: e.target.value } })}> </textarea>

      //           </div>
      //         </div>

      //         <label>Your application of transfer will be processed once submitted. No future dated transfers allowed.</label>

      //         <div className="form-group">
      //           <div className="col-lg-12 col-lg-offset-9">
      //             {/*}<button type="submit" className="btn btn-primary" onClick={this.addNewTransfer.bind(this)}>Submit</button>*/}
      //             <button type="button" className="btn btn-primary submit" onClick={this.addTransfer} disabled={this.state.isButtonDisabled}>SUBMIT FOR APPROVAL</button>
      //           </div>
      //         </div>
      //       </fieldset>
      //     </form>
      //   </div>

      // </div>

    );
  }
}


export default TransferApply;
