import moment from 'moment';

var config = require('../../config.js');
const backend = config.backend;
const year_s = config.start_year;


export const getStaffList = _ => {

  return new Promise(function(resolve, reject) {

    // Get all the staffs
    fetch_retry(backend + `api/allstaffsimple`, {
      method: 'GET',
      credentials: 'include'
    }, 3)
    .then(res => res.json())
    .then(({data}) => {
      // console.log('data',data);
      // console.log('response',response);
      var staffs = {};
      var i = 0
      for (i = 0; i < data.length; i++) {
          // console.log(data[i]);
          staffs[data[i]['value']] = data[i]['label']
      }

      if (i === data.length){

        // console.log('staffs', staffs);
        resolve (staffs);

      }


    });

  });

}


export const getMonthList = _ => {

  return new Promise(function(resolve, reject) {

    const months = moment.months();
    // console.log(months);
    var months_drop = [];
    for (var m in months){
      months_drop.push({
        'value': (parseInt(m)+1).toString(),
        'label':months[m]
      });
    }
    // console.log(months_drop);
    resolve(months_drop);

  });

}


export const getYearList = _ => {

  return new Promise(function(resolve, reject) {

    var year = year_s;
    var year_e = moment().year();

    // If is it the last month of the year and date is more than 15th
    if (moment().month() === 11 && moment().date() >= 15){
      year_e =  moment().year() + 1;
    }

    let years_drop = [];

    while (year < year_e+1){
      years_drop.push({
        'value': year,
        'label':year
      })
      // years.push(year);
      year = year + 1;
    }

    // console.log(years_drop);
    years_drop.sort((a, b) =>
      a.value < b.value ? 1 : ((b.value < a.value) ? -1 : 0)
    );

    resolve(years_drop);

  });

}


export const fetch_retry = async (url, options, n) => {
    try {
        // console.log( 'trying left',n, url)

        return await fetch(url, options);

    } catch(err) {
        console.log('Retry left', n-1, 'url', url)
        if (n === 1) throw err;
        return await fetch_retry(url, options, n - 1);
    }
};


export const customStyles = {
    control: (base, state) => ({
      ...base,
      height: '45px',
      'min-height': '45px',
    }),
    singleValue: (base, state) => ({
      ...base,
      color: state.selectProps.menuIsOpen ? '#C0C0C0' : base.color,
    }),
    width: '100%',
    padding: '8px'
};
