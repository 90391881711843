import React, {Component} from "react";
import moment from 'moment';
// import BootstrapTable from 'react-bootstrap-table-next';
// import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
// import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import {fetch_retry} from "./service/getinfo";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import TransferTable from "../components/transferTable";

var config = require('../config.js');
const backend = config.backend;


class Transfers_All extends Component {

  _isMounted = false;

  state = {
    transfers: [],
    staffs:{},
    check: false
  }


  componentDidMount() {
    this._isMounted = true;
    this.getAllTransfers();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  filterDate(value, filter) {
    if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
        return true;
    }

    if (value === undefined || value === null) {
        return false;
    }

    return value === this.formatDate(filter);
}

  formatDate(value, title) {
    // console.log(value)
    // return moment(value).format('DD-MMM-YYYY');
    return (
      <React.Fragment>
          <span className="p-column-title">{title}</span>
          {moment(value).format('DD-MMM-YYYY')}
      </React.Fragment>
  );
  }

  formatCurrency(value, title) {
    // console.log(value)
    // return '$ '+value;
    return (
      <React.Fragment>
          <span className="p-column-title">{title}</span>
          {'$ '+value}
      </React.Fragment>
  );
  }

  noFormatColumn(value, title) {
    return (
      <React.Fragment>
          <span className="p-column-title">{title}</span>
          {value}
      </React.Fragment>
  );
  }

  formatStatus(status, title) {
    // console.log(status)
    let finalStatus;
    if (status === undefined || status === 'null' || status === null)
    {
      finalStatus = status;
    }
    else if (status === 'approve')
    {
      finalStatus = status.charAt(0).toUpperCase() + status.substr(1) + 'd';
    }
    else if (status === 'reject')
    {
      finalStatus = status.charAt(0).toUpperCase() + status.substr(1) + 'ed';
    }
    else{
      finalStatus = status.charAt(0).toUpperCase() + status.substr(1);
    }
    return (
      <React.Fragment>
          <span className="p-column-title">{title}</span>
          {finalStatus}
      </React.Fragment>
  );
  }

  sortIcon (column, index, { sortElement, filterElement }, length){
    if (length <= 1){
      return (
        <div> {column.text} </div>
      )
    }
    else{
      var {icon} = <i className="material-icons">unfold_more</i>;

      var { order } = sortElement.props;
      if (order === 'desc'){
        icon = <i className="material-icons">keyboard_arrow_up</i>
      }
      else if (order === 'asc'){
        icon = <i className="material-icons">keyboard_arrow_down</i>
      }
      else{
        icon = <i className="material-icons">unfold_more</i>
      }

      return (
        <div>
          {icon} {column.text}
        </div>
      );
    }
  }

  getAllTransfers = _ => {
    // fetch(backend + `api/transfer/all`)
    fetch_retry(backend + `api/transfer/all`,{
      method: 'GET',
      credentials: 'include'
    }, 3)
    .then(response => response.json())
    .then((response) => {

      // Get all the staffs
      // fetch_retry(backend + `api/staffsimple`, {}, 3)
      fetch_retry(backend + `api/allstaffsimple`, {
        method: 'GET',
        credentials: 'include'
      }, 3)// to get former field staffid
      .then(res => res.json())
      .then(({data}) => {
        // console.log('data',data);
        // console.log('response',response);

        var arr = [];
        var staffs_ = {};
        for (var s in data){

          // staffs_["'" + data[k]['label'] + "'"] = data[k]['label'];
            staffs_[ data[s]['label']] = data[s]['label'];

        }
        if (this._isMounted){
          this.setState({staffs: staffs_});
        }
        for (var key in response){

            if(response[key]['Comment'] === 'null'){
              delete response[key]['Comment'];
            }

            for (var k in data){
              if (parseInt(response[key]['ToStaffId'], 10) === (data[k]['value'])){
                response[key]['ToStaffId'] = data[k]['label'];
              }
              if (parseInt(response[key]['FromStaffId'], 10) === (data[k]['value'])){
                response[key]['FromStaffId'] = data[k]['label'];

              }

            }
            arr.push(response[key]);

        }

        if (this._isMounted){
          this.setState({transfers: arr});
          this.setState({check: true});
        }


    })
    .catch(err => console.error(err));

  })
  .catch(err => console.error(err));
  }

  sortDates (a, b, order) {
    // console.log('sortDate');
    var dateA = new Date(a), dateB = new Date(b);
    if (order === 'asc'){
      return dateA - dateB;
    }
    return dateB - dateA;
  }
  
  render() {
    const {transfers} = this.state;
    // const {staffs} = this.state;
    const {check} = this.state;

    // console.log(transfers)

    // const columns = [{
    //   dataField: 'FromStaffId',
    //   text:'FROM',
    //   filter: textFilter()

    // },
    // {
    //   dataField: 'ToStaffId',
    //   text:'TO',
    //   filter: textFilter()

    // }, {
    //   dataField: 'SubmitDate',
    //   text:'SUBMIT DATE',
    //   sort: true,
    //   sortFunc: this.sortDates,
    //   formatter: (submit) => {
    //     return moment(submit).format('DD-MMM-YYYY');
    //   },
    //   headerFormatter: (column, index, { sortElement, filterElement }) => {
    //     return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);

    //   }
    // },{
    //   dataField: 'Amount',
    //   text:'AMOUNT (SGD)',
    //   sort: true,
    //   sortFunc: (a, b, order, dataField, rowA, rowB) => {
    //     if (order === 'asc') {
    //       return b - a;
    //     }
    //     return a - b; // desc
    //   },
    //   formatter: (amount) => {
    //     return '$ '+amount;
    //   },
    //   headerFormatter: (column, index, { sortElement, filterElement }) => {
    //     return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
    //   }
    // }, {
    //   dataField: 'StartDate',
    //   text:'FIRST TRANSFER DATE',
    //   sort: true,
    //   sortFunc: this.sortDates,
    //   formatter: (from) => {
    //     return moment(from).format('DD-MMM-YYYY');
    //   },
    //   headerFormatter: (column, index, { sortElement, filterElement }) => {
    //     return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
    //   }
    // }, {
    //   dataField: 'Times',
    //   text:'MONTH(S)',
    //   sort: true,
    //   sortFunc: (a, b, order, dataField, rowA, rowB) => {
    //     if (order === 'asc') {
    //       return b - a;
    //     }
    //     return a - b; // desc
    //   },
    //   headerFormatter: (column, index, { sortElement, filterElement }) => {
    //     return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
    //   }
    // }, {
    //   dataField: 'EndDate',
    //   text:'LAST TRANSFER DATE',
    //   sort: true,
    //   sortFunc: this.sortDates,
    //   formatter: (end) => {
    //     return moment(end).format('DD-MMM-YYYY');
    //   },
    //   headerFormatter: (column, index, { sortElement, filterElement }) => {
    //     return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);

    //   }
    // }, {
    //   dataField: 'Status',
    //   text:'STATUS',
    //   sort: true,
    //   formatter: (status) => {

    //     if (status === undefined || status === 'null' || status === null)
    //     {
    //       return status;
    //     }
    //     else if (status === 'approve')
    //     {
    //       return status.charAt(0).toUpperCase() + status.substr(1) + 'd';
    //     }
    //     else if (status === 'reject')
    //     {
    //       return status.charAt(0).toUpperCase() + status.substr(1) + 'ed';
    //     }
    //     else{
    //       return status.charAt(0).toUpperCase() + status.substr(1);
    //     }
    //   },
    //   headerFormatter: (column, index, { sortElement, filterElement }) => {
    //     return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);

    //   }
    // }];

    // const defaultSorted = [{
    //   dataField: 'SubmitDate',
    //   order: 'desc'
    // }];

    // const options = {
    //   hidePageListOnlyOnePage: true,
    //   sizePerPage: 15,
    //   hideSizePerPage: true
    // }

    if(check === false){
      return (<div>
        <div className='tab'><span>All Transfers</span></div>
        <div className = "table-container">
        <div className="only-row">Loading...</div>
       </div>
       </div>);
    }

    else{
      if (transfers.length === 0){
        return (<div>
          <div className='tab'><span>All Transfers</span></div>
          <div className = "table-container">
          <div className="only-row">No Transfer Found</div>
         </div>
         </div>);
      }
      else{
        return (<div>
          <div className='tab'><span>All Transfers</span></div>
          <div className="table-container">
          <div className="card">

            <TransferTable transfers={transfers} pending={false} type="admin"/>
          </div>
          </div>
          </div>
        )
      }
    }

    // const header = <div style={{textAlign:'left'}}><Button type="button" icon="pi pi-external-link" label="Export to Excel" onClick={this.exportCSV}></Button></div>;

    // const columns = [{
    //   dataField: 'FromStaffId',
    //   text:'FROM',
    //   // filter: textFilter(),
    //   formatter: (data) => {
    //     return this.noFormatColumn(data['FromStaffId'], 'FROM');
    //   }
    // },
    // {
    //   dataField: 'ToStaffId',
    //   text:'TO',
    //   // filter: textFilter()
    //   formatter: (data) => {
    //     return this.noFormatColumn(data['ToStaffId'], 'TO');
    //   }
    // }, {
    //   dataField: 'SubmitDate',
    //   text:'SUBMIT DATE',
    //   // sort: true,
    //   // sortFunc: this.sortDates,
    //   formatter: (data) => {
    //     // return moment(submit).format('DD-MMM-YYYY');
    //     return this.formatDate(data['SubmitDate'], 'SUBMIT DATE');
    //   }
    // },{
    //   dataField: 'Amount',
    //   text:'AMOUNT (SGD)',
    //   // sort: true,
    //   // sortFunc: (a, b, order, dataField, rowA, rowB) => {
    //   //   if (order === 'asc') {
    //   //     return b - a;
    //   //   }
    //   //   return a - b; // desc
    //   // },
    //   formatter: (data) => {
    //     // return '$ '+amount;
    //     return this.formatCurrency(data['Amount'], 'AMOUNT (SGD)');
    //   }
    // }, {
    //   dataField: 'StartDate',
    //   text:'FIRST TRANSFER DATE',
    //   // sort: true,
    //   // sortFunc: this.sortDates,
    //   formatter: (data) => {
    //     // console.log(data)
    //     // return moment(from).format('DD-MMM-YYYY');
    //     return this.formatDate(data['StartDate'], 'FIRST TRANSFER DATE');
    //   }
    // }, {
    //   dataField: 'Times',
    //   text:'MONTH(S)',
    //   // sort: true,
    //   // sortFunc: (a, b, order, dataField, rowA, rowB) => {
    //   //   if (order === 'asc') {
    //   //     return b - a;
    //   //   }
    //   //   return a - b; // desc
    //   // }
    //   formatter: (data) => {
    //     return this.noFormatColumn(data['Times'], 'MONTH(S)')
    //   }
    // }, {
    //   dataField: 'EndDate',
    //   text:'LAST TRANSFER DATE',
    //   // sort: true,
    //   // sortFunc: this.sortDates,
    //   formatter: (data) => {
    //     // return moment(end).format('DD-MMM-YYYY');
    //     return this.formatDate(data['EndDate'], 'LAST TRANSFER DATE');
    //   }
    // }, 
    // {
    //   dataField: 'Status',
    //   text:'STATUS',
    //   // sort: true,
    //   formatter: (data) => {

    //     // if (status === undefined || status === 'null' || status === null)
    //     // {
    //     //   return status;
    //     // }
    //     // else if (status === 'approve')
    //     // {
    //     //   return status.charAt(0).toUpperCase() + status.substr(1) + 'd';
    //     // }
    //     // else if (status === 'reject')
    //     // {
    //     //   return status.charAt(0).toUpperCase() + status.substr(1) + 'ed';
    //     // }
    //     // else{
    //     //   return status.charAt(0).toUpperCase() + status.substr(1);
    //     // }
    //     return this.formatStatus(data['Status'], 'STATUS');
    //   },
    //   // headerFormatter: (column, index, { sortElement, filterElement }) => {
    //   //   return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);

    //   // }
    // }];

    // const defaultSorted = [{
    //   dataField: 'SubmitDate',
    //   order: 'desc'
    // }];

    // const options = {
    //   hidePageListOnlyOnePage: true,
    //   sizePerPage: 15,
    //   hideSizePerPage: true
    // }

    // if(check === false){
    //   return (<div>
    //     <div className='tab'><span>All Transfers</span></div>
    //     <div className = "table-container">
    //     <div className="only-row">Loading...</div>
    //    </div>
    //    </div>);
    // }

    // else{
    //   if (transfers.length === 0){
    //     return (<div>
    //       <div className='tab'><span>All Transfers</span></div>
    //       <div className = "table-container">
    //       <div className="only-row">No Transfer Found</div>
    //      </div>
    //      </div>);
    //   }
    //   else{
    //     return (<div>
    //       <div className='tab'><span>All Transfers</span></div>
    //       <div className="table-container">
    //       <div className="card">
    //       <DataTable  
    //         className="p-datatable-striped"
    //         ref={(el) => this.dt = el}
    //         value={transfers}
    //         header={header}
    //         paginator
    //         rows={10}
    //         sortField="SubmitDate"
    //         sortOrder={-1}
    //         removableSort
    //         paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    //         rowsPerPageOptions={[10,30,50]}
    //         paginatorPosition="both"
    //       >
    //         {columns.map(column => {
    //           return (column.text=='FROM' || column.text=='TO' ? <Column 
    //             key={"Id"}
    //             field={column.dataField}
    //             header={column.text}
    //             sortable
    //             style={{fontSize: '15px'}}
    //             filter
    //             headerStyle={{
    //               width: '15%',
    //               fontSize: '14px'
    //             }}
    //             filterPlaceholder={"Enter " + column.text + "..."}
    //             filterMatchMode="contains"
    //             body={column.formatter}
    //           /> : <Column 
    //         key={"Id"}
    //         field={column.dataField}
    //         header={column.text}
    //         sortable
    //         style={{fontSize: '15px'}}
    //         headerStyle={{
    //           fontSize: '14px'
    //         }}
    //         body={column.formatter}
    //       />)
    //         })}
    //       </DataTable>
    //       {/* <BootstrapTable bootstrap4 keyField='Id' data={ transfers } columns={ columns } bordered={ false } striped hover defaultSorted={ defaultSorted } filter={ filterFactory() } pagination={ paginationFactory(options) } /> */}
    //     </div>
    //     </div>
    //     </div>);
    //   }
    // }




  }
}

export default Transfers_All;
