import React, { Component } from "react"
import Cookies from 'js-cookie';
// import GoogleLogin from 'react-google-login';
var config = require('./config.js');
const backend = config.backend;
const url = require('url');
var logging = require('./admin/service/loggingComponent.js');
const loggingHeader = '[frontend][login]';

class Login extends Component {

  constructor(props) {
    super(props);

    this.signIn = this.signIn.bind(this)

  }


  componentDidMount() {
    // sessionStorage.setItem('int', 'refresh');
      this.signIn();
  }


  signIn = _ => {
    // logging.logToGoogle(loggingHeader + ' logging in');
    console.log("Signing in...");
    window.open(backend + "api/auth/google", "_self");
    // .then(response => response.json())
    // .then((response) => {
    //   console.log(response);
    // })
    // .catch(err => console.log(err));

  };


  render() {

    return (
      <div className='center'>

        <div className="loader"></div>
        <h1>Please log in with your navigators account.</h1>
        <h2>If nothing prompts from browser, <a href="# " onClick={this.signIn()}>click here</a> to log in. </h2>

      </div>

    );
  }
}


// export { strategy }
export default Login;
