import React, {Component} from "react";
import moment from 'moment';
import TransferTable from "../components/transferTable.js";
// import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';

var config = require('../config.js');
const backend = config.backend;


class TransferHistory extends Component {

  _isMounted = false;

  constructor(props){
    super(props);
    this.state = {
      id : this.props.id,
      received_transfers: [],
      give_transfers: [],
      staffs:[],
      receive_check: false,
      give_check: false

    }
  }


  componentDidMount() {
    this._isMounted = true;
    this.getTransfersHistory();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  sortIcon (column, index, { sortElement, filterElement }, length){
    if (length <= 1){
      return (
        <div> {column.text} </div>
      )
    }
    else{
      var {icon} = <i className="material-icons">unfold_more</i>;

      var { order } = sortElement.props;
      if (order === 'desc'){
        icon = <i className="material-icons">keyboard_arrow_up</i>
      }
      else if (order === 'asc'){
        icon = <i className="material-icons">keyboard_arrow_down</i>
      }
      else{
        icon = <i className="material-icons">unfold_more</i>
      }

      return (
        <div>
          {icon} {column.text}
        </div>
      );
    }
  }

  sortDates (a, b, order) {
    var dateA = new Date(a), dateB = new Date(b);
    if (order === 'asc'){
      return dateA - dateB;
    }
    return dateB - dateA;
  }

// Get all staffs
  getStaff(){
    var promise = new Promise((resolve, reject) => {
      fetch(backend + `api/allstaffsimple`, {
        method: 'GET',
        credentials: 'include'
      })
      .then(res => res.json())
      .then(({data}) => {
        if (this._isMounted){
          this.setState({staffs: data});
        }

        resolve (1);
      })
      .catch(function(err){
        console.error(err);
        reject (err);
      });
    });
    return promise;
  }

  // Get received_transfers
  getRecevied(){
    var promise = new Promise((resolve, reject) => {

      fetch(backend + `api/transfer/to/`+this.state.id, {
        method: 'GET',
        credentials: 'include'
      })
      .then(response => response.json())
      .then((response) => {
        // console.log('received transfers',response);
        var arr = [];
        const {staffs} = this.state;
        for (var key in response){

          if (response[key]['Status'] === "approve"){

            if(response[key]['Comment'] === 'null'){
              delete response[key]['Comment'];
            }

            for (var k in staffs) {
              if ( parseInt((response[key]['FromStaffId']).toString().trim(), 10) === (staffs[k]['value']) ){
              // if ( (typeof(response[key]['FromStaffId']) === "number" && response[key]['FromStaffId'] === staffs[k]['value']) || (typeof(response[key]['FromStaffId']) === "string" && parseInt((response[key]['FromStaffId']).toString().trim(), 10) === (staffs[k]['value']) ) ){

                response[key]['FromStaffId'] = staffs[k]['label'];

                // console.log(response[key]['FromStaffId'])

                arr.push(response[key]);
                break;

              }
            }
          }
        }

        if (this._isMounted){
          this.setState({received_transfers: arr, receive_check: true});
        }
        // console.log('received_transfers',arr);
        resolve (arr);
      })
      // .then( resolve (arr))
      .catch(err => console.error(err));
    });

    return promise;

  }


  // Get received_transfers
  getGive(){
    var promise = new Promise((resolve, reject) => {

      fetch(backend + `api/transfer/from/`+this.state.id, {
        method: 'GET',
        credentials: 'include'
      })
      // fetch(`http://localhost:5000/api/transfer/to/`+this.state.id)
      .then(response => response.json())
      .then((response) => {
        // console.log('gave transfers',response)
        var arr = [];
        const {staffs} = this.state;
        for (var key in response){

            for (var k in staffs) {
              if (parseInt((response[key]['ToStaffId']).toString().trim(), 10) === (staffs[k]['value'])){
                response[key]['ToStaffId'] = staffs[k]['label'];

                arr.push(response[key]);
                break;

              }
            }

        }

        if (this._isMounted){
          this.setState({give_transfers: arr, give_check: true });
        }
        // console.log('received_transfers',arr);
        resolve (arr);
      })
      // .then( resolve (arr))
      .catch(err => console.error(err));
    });

    return promise;

  }




  getTransfersHistory = _ => {

    this.getStaff()
    .then((res) => {
      this.getRecevied()
      .then((arr) => {

        this.getGive()
        .then((r) => {
        })
        .catch(err => console.error(err));

      })
      .catch(err => console.error(err));

    });



  }


  renderReceivedTable(transfers){

    // const {receive_check} = this.state;

    const columns = [{
      dataField: 'FromStaffId',
      text:'FROM',
      sort: true,
      headerFormatter: (column, index, { sortElement, filterElement }) => {
        return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
      }
    }, {
      dataField: 'Amount',
      text:'AMOUNT (SGD)',
      sort: true,
      formatter: (amount) => {
        return '$ '+amount;
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === 'asc') {
          return b - a;
        }
        return a - b; // desc
      },
      headerFormatter: (column, index, { sortElement, filterElement }) => {
        return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
      },
      style:{
        // 'padding-left': '34px'
        'paddingLeft': '34px'
      }
    }, {
      dataField: 'StartDate',
      text:'FIRST TRANSFER DATE',
      sort: true,
      sortFunc: this.sortDates,
      formatter: (from) => {
        return moment(from).format('DD-MMM-YYYY');
      },
      headerFormatter: (column, index, { sortElement, filterElement }) => {
        return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
      },
      style:{
        // 'padding-left': '34px'
        'paddingLeft': '34px'
      }
    }, {
      dataField: 'Times',
      text:'MONTH(S)',
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === 'asc') {
          return b - a;
        }
        return a - b; // desc
      },
      headerFormatter: (column, index, { sortElement, filterElement }) => {
        return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
      },
      style:{
        // 'padding-left': '34px'
        'paddingLeft': '34px'
      }
    }, {
      dataField: 'EndDate',
      text:'LAST TRANSFER DATE',
      sort: true,
      sortFunc: this.sortDates,
      formatter: (end) => {
        return moment(end).format('DD-MMM-YYYY');
      },
      headerFormatter: (column, index, { sortElement, filterElement }) => {
        return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);

      },
      style:{
        // 'padding-left': '34px'
        'paddingLeft': '34px'
      }
    }, {
      dataField: 'Status',
      text:'STATUS',
      sort: true,
      formatter: (status) => {
        // return status.charAt(0).toUpperCase() + status.substr(1);
        if (status === undefined || status === 'null' || status === null)
        {
          return status;
        }
        else if (status === 'approve')
        {
          return status.charAt(0).toUpperCase() + status.substr(1) + 'd';
        }
        else if (status === 'reject')
        {
          return status.charAt(0).toUpperCase() + status.substr(1) + 'ed';
        }
        else{
          return status.charAt(0).toUpperCase() + status.substr(1);
        }
      },
      headerFormatter: (column, index, { sortElement, filterElement }) => {
        return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
      },
      style:{
        // 'padding-left': '34px'
        'paddingLeft': '34px'
      }
    }];

    const defaultSorted = [{
      dataField: 'StartDate',
      order: 'desc'
    }];

    const options = {
      hidePageListOnlyOnePage: true,
      sizePerPage: 10,
      hideSizePerPage: true
    }

    return (
      <TransferTable transfers={transfers} pending={false} type="Staff" transferType="Received"/>
    )


    // return (<BootstrapTable bootstrap4 keyField='Id' data={ transfers } columns={ columns } bordered={ false } striped hover defaultSorted={ defaultSorted } pagination={ paginationFactory(options) }/>)



  }

  // renderGiveTable(data){
  renderGiveTable(transfers){

    const columns = [{
      dataField: 'ToStaffId',
      text:'TO',
      sort: true,
      headerFormatter: (column, index, { sortElement, filterElement }) => {
        return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
      }
    }, {
      dataField: 'Amount',
      text:'AMOUNT (SGD)',
      sort: true,
      formatter: (amount) => {
        return '$ '+amount;
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === 'asc') {
          return b - a;
        }
        return a - b; // desc
      },
      headerFormatter: (column, index, { sortElement, filterElement }) => {
        return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
      },
      style:{
        // 'padding-left': '34px'
        'paddingLeft': '34px'
      }
    }, {
      dataField: 'StartDate',
      text:'FIRST TRANSFER DATE',
      sort: true,
      sortFunc: this.sortDates,
      formatter: (from) => {
        return moment(from).format('DD-MMM-YYYY');
      },
      headerFormatter: (column, index, { sortElement, filterElement }) => {
        return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
      },
      style:{
        // 'padding-left': '34px'
        'paddingLeft': '34px'
      }
    }, {
      dataField: 'Times',
      text:'MONTH(S)',
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === 'asc') {
          return b - a;
        }
        return a - b; // desc
      },
      headerFormatter: (column, index, { sortElement, filterElement }) => {
        return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
      },
      style:{
        // 'padding-left': '34px'
        'paddingLeft': '34px'
      }
    }, {
      dataField: 'EndDate',
      text:'LAST TRANSFER DATE',
      sort: true,
      sortFunc: this.sortDates,
      formatter: (end) => {
        return moment(end).format('DD-MMM-YYYY');
      },
      headerFormatter: (column, index, { sortElement, filterElement }) => {
        return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);

      },
      style:{
        // 'padding-left': '34px'
        'paddingLeft': '34px'
      }
    }, {
      dataField: 'Status',
      text:'STATUS',
      sort: true,
      formatter: (status) => {
        // return status.charAt(0).toUpperCase() + status.substr(1);
        if (status === undefined || status === 'null' || status === null)
        {
          return status;
        }
        else if (status === 'approve')
        {
          return status.charAt(0).toUpperCase() + status.substr(1) + 'd';
        }
        else if (status === 'reject')
        {
          return status.charAt(0).toUpperCase() + status.substr(1) + 'ed';
        }
        else{
          return status.charAt(0).toUpperCase() + status.substr(1);
        }
      },
      headerFormatter: (column, index, { sortElement, filterElement }) => {
        return this.sortIcon(column, index, { sortElement, filterElement }, transfers.length);
      },
      style:{
        // 'padding-left': '34px'
        'paddingLeft': '34px'
      }
    }];

    const defaultSorted = [{
      dataField: 'StartDate',
      order: 'desc'
    }];


    const options = {
      hidePageListOnlyOnePage: true,
      sizePerPage: 10,
      hideSizePerPage: true
    }

    return (
      <TransferTable transfers={transfers} pending={false} type="Staff" transferType="Give"/>);
      // <BootstrapTable bootstrap4 keyField='Id' data={ transfers } columns={ columns } bordered={ false } striped hover defaultSorted={ defaultSorted } pagination={ paginationFactory(options)  }/>  );



  }


  EmptyTable(){
    return  (<div className="only-row">No Transfer Found</div>);
  }

  LoadingTable(){
    return  (<div className="only-row">Loading...</div>);
  }

  render() {
    const {received_transfers} = this.state;
    const {give_transfers} = this.state;
    const {give_check} = this.state;
    const {receive_check} = this.state;
    let received_table;
    let give_table;

    if(!receive_check){
      received_table = this.LoadingTable();
    }
    else{
      if (received_transfers.length === 0){
        received_table = this.EmptyTable();
      } else{
        received_table = this.renderReceivedTable(received_transfers);
      }
    }


    if(!give_check){
      give_table = this.LoadingTable();
    }
    else{
      if (give_transfers.length === 0){
        give_table = this.EmptyTable();
      } else{
        give_table = this.renderGiveTable(give_transfers);
      }
    }


    return (<div className ='transfer-display'>
      <div className='tab'><span>Transfer History</span></div>
      <div className = "table-container">
      <div><h3>Received</h3></div>

      {received_table}

      <div><h3>Give</h3></div>

      {give_table}

      </div>
    </div>);
  }
}

export default TransferHistory;
