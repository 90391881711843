import React, { Component } from "react";
// import Select from 'react-select';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import moment from 'moment';

class TransferApplyTable extends Component {
    render() {
        const { transfer, staffs, loading, handleStaffChange, errors, addTransfer, isButtonDisabled, textChange, notesChange } = this.props;

        console.log(transfer)

        const styles = {
            singleValue: (base, state) => ({
              ...base,
              color: state.selectProps.menuIsOpen ? '#C0C0C0' : base.color,
            }),
            width: '100%',
            padding: '7px'
        }

        if (loading === 'true') {
            return (
                <div className='transfer-apply-form'>
                  <div className='tab '><span>Reserve Transfer Application</span></div>
                  <div className="well">
                    <h4 className='title'>Loading...</h4>
                  </div>
                </div>
              )
        }

        return (
            <div className='transfer-apply-form'>
                <div className='tab '><span>Reserve Transfer Application</span></div>
                <div className="well">
                <form className="form-horizontal">
                    <fieldset>
                    <div className="form-group">
                        <label className="col-lg-3 control-lebel"> TRANSFER TO</label>
                        <div className="col-lg-9">
                        {/* <Select className='react-select-container' options={staffs} styles={styles} isSearchable="true" isClearable="true" placeholder="TYPE TO SEARCH FOR STAFF" onChange={handleStaffChange} /> */}
                        <Dropdown 
                        options={staffs} style={styles}
                        placeholder="TYPE TO SEARCH FOR STAFF"
                        onChange={handleStaffChange} value={transfer.toStaffId}
                        filter filterMatchMode="contains"
                        resetFilterOnHide/>
                        <span className="error">{errors["toStaff"]}</span>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="col-lg-3 control-lebel"> AMOUNT</label>
                        <div className="col-lg-9">
                        <input className="form-control" type="number" placeholder="AMOUNT (ONLY NUMBER ACCEPTED)" value={transfer.amount} onChange={textChange} />
                        <span className="error">{errors["amount"]}</span>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="col-lg-3 control-lebel"> Date of Transfer</label>
                        <div className="col-lg-9">
                        {/* <div className="date"> */}
                            <InputText
                            value={(transfer.startDate === '') ? moment().format('DD-MM-YYYY') : moment(transfer.startDate).format('DD-MM-YYYY')}
                            disabled
                            className="date"
                            />

                        {/* </div> */}

                        </div>
                    </div>

                    <div className="form-group">
                        <label className="col-lg-3 control-lebel two-line"> <span>NOTES</span><br /><span>( SEND TO ADMIN STAFF )</span></label>
                        <div className="col-lg-9">
                        <textarea className="form-control" rows="3" wrap="hard" placeholder="NOTES" onChange={notesChange}/>

                        </div>
                    </div>

                    <label>Your application of transfer will be processed once submitted. No future dated transfers allowed.</label>

                    <div className="form-group">
                        <div className="col-lg-12 col-lg-offset-9">
                        <button type="button" className="btn btn-primary submit" onClick={addTransfer} disabled={isButtonDisabled}>SUBMIT FOR APPROVAL</button>
                        </div>
                    </div>
                    </fieldset>
                </form>
                </div>

            </div>
        )
    }
}

export default TransferApplyTable;