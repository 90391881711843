import React from 'react';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import Popup from './popup.js';
// import 'chartjs-plugin-datalabels';
var logging = require('../../admin/service/loggingComponent.js');
const loggingHeader = '[frontend][mobile][staff][reserve]';

var config = require('../../config.js');
const backend = config.backend;
const months = config.params.monthNames;

var label = [];
var dataset = [];
var reserveData = [];



class StaffMobileReserve extends React.Component {

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      staff: this.props.id,
      name: '',
      month: moment().month(),
      // month: 11,
      year: moment().year(),
      // year: 2020,
      reserves: [],
      cur_reserve: '',
      checked: false,
      // range:3,
      labels: [],
      allreserves: [],
      data: {},
      showPopup: false,
      details: {},
      getall: false
    }


    this.getData = this.getData.bind(this);
    this.handleMonthRange = this.handleMonthRange.bind(this);
    this.filterData = this.filterData.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.cleanData = this.cleanData.bind(this);
    this.renderFilterButtons = this.renderFilterButtons.bind(this);
  }

  componentWillMount() {
    var staff = sessionStorage.getItem('id_s');
    var name = sessionStorage.getItem('name');
    this.setState({ staff: staff });
    this.setState({ name: name });
    this.setState({ data: { datasets: [], labels: [] } });
    label = [];
    dataset = [];
    reserveData = [];
  }


  componentDidMount() {
    this.getData();
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  togglePopup() {
    // console.log('togglePopup');
    this.setState({
      showPopup: !this.state.showPopup
    });
  }

  handleMonthRange = (m) => {
    logging.logToGoogle(loggingHeader + ' adjust month range: ' + m.target.value);
    // console.log(m.target.value)
    var range = m.target.value;

    if (range === '3M' || range === '3 Months') {
      this.filterData(3);
    }
    else if (range === '6M') {
      this.filterData(6);
    }
    else {
      this.filterData(12);
    }

  }

  getData = _ => {

    const { staff } = this.state;
    const { year } = this.state;
    const { month } = this.state;
    let that = this;

    let tempData = [];
    const currentYearPromises = [];

    // Fetch data for each month in the current year up to the current month
    // for (let i = 1; i <= month + 1; i++) {
    //   currentYearPromises.push(
    //     fetch(`${backend}api/scheduler/displayReserve/${staff}/${i}/${year}`, {
    //       method: 'GET',
    //       credentials: 'include',
    //     })
    //     .then(response => response.json())
    //     .then(pass_data => {
    //       console.log(pass_data)
    //       tempData = tempData.concat(pass_data.data);
    //     })
    //   );
    // }
    currentYearPromises.push(
      fetch(`${backend}api/reserve/staff/${staff}/year/${year}`, {
        method: 'GET',
        credentials: 'include',
      })
      .then(response => response.json())
      .then(pass_data => {
        console.log(pass_data)
        for (var m in pass_data) {
          if (pass_data[m] != null) {
            tempData = tempData.concat(pass_data[m]);
          }
        }
      })
    );

    // Execute all current year fetches and handle the response
    Promise.all(currentYearPromises).then(() => {
      // If the month is not December, fetch additional months from the previous year
      // console.log("Temp Data After Current Year Fetch:", tempData);
      if (month !== 12 && that._isMounted) {
        const pass_year = year - 1;
        const months_to_cal = 11 - month;
        const cur_data = tempData;
        console.log(cur_data)
        let temp = [];
        const previousYearPromises = [];

        // Fetch data for each remaining month in the previous year
        for (let j = months_to_cal; j > 0; j--) {
          const pass_month = 12 - j + 1;
          previousYearPromises.push(
            fetch(`${backend}api/scheduler/displayReserve/${staff}/${pass_month}/${pass_year}`, {
              method: 'GET',
              credentials: 'include',
            })
            .then(response => response.json())
            .then(pass_data => {
              console.log(pass_data)
              temp = temp.concat(pass_data.data);
            })
          );
        }

        // Once all previous year data is fetched, sort and update state
        Promise.all(previousYearPromises).then(() => {
          tempData = temp.concat(cur_data);
          console.log("Final Temp Data Before Sorting:", tempData);
          tempData.sort((a, b) => {
            if (a && b) {
              const yearA = Number(a.year) || 0;
              const yearB = Number(b.year) || 0;
              const monthA = Number(a.month) || 0;
              const monthB = Number(b.month) || 0;

              return yearA === yearB ? monthA - monthB : yearA - yearB;
            }
          });
          // console.log("Sorted Temp Data:", tempData);

          // Update state once all data is collected and sorted
          if (that._isMounted) {
            console.log(tempData)
            that.setState({ reserves: tempData, getall: true });
            that.cleanData();
          }
        });
      } else {
        // If only current year data is needed (December case)
        if (that._isMounted) {
          console.log(tempData)
          that.setState({ reserves: tempData, getall: true });
          that.cleanData();
        }
      }
    });

    // fetch(backend + `api/reserve/staff/` + staff + '/year/' + year, {
    //   method: 'GET',
    //   credentials: 'include'
    // })
    //   .then(function (response) {
    //     return response.json();
    //   })
    //   .then(function (data) {
    //     // If current month is not December, need to get past year's data
    //     if (month !== 12 && that._isMounted) {
    //       const pass_year = year - 1;

    //         var cur_data = data;

    //         var months_to_cal = 11 - month;
    //         var tempData = [];

    //         for (var i = months_to_cal; i > 0; i--) {
    //           var pass_month = 12 - i + 1;
    //           fetch(backend + `api/reserve/getOneReserve/` + staff + `/` + pass_month + `/` + pass_year, {
    //             method: 'GET',
    //             credentials: 'include',
    //           })
    //           .then(function (response) {
    //             return response.json();
    //           })
    //           .then(function (pass_data) {
    //             if (pass_month == 12) {
    //               tempData = tempData.concat(pass_data.data);
    //               data = tempData.concat(cur_data);
    //               console.log(data)
    //               that.setState({ reserves: data });
    //               that.setState({ getall: true })
    //               that.cleanData();
    //             } else {
    //               tempData = tempData.concat(pass_data.data);
    //             }
    //           })
    //         }
    //     }
    //     else { // else current month is Dec, only need current year data

    //       if (that._isMounted) {
    //         that.setState({ reserves: data });
    //         that.setState({ getall: true });
    //         that.cleanData();
    //       }

    //     }


    //   })

    // var tempData = [];
    // for (let i = 1; i <= month+1; i++) {
    //   console.log(i)
    //   const response = await fetch(`${backend}api/reserve/getOneReserve/${staff}/${i}/${year}`, {
    //     method: 'GET',
    //     credentials: 'include',
    //   });
    //   const pass_data = await response.json();
    //   tempData = tempData.concat(pass_data.data);
    //   console.log(tempData)
    //   if (month !== 12 && that._isMounted) {
    //     const pass_year = year - 1;

    //     // var cur_data = tempData;

    //     const months_to_cal = 11 - month;
    //     let temp = [];
    //     for (let j = months_to_cal; j > 0; j--) {
    //       const pass_month = 12 - j + 1;
    //       const response = await fetch(`${backend}api/reserve/getOneReserve/${staff}/${pass_month}/${pass_year}`, {
    //         method: 'GET',
    //         credentials: 'include',
    //       });
    //       const pass_data = await response.json();
    //       temp = temp.concat(pass_data.data);
    //     }
    //     tempData = temp.concat(tempData);
    //     if (that._isMounted) {
    //       that.setState({ reserves: tempData, getall: true });
    //       that.cleanData();
    //     }
            // if (pass_month == 12) {
            //   console.log("hello2")
            //   temp = temp.concat(pass_data.data);
            //   tempData = temp.concat(cur_data);
            //   // let data = tempData.sort((a,b) => {
            //   //   if (a.year === b.year) {
            //   //     return Number(a.month) - Number(b.month); // Sort by month if years are the same
            //   //   } else {
            //   //     return Number(a.year) - Number(b.year); // Sort by year
            //   //   }
            //   // })
            //   console.log(tempData)
            //   that.setState({ reserves: tempData });
            //   that.setState({ getall: true })
            //   that.cleanData();
            // } else {
            //   console.log("hello3")
            //   temp = temp.concat(pass_data.data);
            // }
          // })
        // }
      // }
    //   else { // else current month is Dec, only need current year data

    //     if (that._isMounted) {
    //       console.log("hello")
    //       that.setState({ reserves: tempData });
    //       that.setState({ getall: true });
    //       that.cleanData();
    //     }

    //   }
    // }
    // else {
    //   tempData = tempData.concat(pass_data.data);
    // }
    //   })
    // }

  }


  cleanData = _ => {

    // const {data} = this.state;
    var data = this.state.reserves;
    const { month } = this.state;
    const { year } = this.state;
    // var tempReservesData = [];

    if (!data || data.length === 0) {
      return; // Exit if there's no data
    }

    for (var i = 0; i < data.length; i++) {

      if (data[i]) {
        // console.log((data[i].Month));

        // label e.g. '19 Jan'
        // label.push(data[i].Year.slice(-2) + ' ' + months[data[i].Month - 1]);
        // label e.g. 'Jan 19'
        // label.push( months[data[i].Month - 1] + ' ' + data[i].Year.slice(-2));
        // label e.g. 'Jan 19 \n 12345.67'
        label.push([months[data[i].Month - 1] + ' ' + data[i].Year.slice(-2), parseFloat(data[i].ReservedFundCF)]);

        // dataset add in figure
        dataset.push(parseFloat(data[i].ReservedFundCF));
        // find current reserve
        if (parseInt(data[i].Month) - 1 === month && parseInt(data[i].Year) === year) {

          // console.log(month, year, data[i].ReservedFundCF);
          this.setState({ cur_reserve: parseFloat(data[i].ReservedFundCF) })

        }

      }

    }

    // let years = [year-1, year];

    // for (let j=0; j< years.length; j++) {
    //   for (let i = 0; i < months.length; i++) {
    //     const currentMonth = i + 1; // Months are 1-indexed (Jan = 1, Feb = 2, etc.)
    //     const monthData = data.find(item => parseInt(item.Month) === currentMonth && parseInt(item.Year) === years[j]);

    //     if (monthData) {
    //       // If we found data for the current month
    //       label.push([`${months[i]} ${monthData.Year.slice(-2)}`, parseFloat(monthData.ReservedFundCF)]);
    //       dataset.push(parseFloat(monthData.ReservedFundCF));
    //       tempReservesData.push(monthData);
    
    //       // Check for current reserve fund
    //       if (currentMonth - 1 === month && parseInt(data[i].Year) === year) {
    //         this.setState(prevState => ({
    //           cur_reserve: parseFloat(monthData.ReservedFundCF),
    //         }));
    //       }
    //     } 
    //   }
    // }

    // console.log(tempReservesData)
  
    // Update state after processing
    this.setState({ labels: label, allreserves: dataset });
    this.filterData(3);


    // for (var i = 0; i < data.length; i++) {

    //   if (data[i]) {
    //     console.log((data[i].Month));

    //     // label e.g. '19 Jan'
    //     // label.push(data[i].Year.slice(-2) + ' ' + months[data[i].Month - 1]);
    //     // label e.g. 'Jan 19'
    //     // label.push( months[data[i].Month - 1] + ' ' + data[i].Year.slice(-2));
    //     // label e.g. 'Jan 19 \n 12345.67'
    //     label.push([months[data[i].Month - 1] + ' ' + data[i].Year.slice(-2), parseFloat(data[i].ReservedFundCF)]);

    //     // dataset add in figure
    //     dataset.push(parseFloat(data[i].ReservedFundCF));
    //     // find current reserve
    //     if (parseInt(data[i].Month) - 1 === month && parseInt(data[i].Year) === year) {

    //       console.log(month, year, data[i].ReservedFundCF);
    //       this.setState({ cur_reserve: parseFloat(data[i].ReservedFundCF) })

    //     }

    //   }

    // }

    // this.setState({ labels: label });
    // this.setState({ allreserves: dataset });

    // this.filterData(3)

  }


  filterData(range) {
    var labels = this.state.labels;
    var allres = this.state.allreserves;
    range = range * (-1);
    var lab = labels.slice(range);
    var dataset_slice = allres.slice(range);

    var data = {
      datasets: [
        {
          data: dataset_slice,
          fill: false,
          lineTension: 0.1,
          borderColor: '#0B3C5D',
          borderCapStyle: 'round',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          borderWidth: 2,
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#328CC1',
          pointBorderWidth: 6,
          pointHoverRadius: 6,
          pointHoverBackgroundColor: '#EDC500',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 1,
          pointRadius: 1,
          pointHitRadius: 10,
        }
      ],
      labels: lab
    }

    console.log(data)

    this.setState({ data: data })
    // console.log('labels and allreserves after range', labels, this.state.allreserves)
  }

  renderFilterButtons() {

    const { getall } = this.state;

    if (getall) {
      return (
        <div className='range-buttons'>
          <input className='button' type='button' value='3M' onClick={this.handleMonthRange} />
          <input className='button' type='button' value='6M' onClick={this.handleMonthRange} />
          <input className='button' type='button' value='1Y' onClick={this.handleMonthRange} />
        </div>
      )
    }

    else {
      return (
        <div className='range-button'>
          <input className='button' type='button' value='3 Months' />
        </div>
      )
    }

  }

  render() {

    var { cur_reserve } = this.state;
    var { reserves } = this.state;
    var cur_year = this.state.year;
    const { getall } = this.state;

    var { data } = this.state;
    var chartOptions = {
      legend: {
        display: false
      }
    }

    var details = {};
    var year;
    var month;
    // console.log(reserves, data, getall)

    // If the data already get
    if (data && data.labels && data.labels.length !== 0) {

      return (
        <div className='content-container'>

          <div className='title'>
            <h3 className='line'> Current Reserve </h3>
            <h3 className='line_reserve'> ${cur_reserve} </h3>
            {/*}<h3 className='line'> ({cur_month} {cur_year}) </h3>*/}
          </div>

          {this.renderFilterButtons()}

          <div className='chart-container'>

            <Line data={data} options={chartOptions} onElementsClick={elems => {

              if (elems !== undefined && elems.length !== 0) {

                // console.log(elems[0]._datasetIndex + ', ' + elems[0]._index);
                var label = data.labels[elems[0]._index][0];
                year = '20' + label.slice(4);
                month = months.indexOf(label.slice(0, 3));
                // console.log(label, year, cur_year, months, label.slice(0, 3));


                if (parseInt(year) === cur_year && getall) {
                  // if (parseInt(year) === cur_year && getall){ 
                  // console.log(month)
                  details = reserves[month+1];
                  // console.log(reserves, month + 12)
                  // console.log(details);
                  this.setState({ details: details });
                  this.togglePopup();

                }
                else {
                  // console.log(month)
                  details = reserves[elems[0]._index];
                  // console.log(details);
                  this.setState({ details: details });
                  this.togglePopup();
                }

              }

              // console.log(year, month, details.Year, details.Month);


            }

            } />

            {(this.state.showPopup) ?
              <Popup
                data={this.state.details}
                closePopup={this.togglePopup.bind(this)}
              />
              : null
            }

          </div>

        </div>
      );

    }

    // Else the data still loading
    else {

      return (
        <div className='content-container'>

          <div className='loader'></div>

        </div>
      )

    }


  }

}


export default StaffMobileReserve;
