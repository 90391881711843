const cell_font_size = '17';
// const title_font_size = '20';
const employee_cell_font_size = '12';


export const roundup = (x) => {
  return (Math.round(x*100) / 100);
}

export const changeFloat = (x) => {
  if((x!== null) && !isNaN(x) && x!=='' && x!== undefined){
    if (x === '0' || x === 0) {return 0}
    return parseFloat(x);
  }
  return 0;
}

export const numberWithCommas = (x) => {
  if((x!== null) && !isNaN(x)){
    if (x === '0' || x === 0) {return [0]}
    var y = roundup(x).toFixed(2);
    if (y<0){
      return [y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), 'negative'];
    }
    return [y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")];
  }
  // console.log('numberWithCommas',x);
  return [null];
}


export const numberWithCommas_display = (x) => {
  if((x!== null) && !isNaN(x)){
    if (x === '0' || x === 0) {return '0.00'}
    // else{
      var y = roundup(x).toFixed(2);
      return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }
  }
  // return null;
  else{
    // console.log('not a number')
    return x
  }
  // return 'NaN';

}

export const removeCommas = (x) => {
  // console.log('Remove commas', x);
  if((x!== null) && !isNaN(x)){

    if (x === 0.00 || x === '0.00' || x === 0 || x === '0'){
      return '0';
    }
    x=x.toString().replace(/,/g,'');
    // var y = roundup(x).toFixed(2);
    return x;
  }

  else{
    return x;
  }

}

export const validInput = (x) => { //check and round up numbers
  if((x!== null) && !isNaN(x) && x!== undefined){
    if (x === '0' || x === 0) {
      return 0
    }
    else{
      var dec=x.indexOf('.');
      if(dec!==-1){
        //reading the decimal places  count
        var decPlaces=x.substring(dec+1,x.length).length;
        //compare it with props value
        if(decPlaces>=2){
          x = parseFloat(x);
          x = x.toFixed(2);
        }
      }    
      // x = (Math.round(x*100) / 100);
      return x;
    }
  }
  else{
    return 0;
  }
}


export const borderedCell_employee = (value, string, wrap) => {
  var cell = {};
  cell.style = {
                  alignment:{
                    horizontal: 'right',
                    vertical: 'bottom'
                  },
                  font:{ sz:employee_cell_font_size}
                }
  if (string){
    if (String(value).startsWith('-') ){
      cell.style.font.color = {rgb: "FF0000"};
    }
    cell.value = value;
    // cell.style.alignment.horizontal = 'right';
  }
  else{
    if (value !== null && value!=='null' && value !== 0 && value !== '0' && value !== undefined){
      var v = numberWithCommas(value);
      // console.log(value)
      cell.value = v[0];

      if (v.length > 1){
        cell.style.font.color = {rgb: "FF0000"};
      }

    }
    else{
      // cell.value = '';
      cell.value = (0).toFixed(2);
    }
  }

  if(wrap){
    // cell.style.fill = {pattern: "solid", fgColor: {rgb: "D4D4D4"}};
    cell.style.alignment.vertical = "center";
    cell.style.alignment.wrapText = true;
  }
  return cell;
}

export const borderedCell_payroll = (value, string, total) => {
  var cell = {};
  cell.style = {
                  border: {
                    top: { style: "medium", color: "black" },
                    bottom: { style: "medium", color: "black" },
                    left: { style: "medium", color: "black" },
                    right: { style: "medium", color: "black" }
                  },
                  alignment:{
                    horizontal: 'right',
                    vertical: 'bottom'
                  },
                  font:{ sz:cell_font_size}
                }
  if (string){
    if (String(value).endsWith('%') ){
      cell.value = value;
      cell.style.alignment.horizontal = 'right';

      if (value.startsWith('-')){
        cell.style.font.color = {rgb: "FF0000"};
      }

    }
    else{
      cell.value = value;
      cell.style.alignment.horizontal = 'left';
    }

  }
  else{
    if (value !== null && value!=='null' && value !== 0 && value !== '0'){
      var v = numberWithCommas(value);

      cell.value = v[0];

      if (v.length > 1){
        cell.style.font.color = {rgb: "FF0000"};
      }
    }
    else{
      cell.value = (0).toFixed(2);
      // cell.value = '';
    }
  }



  if(total){
    cell.style.fill = {pattern: "solid", fgColor: {rgb: "D4D4D4"}};
    cell.style.font.bold = true;
  }
  return cell;
}
