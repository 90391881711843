import React, { Component } from "react";
import ScrollContainer from 'react-indiana-drag-scroll'

class ReportTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSet: this.props.dataSet,
      income: []
    }
  }


  componentDidMount() {
    // this.renderTitle();
    // this.renderTable();
    // this.renderRow();
  }

  mapTitle = (r) => {
    // console.log('r',r[0]);
    return (<tr key={r[0]}>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <th>{r[0].value}</th>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>);
  }

  renderColumns = (col) => {
    // console.log('col is ', col);
    return (<th key={col.value}>{col.value}</th>);
  }


  renderTitle(columns) {
    return columns.slice(2, columns.length).map(this.renderColumns)

  }


  renderCell = (cell, index) => {
    // console.log('cell', cell, index);
    if (typeof cell === 'string') {
      if (String(cell).startsWith('-')) {
        return <td key={index} align="left" className='red'>{cell.value}</td>
      }
      return <td key={index} align="left">{cell}</td>
    }
    else {
      if (cell.value === "Total Collection Vs Budget (%)") {
        return <td key={index} align="left">{cell.value}</td>
      }
      else if (cell.value === null) {
        return <td key={index} align="left"></td>
      }
      else if (cell.value.startsWith('-')) {
        return <td key={index} align="left" className='red'>{cell.value}</td>
      }
      else {
        return <td key={index} align="left">{cell.value}</td>
      }

    }
  }

  renderRow = (row, index) => {
    // console.log('row', row);
    return <tr key={index}>{row.map(this.renderCell)}</tr>
  }

  renderTable(content) {

    return content.map(this.renderRow);

  }

  renderName(staff, date) {
    if (staff !== '') {
      return (<tr key={staff[0][1]}>
        <td colSpan='5'>Name: {staff[0][1]}</td>
        <td></td><td></td><td></td><td></td><td></td><td></td><td></td>
        <td></td><td className="alignright" colSpan='5'>{date[0][0]}</td>
      </tr>)
    }
    else {
      return (
        <tr>
          <td colSpan='5'></td>
          <td></td><td></td><td></td><td></td><td></td><td></td><td></td>
          <td></td><td className="alignright" colSpan='5'>{date[0][0]}</td>
        </tr>
      )
    }

  }

  render() {
    const dataSet = this.props.dataSet;
    const type = this.props.type;
    // console.log(dataSet);
    // console.log(type);
    var staff = '';
    console.log(dataSet)

    // var {income} = this.state;

    if (dataSet.length !== 0) {
      var title = dataSet[0].data;
      const date = dataSet[1].data;

      if (type === 'employee') {
        staff = dataSet[2].data;
        console.log(staff)
      }

      const columns = dataSet[3].data[0];
      const income = dataSet[3].data.slice(1, dataSet[3].data.length);
      const deduction = dataSet[4].data.slice(1, dataSet[4].data.length);
      const summary = dataSet[5].data;

      return (<div>
        <div className="container-fluid report">
          <div className="table-container">
            <ScrollContainer className="scroll-container">

              <form>
                <table className="table table-striped table-hover ">
                  <thead>
                    <tr className="title" key={title[0][0].value}>
                      <th colSpan='18'>{title[0][0].value}</th>
                    </tr>
                    <tr className="title" key={title[1][0].value}>
                      <th colSpan='18'>{title[1][0].value}</th>
                    </tr>
                    {this.renderName(staff, date)}
                    <tr key='months'>
                      <th colSpan='2'>MONTHS</th>
                      {this.renderTitle(columns)}
                    </tr>
                  </thead>
                  <tbody>
                    <tr><td></td></tr>
                    {this.renderTable(income)}
                    <tr><th>Deduction</th></tr>
                    {this.renderTable(deduction)}
                    <tr><td></td></tr>
                    {this.renderTable(summary)}
                  </tbody>

                </table>
              </form>
            </ScrollContainer>

          </div>
        </div>

      </div>);

    }


  }


}

export default ReportTable;