import React, {Component} from "react";
import { Dropdown } from 'primereact/dropdown';
var service = require('../admin/service/getinfo.js');
const customStyles = service.customStyles;

class DropdownComponent extends Component {
    render() {
        const { options, placeholder, handleChange, value } = this.props;

        return (
            <Dropdown 
            options={options} style={customStyles}
            placeholder={placeholder}
            onChange={handleChange} value={value}
            filter
            filterBy="label"
            />
        )
    }
}

export default DropdownComponent;