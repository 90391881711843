import React, {Component} from "react";
// import Select from 'react-select';
import DropdownComponent from "../../components/dropdown";
import moment from 'moment';
import ReactExport from "react-data-export";
// import YearlyReportTable from "./report_yearly_table";
import ReportTable from "../../components/reportTable";
import ExportPDF from "../../components/exportPDF.js";
// import {NotificationManager, NotificationContainer} from 'react-notifications';
import { Toast } from "primereact/toast";

import {borderedCell_employee as borderedCell} from "../service/excelService";
import CheckButton from "../../components/checkButton";

var config = require('../../config.js');
var service = require('../service/getinfo.js');
var logging = require('../service/loggingComponent.js');
const loggingHeader = '[frontend][report_yearly]';
// const customStyles = service.customStyles;

const backend = config.backend;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const report_title = "The Navigators Singapore";
const report_subtitle = "Total Yearly Reserves Report for ";

var income = {
  ySteps:-1,
  columns: [
    {title: '', width:{wch:22}},
    {title: '', width:{wch:1}}
  ],
  data: [ ]
};

var deduct = {
  columns: [],
  data: []
};

class Report_Yearly extends Component {

  constructor(props){
    super(props);


    this.state = {
      years: [],
      year: '',
      staffs:[],
      errors:{},
      checked:false,
      space: {
        columns:[],
        data:[]
      },
      multiData:[],
      filename:'report',
      total: 1,
      num: 0,
      finished: false

    }
    this.showError = this.showError.bind(this);
    // this.showSuccess = this.showSuccess.bind(this);

  }

  _isMounted = false;


  componentDidMount() {
    this._isMounted = true;
    this.renderYear();
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  // showSuccess(message) {
  //   this.toast.show({severity:'success', summary: 'Processing Success', detail:message, life: 3000});
  // }

  showError(message) {
    this.toast.show({severity:'error', summary: 'Processing Failed', detail:message, life: 3000});
  } 


  renderYear = _ => {
    service.getYearList()
    .then((years_drop) => {
      // this.setState({years:years_drop});
      if (this._isMounted){
        this.setState({years:years_drop});
      }
    })
  }

  handleYearChange = (y) => {
    logging.logToGoogle(loggingHeader + ' choosing year using dropdown list');
    this.setState({ year: y.value});
    // console.log('year changed to ', this.state.y);

  }

  inputValid = () => {
    let errors = {};
    let formIsValid = true;
    const {year}  =  this.state;

    // console.log('year', year);

      //Check To Staff Id
      if (!(isFinite(String(year)) && year !== '' )){
        errors['year'] = 'Please choose a year!';
        formIsValid = false;
      }

    this.setState({errors: errors});
    return formIsValid;
  }

  generateTitle(year)
  {

    // title and month will have its own function
    var title = {
      ySteps: -1,
      xSteps: 7,
      columns: [
      ],
      data:[
        [

          {
            value:report_title,
            style:{
              font:{
                sz: '18',
                bold: true
              },
              alignment:{
                horizontal: 'center',
                vertical: 'center',
                readingOrder: 4
              }
            }
          }

        ],

        [
          {
            value:report_subtitle + year,
            style:{
              font:{
                bold: true
              },
              alignment:{
                horizontal: 'center',
                vertical: 'center'
              }
            }
          }
        ],
      ]
    }
    return title;
  }

  generateDate(){
    var date = moment().format('DD/MM/YYYY hh:mm:ss A');
    // console.log('date',date);
    var datedetail = {
      ySteps: -2,
      xSteps: 13,
      columns:[],
      data: [
        [date]
      ]
    }
    return datedetail;
  }

  updateState = (data) => {
    // console.log('outside promise');
    this.setState({multiData: data});

  }

  getyearlyReport(year, incomedata, deductdata){

    return new Promise(function(resolve, reject){

      fetch(backend + `api/yearlyreport/year/` + year, {
        method: 'GET',
        credentials: "include"
      })
      .then(res => res.json())
      .then((res) => {
        console.log('getYearlyReport res', res);

        var income_data = res['income']
        var deduct_data = res['outcome']

        // console.log(income_data);
        // console.log(deduct_data);

        for (var i=0; i < income_data.length; i++){

          for (var j = 2; j < 15; j++){
            income_data[i][j] = borderedCell(income_data[i][j]);
          }

          if (income_data[i][0] === 'Donations' || income_data[i][0] === 'Total'){
            // console.log('average', income_data[i][15])
            income_data[i][15] = borderedCell(income_data[i][15])
          }

        }

        for (var x=0; x < deduct_data.length; x++){

          for (var y = 2; y < 15; y++){

            if (x === deduct_data.length-1){
              // console.log('ration', deduct_data[x][y])
              deduct_data[x][y] = borderedCell(deduct_data[x][y], true, true);
            }
            else{
              deduct_data[x][y] = borderedCell(deduct_data[x][y]);
            }

          }

          if (deduct_data[x][0] === 'Total'){
            deduct_data[x][15] = borderedCell(deduct_data[x][15])
          }

        }


        incomedata = incomedata.concat(income_data);
        deductdata = deductdata.concat(deduct_data);

        // console.log(incomedata);
        // console.log(deductdata);

        income['data'] = incomedata;
        deduct['data'] = deductdata;
        // deduct['data'] = deductdata.slice(0,18);

        resolve ([income, deduct])
      })
      .catch((err) => {
        console.log('yearlyReport error', err)
      })

    })


  }


  checkReserve = _ => {
    logging.logToGoogle(loggingHeader + ' clicked CHECK');
    // const {staffs} = this.state;
    const {year}  =  this.state;
    const {space} = this.state;
    this.setState({multiData: [], num: 0, finished: false});
    // this.setState({num: 0});
    this.setState({finished: false}, () => {
      // console.log(this.state.finished)

    })


    // init();

    var title = this.generateTitle(year);
    var date = this.generateDate();


    // Empty Column row to set the cell width
    for (var j=0; j< 12; j++ ){
      income.columns.push({title: '', width:{wch:9}})
    }
    income.columns.push({title: '', width:{wch:11}});
    income.columns.push({title: '', width:{wch:9}});
    // Generate months columns for income table
    var col_row = [
      {
        value: 'MONTH',
        style: {
          alignment:{
            horizontal: 'center',
            vertical: 'bottom'
          },
          font: {bold:true}

        }
      },
      ''
    ]

    // Generate columns for income table
    const months = moment.monthsShort();
    for (var m in months){
      col_row.push({
        value: months[m],
        style: {
          alignment:{
            horizontal: 'center',
            vertical: 'bottom'
          },
          font: {bold:true}

        }
      });
    }
    col_row.push({
      value: 'TOTAL',
      style: {
        alignment:{
          horizontal: 'center',
          vertical: 'bottom'
        },
        font: {bold:true}
      }
    });
    col_row.push({
      value: 'AVERAGE',
      style: {
        alignment:{
          horizontal: 'center',
          vertical: 'bottom'
        },
        font: {bold:true}
      }
    });


    var months_data = [];
    for (var i=0; i < 16; i++){
      var cell = {};
      cell['value'] = '';
      cell['style'] = {
        border: {top: {style: 'thick', color: { rgb: '000000'}}},
        alignment:{
            horizontal: 'center',
            vertical: 'bottom'
        }
      }
      months_data.push(cell);
      // console.log('months_data',months_data);
    }
    var incomedata = [];
    incomedata.push(col_row);

    incomedata.push(months_data);

    var deductdata = [
      [{value: 'Deduction', style: {font:{underline:true, bold:true}, width:{wch: 32}}}]
    ];
    // var percentagedata = [];

    if (!this.inputValid()){
      // NotificationManager.error('Please check your input!');
      this.showError('Please check your input!')
    }
    else{

      this.setState({checked: true})
      this.getyearlyReport(year, incomedata, deductdata)
      .then((result) =>{
        // console.log('yearlyReport_result', result)
        this.setState({finished: true, multiData: [title, date, space, result[0], result[1], space]})
      });


    }
  }




  renderReport(){
    const {multiData} = this.state;
    const {year} = this.state;
    const filename = year + " Total Yearly Report";


    let checked = this.state.checked;

    if (checked){
      // console.log('if checked',multiData.length);

      if (multiData.length !== 0 && multiData.length !== undefined ){
        // console.log('multiData is updated', multiData);
        return (<div>

                  <div className="download-report col-sm-12">
                    <ExcelFile element={ <button type="button" className="btn btn-warning col-sm-2 button-excel">Download Excel</button> } filename={filename}>
                      <ExcelSheet dataSet={multiData} name="Report" />
                    </ExcelFile>

                    <ExportPDF data={multiData} type='Yearly' />
                  </div>

                  <ReportTable dataSet={multiData} type='yearly' />
              </div>);
      }
      else{
        return <h4 className='title'>Loading... This may take a while... </h4>
      }
    }

  }


  render() {
    const {years} = this.state;
    let report_table = this.renderReport();


    // return (<h1>Total Yearly Reserves Report</h1>)
    return (<div className="yearly-report">
      <Toast ref={(el) => this.toast = el} />
      <h4 className="title">Generate Total Yearly Reserves Report</h4>
      <div className="form-set row">
        <form className="form-horizontal">
          <div className="form-sets col-sm-12">
            <fieldset>
              <div className="fieldset yearly-buttons">

                <div className = "buttons year col-sm-6">
                  <div className="btn-group ">
                    {/* <Select className='react-select-container' styles={customStyles} options={years} isSearchable="false" placeholder="year" onChange={this.handleYearChange}/> */}
                    <DropdownComponent
                    options={years}
                    placeholder="year"
                    handleChange={this.handleYearChange}
                    value={this.state.year} />
                    <span className="error">{this.state.errors["year"]}</span>
                  </div>
                </div>

                <div className="col-sm-6 check-button">
                  {/* <button type="button" className="btn btn-primary submit" onClick={this.checkReserve}>CHECK</button> */}
                  <CheckButton 
                  checkReserve={this.checkReserve}/>
                </div>

              </div>
            </fieldset>
          </div>
        </form>


      </div>
        <div className="report-table" id="reserve-table">
          {report_table}

        </div>
        {/* <NotificationContainer/> */}

      </div>


    )

  }
}

export default Report_Yearly;
