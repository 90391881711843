import React, {Component} from 'react';
import {
  BrowserRouter as Router,
  NavLink
} from "react-router-dom";
// import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
// import StaffMobileReserve from "./reserve";
import AdminMobileTransfer from "./transfer";
import '../css/style.css';
import '../css/admin.css';
var logging = require('../../admin/service/loggingComponent');
const loggingHeader = '[frontend][mobile][admin][home]';


class AdminMobile extends Component {

  constructor(props){
    super(props);
    this.state = {

      name:'',
      staff:'',
      default_content:''

    }
  }

  componentWillMount(){
    this.default();
  }

  handlelogout = _ => {
    logging.logToGoogle(loggingHeader + ' clicked logout');
    sessionStorage.setItem('int', 'logout');
  }


  default = _ => {
    var {staff} = this.state;
    var url = window.location.href;

    // Redirect to current reserve page
    if (url.endsWith('/adminmobile/home')){
      this.setState({default_content: (<AdminMobileTransfer id={staff}></AdminMobileTransfer>) })
    }

  }


  render() {

      // var staff = sessionStorage.getItem('id_s');
      // var name = sessionStorage.getItem('name');
      var email = sessionStorage.getItem('email');
      let {default_content} = this.state;

      return (

        <div className="StaffMobile">
        <Router>
          <div>

          <nav className="navbar navbar-default">
            <div className="container-fluid">
              <div className="navbar-header">
                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <a className="navbar-brand" href="/"><img id="logo" src="/img/logo.png" alt="Navlogo"/></a>
              </div>

              <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">

                <ul className="nav navbar-nav"  >
                  <li data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" className=""><NavLink data-toggle="collapse" active="active" to="/{staff}/adminmobile">PROCESS TRANSFER </NavLink></li>
                </ul>

                <ul className="nav navbar-nav navbar-right">
                  <li>
                    {/*}<a href="/.auth/logout">{name}
                      <i className="material-icons" style={{fill:'white'}}>exit_to_app</i>
                    </a>*/}
                    <a href="/logout" onClick = {() => this.handlelogout()}>Log out from {email}@navigators.org.sg</a>
                    <a href="/admin/">Switch to Desktop Version</a>

                  </li>
                </ul>

              </div>
            </div>
          </nav>


          <div className="content container">

            {default_content}

          </div>



          </div>

        </Router>

        </div>

      );
    }

}


export default AdminMobile;
