var config = require('../../config.js');
const backend = config.backend;
const logCode = config.logging.severity;
const projectTopic = config.logging.label;
const errorCode = config.logging.error;
// console.log(logCode, projectTopic)

export const logToGoogle = (text) => {
    return new Promise((resolve, reject) => {
        fetch(backend + 'api/loggingFrontend/2/' + logCode + '/' + text + '/' + projectTopic, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((result) => {
            if (result) {
                resolve(result)
            }
        })
    });
}

export const logErrorToGoogle = (text) => {
    return new Promise((resolve, reject) => {
        fetch(backend + 'api/loggingFrontend/2/' + errorCode + '/' + text + '/' + projectTopic, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((result) => {
            if (result) {
                resolve(result)
            }
        })
    });
}