import React, {Component} from "react";
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

class TransferTable extends Component {

    renderStaffTable(column, transferType) {
      if (transferType=='Received') {
        if (column.text !=='TO' && column.text !=='SUBMIT DATE') {
          return (<Column 
            key={"Id"}
            field={column.dataField}
            header={column.text}
            sortable
            style={{fontSize: '15px'}}
            headerStyle={{
              width: '17%',
              fontSize: '14px'
            }}
            body={column.formatter}
          />)
        }
      }
      else if (transferType=='Give') {
        if (column.text !=='FROM' && column.text !=='SUBMIT DATE') {
          return (<Column 
            key={"Id"}
            field={column.dataField}
            header={column.text}
            sortable
            style={{fontSize: '15px'}}
            headerStyle={{
              width: '17%',
              fontSize: '14px'
            }}
            body={column.formatter}
          />)
        }
      }
      else if (transferType=='Pending') {
        if (column.text !=='FROM') {
          return (<Column 
            key={"Id"}
            field={column.dataField}
            header={column.text}
            sortable
            style={{fontSize: '15px'}}
            headerStyle={{
              width: '17%',
              fontSize: '14px'
            }}
            body={column.formatter}
          />)
        }
      }
    }

    renderAdminTable(column, device) {
      if (device !=='mobile') {
        return (column.text=='FROM' || column.text=='TO' ? <Column 
          key={"Id"}
          field={column.dataField}
          header={column.text}
          sortable
          style={{fontSize: '15px'}}
          filter
          headerStyle={{
            width: '17%',
            fontSize: '14px'
          }}
          filterPlaceholder={"Enter " + column.text + "..."}
          filterMatchMode="contains"
          body={column.formatter}
        /> : (column.text=='ACTION') ? <Column 
        key={"Id"}
        field={column.dataField}
        header={column.text}
        headerStyle={{
            width: '16%',
            fontSize: '14px'
        }}
        style={{fontSize: '15px'}}
        body={column.formatter}
        /> : <Column 
      key={"Id"}
      field={column.dataField}
      header={column.text}
      sortable
      style={{fontSize: '15px'}}
      headerStyle={{
        fontSize: '14px'
      }}
      body={column.formatter}
      />)
    }
    else {
      if (column.text == 'FROM' || column.text == 'TO' || column.text == 'AMOUNT (SGD)' || column.text == 'ACTION') {
        return ((column.text !== 'AMOUNT (SGD)' ? <Column 
          key={"Id"}
          field={column.dataField}
          header={column.text}
          style={{fontSize: '15px'}}
          headerStyle={{
            width: '17%',
            fontSize: '14px'
          }}
          body={column.formatter}
        /> : <Column 
        key={"Id"}
        field={column.dataField}
        header="S$"
        style={{fontSize: '15px'}}
        headerStyle={{
          width: '17%',
          fontSize: '14px'
        }}
        body={column.formatter}
      />))
      }
    }
  }

    formatDate(value, title) {
        // console.log(value)
        // return moment(value).format('DD-MMM-YYYY');
        return (
          <React.Fragment>
              <span className="p-column-title">{title}</span>
              {moment(value).format('DD-MMM-YYYY')}
          </React.Fragment>
      );
      }
    
      formatCurrency(value, title, device) {
        // console.log(value)
        // return '$ '+value;
        return (
          <React.Fragment>
              <span className="p-column-title">{(device=='mobile') ? 'S$' : title}</span>
              {'$ '+value}
          </React.Fragment>
      );
      }
    
      noFormatColumn(value, title) {
        return (
          <React.Fragment>
              <span className="p-column-title">{title}</span>
              {value}
          </React.Fragment>
      );
      }
    
      formatStatus(status, title) {
        // console.log(status)
        let finalStatus;
        if (status === undefined || status === 'null' || status === null)
        {
          finalStatus = status;
        }
        else if (status === 'approve')
        {
          finalStatus = status.charAt(0).toUpperCase() + status.substr(1) + 'd';
        }
        else if (status === 'reject')
        {
          finalStatus = status.charAt(0).toUpperCase() + status.substr(1) + 'ed';
        }
        else{
          finalStatus = status.charAt(0).toUpperCase() + status.substr(1);
        }
        return (
          <React.Fragment>
              <span className="p-column-title">{title}</span>
              {finalStatus}
          </React.Fragment>
      );
      }

      exportCSV = () => {
        this.dt.exportCSV();
      }

      buttonFormatter(data, title){
        const { onClickButton } = this.props;
        return (
          <React.Fragment>
            <span className="p-column-title">{title}</span>
              <Button label="Approve" 
                className="p-button-lg p-button-success action-btn approve-btn"
                onClick={() =>
                  onClickButton(data["Id"],"status","approve")} 
              />
              <Button label="Reject" 
                className="p-button-lg p-button-danger action-btn reject-btn"
                onClick={() =>
                  onClickButton(data["Id"],"status","reject")} 
              />
              {/* <div id="action-buttons">
                <button
                    className="btn btn-success action-btn approve-btn"
                    type="button"
                    onClick={() =>
                    this.onClickButton(cell, row.Id,"status","approve")}
                > */}
                {/*} <i className="material-icons">check</i>*/}
                {/* Approve
                </button>
              
                <button
                    className="btn btn-danger action-btn reject-btn"
                    type="button"
                    onClick={() =>
                    this.onClickButton(cell, row.Id,"status","reject")}
                > */}
                {/*}<i className="material-icons">close</i>*/}
                {/* Reject
                </button>
              </div> */}
          </React.Fragment>
        )
      }

      render() {
        const { transfers, pending, type, transferType, device } = this.props;
        // const header = <div style={{textAlign:'left'}}><Button type="button" icon="pi pi-external-link" label="Export to Excel" onClick={this.exportCSV}></Button></div>;

        const columns = [{
            dataField: 'FromStaffId',
            text:'FROM',
            // filter: textFilter(),
            formatter: (data) => {
              return this.noFormatColumn(data['FromStaffId'], 'FROM');
            }
          },
          {
            dataField: 'ToStaffId',
            text:'TO',
            // filter: textFilter()
            formatter: (data) => {
              return this.noFormatColumn(data['ToStaffId'], 'TO');
            }
          }, {
            dataField: 'SubmitDate',
            text:'SUBMIT DATE',
            // sort: true,
            // sortFunc: this.sortDates,
            formatter: (data) => {
              // return moment(submit).format('DD-MMM-YYYY');
              return this.formatDate(data['SubmitDate'], 'SUBMIT DATE');
            }
          },{
            dataField: 'Amount',
            text:'AMOUNT (SGD)',
            // sort: true,
            // sortFunc: (a, b, order, dataField, rowA, rowB) => {
            //   if (order === 'asc') {
            //     return b - a;
            //   }
            //   return a - b; // desc
            // },
            formatter: (data) => {
              // return '$ '+amount;
              return this.formatCurrency(data['Amount'], 'AMOUNT (SGD)', device);
            }
          }, {
            dataField: 'StartDate',
            text:'FIRST TRANSFER DATE',
            // sort: true,
            // sortFunc: this.sortDates,
            formatter: (data) => {
              // console.log(data)
              // return moment(from).format('DD-MMM-YYYY');
              return this.formatDate(data['StartDate'], 'FIRST TRANSFER DATE');
            }
          }, {
            dataField: 'Times',
            text:'MONTH(S)',
            // sort: true,
            // sortFunc: (a, b, order, dataField, rowA, rowB) => {
            //   if (order === 'asc') {
            //     return b - a;
            //   }
            //   return a - b; // desc
            // }
            formatter: (data) => {
              return this.noFormatColumn(data['Times'], 'MONTH(S)')
            }
          }, {
            dataField: 'EndDate',
            text:'LAST TRANSFER DATE',
            // sort: true,
            // sortFunc: this.sortDates,
            formatter: (data) => {
              // return moment(end).format('DD-MMM-YYYY');
              return this.formatDate(data['EndDate'], 'LAST TRANSFER DATE');
            }
          }, 
          {
            dataField: 'Status',
            text:'STATUS',
            // sort: true,
            formatter: (data) => {
      
              // if (status === undefined || status === 'null' || status === null)
              // {
              //   return status;
              // }
              // else if (status === 'approve')
              // {
              //   return status.charAt(0).toUpperCase() + status.substr(1) + 'd';
              // }
              // else if (status === 'reject')
              // {
              //   return status.charAt(0).toUpperCase() + status.substr(1) + 'ed';
              // }
              // else{
              //   return status.charAt(0).toUpperCase() + status.substr(1);
              // }
              return this.formatStatus(data['Status'], 'STATUS');
            },
        }];

        if (pending) {
            columns.push({
                  text:'ACTION',
                  dataField:"button",
                //   style: { 'width': '170px'},
                  formatter: (data, row) => {
                    return this.buttonFormatter(data, 'ACTION');
            }})
        }

        // if(check === false){
        //     return (<div>
        //       <div className='tab'><span>{title} Transfers</span></div>
        //       <div className = "table-container">
        //       <div className="only-row">Loading...</div>
        //      </div>
        //      </div>);
        //   }
      
        //   else{
        //     if (transfers.length === 0){
        //       return (<div>
        //         <div className='tab'><span>{title} Transfers</span></div>
        //         <div className = "table-container">
        //         <div className="only-row">No Transfer Found</div>
        //        </div>
        //        </div>);
        //     }
            // else{
              return (
                <DataTable  
                  className="p-datatable-striped"
                  ref={(el) => this.dt = el}
                  value={transfers}
                  // header={header}
                  paginator
                  rows={10}
                  sortField="SubmitDate"
                  sortOrder={-1}
                  removableSort
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  rowsPerPageOptions={[10,30,50]}
                  paginatorPosition="both"
                >
                  {columns.map(column => {
                    return (type==='admin') ? this.renderAdminTable(column, device) : this.renderStaffTable(column, transferType)
                //     (column.text=='FROM' || column.text=='TO' ? <Column 
                //       key={"Id"}
                //       field={column.dataField}
                //       header={column.text}
                //       sortable
                //       style={{fontSize: '15px'}}
                //       filter
                //       headerStyle={{
                //         width: '17%',
                //         fontSize: '14px'
                //       }}
                //       filterPlaceholder={"Enter " + column.text + "..."}
                //       filterMatchMode="contains"
                //       body={column.formatter}
                //     /> : (column.text=='ACTION') ? <Column 
                //     key={"Id"}
                //     field={column.dataField}
                //     header={column.text}
                //     headerStyle={{
                //         width: '16%',
                //         fontSize: '14px'
                //     }}
                //     style={{fontSize: '15px'}}
                //     body={column.formatter}
                //     /> : <Column 
                //   key={"Id"}
                //   field={column.dataField}
                //   header={column.text}
                //   sortable
                //   style={{fontSize: '15px'}}
                //   headerStyle={{
                //     fontSize: '14px'
                //   }}
                //   body={column.formatter}
                // />)
                  })}
                </DataTable>
              // </div>
              // </div>
              // </div>
              );
            // }
          // }
      }
}

export default TransferTable;